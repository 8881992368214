import './UploadSourceDataModal.css';
import {
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Box
} from "@mui/material";

import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';

import {useCallback, useState} from "react";
import {DataGrid} from "@mui/x-data-grid";
import {useDropzone} from "react-dropzone";

import checked from '../assets/checked.png';

import MainModal from "../../../helpers/Modal/MainModal";
import AssingDatasetModal from "../AssignDatasetModal/AssingDatasetModal";
import FileProcessModal from "../FileProcessModal/FileProcessModal";
import warning from "../assets/warning.png";
import DataIngestionErrorModal from "../DataIngestionErrorModal/DataIngestionErrorModal";
import close from "../assets/close.png";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FolderIcon from '@mui/icons-material/Folder';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import AccountTreeIcon from '@mui/icons-material/AccountTree';

const uploadFileTypes = [
    'CSV File',
    'New Dataset',
    'Database Connection',
    'any',
];


const UploadSourceDataModal = () => {
    const [ingestionMethod, setIngestionMethod] = useState('');
    const [sourceSystem, setSourceSystem] = useState('');
    const [checkedDataset, setCheckedDataset] = useState(null);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [assignModal, setAssignModal] = useState(false);
    const [selectedRows, setSelectedRows] = useState(null);
    const [dataError, setDataError] = useState(false);
    const [connectDb, setConnectDb] = useState(false);


    const priorityFormater = (cell) => {

        return (
            <div className='data-grid-icons-container'>

                {
                    cell === 'error' ?
                        <img onClick={_ => setDataError(true)} className='data-grid-icon' src={warning}></img> :
                        <img className='data-grid-icon' src={checked}></img>
                }
            </div>
        );
    };

    const rows = [
        {
            id: 1,
            status: 'ok',
            sourceDatasetName: 'Customers',
            sourceSystem: 'SAP ECC',
            assignedTemplateObject: 'Customers'
        },
        {
            id: 2,
            status: 'ok',
            sourceDatasetName: 'Material Long Text',
            sourceSystem: 'SAP ECC',
            assignedTemplateObject: 'Material Long Text'
        },
        {
            id: 3,
            status: 'ok',
            sourceDatasetName: 'Material Classifications',
            sourceSystem: 'SAP ECC',
            assignedTemplateObject: 'Material Classifications'
        },
        {
            id: 4,
            status: 'error',
            sourceDatasetName: 'Suppliers',
            sourceSystem: 'SAP ECC',
            assignedTemplateObject: 'Suppliers'
        },
        {
            id: 5,
            status: 'ok',
            sourceDatasetName: 'Suppliers Long Text',
            sourceSystem: 'SAP ECC',
            assignedTemplateObject: 'Suppliers',
        }
    ];


    const Dragndrop = () => {
        const [files, setFiles] = useState()
        const [fileProcessModal, setFileProcessModal] = useState(false);

        const onDrop = useCallback(acceptedFiles => {
            setFiles(acceptedFiles)
        }, [])

        const {getRootProps, getInputProps} = useDropzone({
            onDrop
        })

        const FileContainer = () => {
            return (
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
                    <h2>File Name</h2>

                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '80%',}}>
                        {
                            files.map(file => {
                                return (
                                    <div style={{
                                        display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '2rem',
                                        padding: '2% 0', borderBottom: '1px solid lightgray', width: '100%'
                                    }}>
                                        <img onClick={_ => setFiles(files?.filter(el => el !== file))}
                                             style={{width: '16px', height: '16px', cursor: 'pointer'}}
                                             src={close}></img>

                                        <span>{file.name}</span>

                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            )
        }

        return (
            <div style={{width: '90%', padding: '1rem', border: '1px solid lightgray', marginTop: '1rem'}}>
                <div>
                    <h2 style={{padding: 0, marginTop: 0}}>Upload Files:</h2>
                    <div
                        className='new-source-dataset-dropzone-area dropzone-area' {...(!files?.length ? getRootProps() : null)}>

                        {!files?.length ? "Drag your file or select file" : <FileContainer/>}
                        {!files?.length && <input className='dropzone-area' {...getInputProps()}/>}
                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        width: '100%',
                        alignItems: 'center',
                        marginBottom: '3%'
                    }}>

                        <Button
                            disabled={!files?.length}
                            variant='contained'
                            onClick={_ => setFileProcessModal(true)}
                            sx={{marginRight: '2%', marginTop: '4%'}}>Process</Button>
                    </div>

                    {
                        !!files?.length &&
                        <p style={{marginLeft: '25%'}}>Processing source datasets. Do not close the page...</p>
                    }


                    <MainModal modalOpen={fileProcessModal} setModalOpen={setFileProcessModal}
                               headerText='Upload New Source Dataset(s)'
                               Child={FileProcessModal}
                               files={files}
                               tooltip={true}
                               setFiles={setFiles}
                               isOpen={setFileProcessModal}
                    />
                </div>
            </div>

        )
    }

    const DbTree = () => {
        const [expanded, setExpanded] = useState([]);

        const handleToggle = (event, nodeIds) => {
            setExpanded(nodeIds);
        };


        const handleExpandClick = () => {
            setExpanded((oldExpanded) =>
                oldExpanded.length === 0 ? ['1', '2', '5', '6', '15', '18', '21', '22', '25'] : [],
            );
        };

        const data = [
            {
                id: 1,
                name: 'config_test_project1',
                folders: [
                    {
                        id: 2,
                        name: 'table1',
                        files: [
                            {
                                id: 3,
                                name: 'file1'
                            },
                            {
                                id: 4,
                                name: 'file2'
                            }
                        ],
                        folders: [
                            {
                                id: 15,
                                name: 'table3',
                                files: [
                                    {
                                        id: 16,
                                        name: 'subfile1'
                                    },
                                    {
                                        id: 17,
                                        name: 'subfile2'
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                id: 5,
                name: 'config_test_project2',
                folders: [
                    {
                        id: 6,
                        name: 'folder2',
                        files: [
                            {
                                id: 7,
                                name: 'file3'
                            },
                            {
                                id: 8,
                                name: 'file4'
                            }
                        ],
                        folders: [
                            {
                                id: 18,
                                name: 'table2',
                                files: [
                                    {
                                        id: 19,
                                        name: 'subfile3'
                                    },
                                    {
                                        id: 20,
                                        name: 'subfile4'
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                id: 21,
                name: 'config_test_project1',
                folders: [
                    {
                        id: 22,
                        name: 'table1',
                        files: [
                            {
                                id: 23,
                                name: 'file1'
                            },
                            {
                                id: 24,
                                name: 'file2'
                            }
                        ],
                        folders: [
                            {
                                id: 25,
                                name: 'table3',
                                files: [
                                    {
                                        id: 26,
                                        name: 'subfile1'
                                    },
                                    {
                                        id: 27,
                                        name: 'subfile2'
                                    },
                                    {
                                        id: 28,
                                        name: 'subfile3'
                                    },
                                    {
                                        id: 29,
                                        name: 'subfile4'
                                    },
                                    {
                                        id: 30,
                                        name: 'subfile5'
                                    },
                                    {
                                        id: 31,
                                        name: 'subfile6'
                                    },
                                    {
                                        id: 32,
                                        name: 'subfile7'
                                    },
                                    {
                                        id: 33,
                                        name: 'subfile8'
                                    },
                                    {
                                        id: 34,
                                        name: 'subfile9'
                                    },
                                    {
                                        id: 35,
                                        name: 'subfile9'
                                    },
                                    {
                                        id: 36,
                                        name: 'subfile9'
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
        ];


        const renderTree = (nodes) => (
            <TreeItem
                key={nodes.id}
                nodeId={nodes.id.toString()}
                label={nodes.name}
                icon={nodes.folders ? <AccountTreeIcon/> : <FolderIcon/>}
            >
                {Array.isArray(nodes.files) &&
                    nodes.files.map((file) => (
                        <TreeItem key={file.id} nodeId={file.id.toString()} label={file.name}/>
                    ))}
                {Array.isArray(nodes.folders) &&
                    nodes.folders.map((folder) => (
                        <TreeItem key={folder.id} nodeId={folder.id.toString()} label={folder.name}
                                  icon={<FolderIcon/>}>
                            {Array.isArray(folder.folders) &&
                                folder.folders.map((subfolder) => (
                                    <TreeItem
                                        key={subfolder.id}
                                        nodeId={subfolder.id.toString()}
                                        label={subfolder.name}
                                        icon={<CalendarViewMonthIcon/>}
                                    >
                                        {Array.isArray(subfolder.files) &&
                                            subfolder.files.map((file) => (
                                                <TreeItem key={file.id} nodeId={file.id.toString()} label={file.name}/>
                                            ))}
                                    </TreeItem>
                                ))}
                        </TreeItem>
                    ))}
            </TreeItem>
        )

        return (
            <Box sx={{
                flexGrow: 1,
                minWidth: 300,
                overflowY: 'auto',
                overflowX: 'hidden',
                width: '100%',
                marginTop: '1rem'
            }}>
                <Box sx={{mb: 1}}>
                    <Button
                        sx={{marginBottom: '1rem'}}
                        variant='contained'
                        onClick={handleExpandClick}>
                        {expanded.length === 0 ? 'Expand all' : 'Collapse all'}
                    </Button>
                </Box>

                <TreeView
                    aria-label="controlled"
                    defaultCollapseIcon={<ExpandMoreIcon/>}
                    defaultExpandIcon={<ChevronRightIcon/>}
                    expanded={expanded}
                    onNodeToggle={handleToggle}
                >
                    <div style={{overflowY: 'auto', overflowX: 'hidden'}}>
                        {
                        data.map(el => (renderTree(el)))
                    }
                    </div>


                </TreeView>
            </Box>
        )
    }


    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <div className='upload-source-data-modal-window'>
                <div className='existing-source-dataset-container'>
                    <h2 className='source-dataset-container-header'>Choose Active Source Dataset(s):</h2>
                    <p>Browse existing source datasets to assign to the Template Object.</p>
                    <Button
                        onClick={_ => setAssignModal(true)}
                        disabled={!rowSelectionModel.length}
                        className='upload-source-data-assign-btn'
                        variant="contained">Assign
                    </Button>

                    <div className='upload-source-table-container'>

                        <DataGrid
                            rows={rows}
                            columns={[

                                {
                                    field: 'status',
                                    headerName: '',
                                    width: 120,
                                    renderCell: (params) => {
                                        return priorityFormater(params.value);
                                    }
                                },
                                {
                                    field: 'sourceDatasetName',
                                    headerName: 'Source Dataset Name',
                                    width: 280,
                                    editable: true,
                                },
                                {
                                    field: 'sourceSystem',
                                    headerName: 'Source System',
                                    width: 280,
                                    editable: true,
                                },
                                {
                                    field: 'assignedTemplateObject',
                                    headerName: 'Assigned Template Object',
                                    width: 350,
                                    editable: true,
                                },]}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 5,
                                    },
                                },
                            }}
                            pageSizeOptions={[5]}
                            checkboxSelection
                            disableRowSelectionOnClick
                            onRowSelectionModelChange={(newRowSelectionModel) => {
                                const selectedRowsData = newRowSelectionModel.map((id) => rows.find((row) => row.id === id));

                                setRowSelectionModel(newRowSelectionModel);
                                setSelectedRows(selectedRowsData)
                            }}
                            rowSelectionModel={rowSelectionModel}

                        />
                    </div>
                </div>

                <hr></hr>

                <div className='new-source-dataset-container'>
                    <h2>Upload New Source Dataset(s):</h2>

                    <div className='new-source-dataset-form-container'>
                        <FormControl variant="standard" sx={{m: 1, width: '35%', minWidth: '260px'}}>
                            <InputLabel sx={{marginLeft: '1px'}} id="demo-simple-select-label">Select Data Ingestion
                                Method:</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={ingestionMethod}
                                variant='standard'
                                onChange={event => setIngestionMethod(event.target.value)}
                            >
                                {
                                    uploadFileTypes.map(type => {
                                        return (
                                            <MenuItem value={type}>{type}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>

                        <FormControl variant="standard" sx={{m: 1, width: '25%', minWidth: '260px'}}>
                            <InputLabel sx={{marginLeft: '1px'}} id="demo-simple-select-label">Select Source
                                System:</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={sourceSystem}
                                variant='standard'
                                onChange={event => setSourceSystem(event.target.value)}
                            >
                                {
                                    uploadFileTypes.map(type => {
                                        return (
                                            <MenuItem value={type}>{type}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>

                    </div>
                    {
                        ingestionMethod === 'Database Connection' ?
                            <div className='upload-source-data-connect-db-container'
                                 style={connectDb ? {height: '50vh'} : null}>
                                <div className='upload-source-data-connect-db-header'>
                                    <span><strong>Connect to Database: </strong>DB Name</span>
                                    {
                                        !connectDb ?
                                            <Button onClick={_ => setConnectDb(true)}
                                                    variant='contained'>Connect</Button>
                                            :
                                            <Button onClick={_ => setConnectDb(false)}
                                                    variant='contained'>Upload</Button>
                                    }

                                </div>

                                <div style={{width: '90%', paddingLeft: '1rem', overflowY: 'auto'}}>
                                    {
                                        connectDb && <DbTree/>
                                    }
                                </div>
                            </div>
                            :
                            <Dragndrop/>
                    }

                    <div style={{display: 'flex', marginTop: 'auto', marginLeft: 'auto', padding: '0 1rem 1rem 0'}}>
                        <Button
                            variant="contained">Save & Close
                        </Button>
                    </div>



                </div>

                <MainModal modalOpen={assignModal} setModalOpen={setAssignModal}
                           headerText='Assign Existing Source Datasets'
                           Child={AssingDatasetModal}
                           tooltip={true}
                           selectedRows={selectedRows}
                           setSelectedRows={setSelectedRows}
                           setRowSelectionModel={setRowSelectionModel}
                           rowSelectionModel={rowSelectionModel}
                />

                <MainModal
                    modalOpen={dataError}
                    setModalOpen={setDataError}
                    headerText='Display Data Ingestion Errors'
                    Child={DataIngestionErrorModal}
                />


            </div>
        </div>
    )
}

export default UploadSourceDataModal;