import './DataIngestionErrorModal.css';
import {Accordion, AccordionSummary, AccordionDetails, Typography} from "@mui/material";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const DataIngestionErrorModal = () => {

    const errors = [
        {
            status: '400 Error',
            errorMsg: 'Error: Sample Traceback error: Sample Traceback error: Sample Traceback error: Sample Sample Traceback error: Error: Sample Traceback error: Sample Traceback error: Sample Traceback error: Sample Sample Traceback error: Sample Error: Sample Traceback Sample Error: Sample Traceback error: Sample Traceback error: Sample Traceback error: Sample Sample Traceback error: Sample Error: Sample Traceback error: Sample Traceback error: Sample Traceback error: Sample Sample Traceback error: Sample',
            recommendedFixes: 'Recommended fix sample instructions'
        },
        {
            status: 'Other Error',
            errorMsg: 'Traceback error: Sample',
            recommendedFixes: 'Recommended fix sample instructions'
        },
        {
            status: 'Different Error',
            errorMsg: 'Error: Sample Traceback error: Sample Traceback error: Sample Traceback error: Sample Sample Traceback error: Sample Error: Sample Traceback error: Sample Traceback error: Sample Traceback error: Sample Sample Traceback error: Sample Error: Sample Traceback error: Sample Traceback error: Sample Traceback error: Sample Sample Traceback error: Sample',
            recommendedFixes: 'Recommended fix sample instructions'
        },
    ]

    return (
        <div className='ingestion-error-accordion-container ingestion-error-accordion-scroll'>

            {
                errors.map(error => {
                    return (
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon size='large' />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <div className='ingestion-error-accordion-summary'>
                                    <span>MARM</span>
                                    <span>Info</span>
                                    <span>{error.status}</span>
                                    <span>timestamp</span>
                                </div>
                            </AccordionSummary>

                            <AccordionDetails>
                                <Typography className='ingestion-error-accordion-details-header' variant='h4'>
                                    Error Message
                                </Typography>


                                <Typography className='ingestion-error-accordion-error-msg ingestion-error-accordion-scroll '>
                                    {error.errorMsg}
                                </Typography>

                                <hr/>

                                <Typography className='ingestion-error-accordion-details-header' variant='h4'>
                                    Recommended Fixes
                                </Typography>


                                <Typography className='ingestion-error-accordion-error-msg ingestion-error-accordion-scroll'>
                                    {error.recommendedFixes}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    )
                })
            }
        </div>
    )
}

export default DataIngestionErrorModal;