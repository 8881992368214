import {
    TextField,
    Autocomplete,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    CircularProgress
} from "@mui/material";
import {useCallback, useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import {DataGrid} from "@mui/x-data-grid";
import './EditLookupTableModal.css';

import WarningIcon from '@mui/icons-material/Warning';
import close from "../../assets/close.png";
import {getLookupValues, getProjectSourceSystems, getProjectTargetSystems} from "../../../../api/requests";

const systems = [
    'SAP ECC',
    'SAP S/4HANA'
]

const rowsExample = [
    {
        id: 1,
        sourceValue: 'B05',
        sourceDescription: 'Buyer #5',
        targetValue: '100',
        targetDescription: 'Buyer #100',
        updatedBy: 'Colin Bryan',
        updatedDate: ' 6/15/2023',
    },
    {
        id: 2,
        sourceValue: 'B05',
        sourceDescription: 'Buyer #5',
        targetValue: '100',
        targetDescription: 'Buyer #100',
        updatedBy: 'Colin Bryan',
        updatedDate: ' 6/15/2023',
    },
    {
        id: 3,
        sourceValue: '',
        sourceDescription: 'Buyer #5',
        targetValue: '100',
        targetDescription: 'Buyer #100',
        updatedBy: 'Colin Bryan',
        updatedDate: ' 6/15/2023',
    },
    {
        id: 4,
        sourceValue: '',
        sourceDescription: 'Buyer #5',
        targetValue: '100',
        targetDescription: 'Buyer #100',
        updatedBy: 'Colin Bryan',
        updatedDate: ' 6/15/2023',
    }

];

const columns = [
    {
        field: 'sourceValue',
        headerName: 'Source Value',
        width: 120,
        editable: true,
    }, {
        field: 'sourceDescription',
        headerName: 'Source Description',
        width: 170,
        editable: true,
    },
    {
        field: 'targetValue',
        headerName: 'Target Value',
        width: 120,
        editable: true,
    },
    {
        field: 'targetDescription',
        headerName: 'Target Description',
        width: 200,
        editable: true,
    },
    {
        field: 'updatedBy',
        headerName: 'Updated By',
        width: 250,
        editable: true,
    },
    {
        field: 'updatedDate',
        headerName: 'Updated Date',
        width: 250,
        editable: true,
    },
]

const EditLookupTableModal = ({selectedLookupTable}) => {
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    // change rows variable
    const [rows, setRows] = useState(rowsExample);
    const [sourceSystems, setSourceSystems] = useState([]);
    const [targetSystems, setTargetSystems] = useState([]);
    const [lookupValues, setLookupValues] = useState([])
    const [isLoading, setIsLoading] = useState(true);


    // console.log(selectedLookupTable)
    // console.log(sourceSystems)

    useEffect(() => {
        getProjectSourceSystems(1, localStorage.getItem('token')).then(res => setSourceSystems(res.data.project_source_systems)).catch(err => console.log(err))
        getProjectTargetSystems(1, localStorage.getItem('token')).then(res => setTargetSystems(res.data.project_target_systems)).catch(err => console.log(err))
        getLookupValues(1, selectedLookupTable.recid, localStorage.getItem('token'))
            .then(res => {
                setLookupValues(res.data.lookup_values)
                setIsLoading(false);
            })
            .catch(err => console.log(err))


    }, [])

    const getRows = () => {
        console.log(lookupValues)
        const test = lookupValues.map(el => {
            return {
                    id: el.recid,
                    sourceValue: el.source_value,
                    sourceDescription: el.source_description,
                    targetValue: el.target_value,
                    targetDescription: el.target_description,
                    updatedBy: el.updated_by,
                    updatedDate: el.updated_date,
                }})

        console.log(test)
        return test
    }


    const findDuplicates = (arr) => {
        let sorted_arr = arr.slice().sort();
        let results = [];

        for (let i = 0; i < sorted_arr.length - 1; i++) {
            if (sorted_arr[i + 1] == sorted_arr[i]) {
                results.push(sorted_arr[i]);
            }
        }

        return Array.from(new Set(results)).join('", ');
    }

    const addNewRow = () => {
        setRows([...rows, {
            id: rows.length + 1,
            sourceValue: '',
            sourceDescription: '',
            targetValue: '',
            targetDescription: '',
            updatedBy: '',
            updatedDate: '',
        }])
    }

    const getSelectedSourceSystem = () => {
        const system = sourceSystems.filter(el => el.recid === selectedLookupTable.source_system_id)
        console.log('qwe')
        return system.system_name + ' ' + system.system_version
    }


    const Dragndrop = () => {
        const [files, setFiles] = useState();
        const [fields, setFields] = useState([])

        const onDrop = useCallback(acceptedFiles => {
            setFiles(acceptedFiles)

        }, [])

        const {getRootProps, getInputProps} = useDropzone({
            onDrop
        })

        const FileContainer = () => {
            return (
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
                    <h2>File Name</h2>

                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '80%',}}>
                        {
                            files.map(file => {
                                return (
                                    <div style={{
                                        display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '2rem',
                                        padding: '2% 0', borderBottom: '1px solid lightgray', width: '100%'
                                    }}>
                                        <img onClick={_ => setFiles(files?.filter(el => el !== file))}
                                             style={{width: '16px', height: '16px', cursor: 'pointer'}}
                                             src={close}></img>

                                        <span>{file.name}</span>

                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            )
        }


        return (
            <div style={{width: '90%', padding: '1rem', border: '1px solid lightgray', marginTop: '1rem'}}>
                <div>
                    <h2 style={{padding: 0, marginTop: 0}}>Upload Files:</h2>
                    <div style={{margin: '0 auto'}}
                         className='dropzone-area' {...(!files?.length ? getRootProps() : null)}>

                        {!files?.length ? "Drag your file or select file" : <FileContainer/>}
                        {!files?.length && <input className='dropzone-area' {...getInputProps()}/>}
                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        width: '100%',
                        alignItems: 'center',
                        marginBottom: '3%'
                    }}>

                        <Button
                            disabled={!files?.length}
                            variant='contained'
                            sx={{marginRight: '2%', marginTop: '4%'}}>Upload</Button>
                    </div>

                    {
                        !!files?.length &&
                        <p style={{marginLeft: '25%'}}>Processing source datasets. Do not close the page...</p>
                    }
                </div>
            </div>

        )
    }

    const SourceSystemSelect = () => {
        const [selectedSourceSystem, setSelectedSourceSystem] = useState(selectedLookupTable.source_system)

        return (
            <>
                <FormControl>
                    <InputLabel className='system-select-input-label'>Source System</InputLabel>
                    <Select
                        size='small'
                        variant='standard'
                        value={selectedSourceSystem}
                        onChange={e => setSelectedSourceSystem(prev => e.target.value)}
                    >
                        {sourceSystems?.map(system => {
                            return <MenuItem
                                value={system.system_name + ' ' + system.system_version}>{system.system_name} {system.system_version}</MenuItem>
                        })}

                    </Select>
                </FormControl>

            </>
        )
    }

    const TargetSystemSelect = () => {
        const [selectedTargetSystem, setSelectedTargetSystem] = useState(selectedLookupTable.target_system)

        return (
            <>
                <FormControl>
                    <InputLabel className='system-select-input-label'>Target System</InputLabel>
                    <Select
                        size='small'
                        variant='standard'
                        value={selectedTargetSystem}
                        onChange={e => setSelectedTargetSystem(prev => e.target.value)}
                    >
                        {targetSystems?.map(system => {
                            return <MenuItem
                                value={system.system_name + ' ' + system.system_version}>{system.system_name} {system.system_version}</MenuItem>
                        })}

                    </Select>
                </FormControl>

            </>
        )
    }

    return (
        <div className='edit-lookup'>

            {
                isLoading ? <CircularProgress style={{margin: 'auto auto'}} size={'10rem'}/> :
                    <>
                        <div className='edit-lookup-main'>
                <div className='edit-lookup-instructions-container'>
                    <h1 style={{margin: '0'}}>Instructions:</h1>
                    <div className='edit-lookup-instructions'>
                        <h2>1. Edit the Lookup Table Name and Comments:</h2>
                        <TextField
                            className='edit-lookup-text-field'
                            id="standard-basic"
                            label="Name"
                            placeholder="Lookup Table Name Enter Descriptive Name for the Lookup Table"
                            variant="standard"
                            value={selectedLookupTable.lookup_table_name}
                        />

                        <TextField
                            className='edit-lookup-text-field'
                            id="standard-basic"
                            label="Comments"
                            placeholder="Succinct Description Succinct Description"
                            variant="standard"
                            value={selectedLookupTable.comments}
                        />


                        <SourceSystemSelect/>


                        <TargetSystemSelect/>


                        <h2 style={{marginBottom: '0'}}>2. Upload Data to Lookup Table:</h2>
                        <p>Download this template, update values, and upload below to populate Lookup Table.</p>
                        <Dragndrop/>
                    </div>
                </div>

                <hr></hr>

                <div className='edit-lookup-table-container'>
                    <h1 style={{margin: '0', flex: 0}}>Lookup Table Data:</h1>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: '1rem'
                    }}>
                        <span>View and edit existing Lookup Table data.</span>
                        <div className='edit-lookup-warrning-container'>
                            <WarningIcon sx={{color: '#ef7917'}}/>
                            <span>Correct duplicate source values: "{findDuplicates(rows.map(({sourceValue}) => sourceValue))}</span>
                        </div>
                    </div>

                    <div>
                        <div className='edit-lookup-btns-container'>
                            <Button
                                className='edit-lookup-btn'
                                onClick={addNewRow}
                                variant="contained">Add new row
                            </Button>
                            <Button disabled={!rowSelectionModel.length} variant="outlined" color='error'>Delete
                                Row(s)</Button>
                        </div>

                        <div className='display-source-grid-container'>
                            <DataGrid
                                rows={lookupValues.map(el => {
                                    return {
                                            id: el.recid,
                                            sourceValue: el.source_value,
                                            sourceDescription: el.source_description,
                                            targetValue: el.target_value,
                                            targetDescription: el.target_description,
                                            updatedBy: el.updated_by,
                                            updatedDate: el.updated_date,
                                    }})
                                }
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 5,
                                        },
                                    },
                                }}
                                pageSizeOptions={[5]}
                                checkboxSelection
                                disableRowSelectionOnClick
                                onRowSelectionModelChange={(newRowSelectionModel) => {
                                    setRowSelectionModel(newRowSelectionModel);
                                }}
                                rowSelectionModel={rowSelectionModel}
                            />
                        </div>

                    </div>

                </div>
            </div>

            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button className='manage-load-data-save-close-btn' variant='contained'>Save & Close</Button>
            </div>
                    </>
            }

        </div>
    )
}

export default EditLookupTableModal