import './ManageErrorLogsModal.css';
import {Button} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {useCallback, useState} from "react";
import {useDropzone} from "react-dropzone";
import MainModal from "../../../../../helpers/Modal/MainModal";


import download from '../../../assets/download.png';
import close from '../../../assets/close.png';
import UploadLoadDatasetModal from "../../UploadLoadDatasetModal/UploadLoadDatasetModal";

const ManageErrorLogsModal = () => {
    const [rowSelectionModel, setRowSelectionModel] = useState([]);


    const columns = [
        {
            field: 'errorLogName',
            headerName: 'Error Log Name',
            width: 200,
        },
        {
            field: 'uploadedBy',
            headerName: 'Uploaded By',
            width: 200,
        },
        {
            field: 'uploadedDate',
            headerName: 'Uploaded Date',
            width: 200,
        },
        {
            field: 'download',
            headerName: 'Download',
            width: 200,
            renderCell: (params) => {
                return <img style={{width: '25px'}} src={download}></img>
            }
        },
    ]

    const rows = [
        {
            id: 1,
            errorLogName: 'Materials Error Log.xlsx',
            uploadedBy: '',
            uploadedDate: '',
            download: '',
        },
        {
            id: 2,
            errorLogName: 'Materials Error Log.xlsx',
            uploadedBy: '',
            uploadedDate: '',
            download: '',
        },
    ]

    const Dragndrop = () => {
        const [files, setFiles] = useState()
        const [fileProcessModal, setFileProcessModal] = useState(false);

        const onDrop = useCallback(acceptedFiles => {
            setFiles(acceptedFiles)
        }, [])

        const {getRootProps, getInputProps} = useDropzone({onDrop})

        const FileContainer = () => {

            return (
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
                    <h2>File Name</h2>

                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '80%',}}>
                        {
                            files.map(file => {
                                return (
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '2rem',
                                        padding: '2% 0', borderBottom: '1px solid lightgray', width: '100%'}}>
                                        <img onClick={_ => setFiles(files?.filter(el => el !== file))} style={{width: '16px', height: '16px', cursor: 'pointer'}} src={close}></img>

                                        <span>{file.name}</span>

                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            )
        }

        return (
            <div style={{width: '90%', padding: '1rem', border: '1px solid lightgray'}}>
                <h2 style={{padding: 0, marginTop: 0}}>Upload Files:</h2>
                <div>
                    <div className='manage-error-logs-dropzone dropzone-area'  {...(!files?.length? getRootProps() : null)}>

                    {!files?.length ? "Drag your file or select file" : <FileContainer/>}
                    {!files?.length && <input className='dropzone-area' {...getInputProps()} />}
                </div>

                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%', alignItems: 'center', marginBottom: '3%'}}>

                <Button
                    disabled={!files?.length}
                    variant='contained'
                    onClick={_ => setFileProcessModal(true)}
                    sx={{marginRight: '2%', marginTop: '4%'}}>Process</Button>
                </div>

                {
                    !!files?.length &&
                        <p style={{marginLeft: '25%'}}>Processing source datsaets. Do not close the page...</p>
                }


                <MainModal modalOpen={fileProcessModal} setModalOpen={setFileProcessModal} headerText='Upload Load Dataset'
                    Child={UploadLoadDatasetModal}
                    files={files}
                    setFiles={setFiles}
                    isOpen={setFileProcessModal}
                />

                </div>

                <div/>
            </div>

        )
    }


    return (
        <div style={{width: '70vw', height: '60vh', minWidth: 1500}}>
            <div className='manage-error-logs-main'>
                <div>
                    <h2>Review Existing Error Log(s): Materials_2023_06_23</h2>
                    <div className='manage-error-logs-btns-container'>
                        <Button variant='contained'>Download All</Button>
                        <Button
                            disabled={!rowSelectionModel.length}
                            variant='outlined'
                            color='error'>Delete File(s)
                        </Button>
                    </div>

                    <div>
                        <DataGrid
                            checkboxSelection
                            sx={{marginTop: '1rem', "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {display: "none"}}}
                            rows={rows}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 5,
                                    },
                                },
                            }}
                            pageSizeOptions={[5]}
                            disableRowSelectionOnClick
                            selection
                            onRowSelectionModelChange={(newRowSelectionModel) => {
                                setRowSelectionModel(newRowSelectionModel);
                            }}
                            rowSelectionModel={rowSelectionModel}
                        />
                    </div>


                </div>

                <hr style={{margin: '1rem'}}/>

                <div className='manage-error-logs-upload-file-container'>
                    <h2>Upload Error Log(s):</h2>
                    <Dragndrop/>

                </div>
            </div>

            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button className='post-load-processing-save-close-btn' variant='contained'>Save & Close</Button>
            </div>

        </div>
    )
}

export default ManageErrorLogsModal;
