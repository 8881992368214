import Avatar from "@mui/material/Avatar";
import {normalize_color, stringToColor} from "../../../helpers/utils/stringToColor";
import dayjs from "dayjs";
import {DataGrid} from "@mui/x-data-grid";

const FollowUpDataGrid = ({userData, allUserData, followUpData, STO = false}) => {
    let columns = [
        {field: 'follow_up_id', headerName: 'ID', minWidth: 40},
        {field: 'follow_up_level', headerName: 'Follow Up Level', minWidth: 150, flex: 1},
        {field: 'follow_up_title', headerName: 'Follow Up Title', minWidth: 150, flex: 1},
        {field: 'follow_up_priority', headerName: 'Follow Up Priority', minWidth: 150, flex: 1},
        {field: 'follow_up_status', headerName: 'Follow Up Status', minWidth: 150, flex: 1},
        {
            field: 'follow_up_assignees',
            headerName: 'Follow Up Assignees',
            minWidth: 250,
            flex: 1,
            renderCell: cellValues => {
                return <div style={{display: 'flex', flexDirection: 'column', gap: '.5rem'}}>
                    {cellValues.formattedValue.map(el => {
                        if (el.assignment_type === 'U') {
                            const user = allUserData[el.assigned_resource]
                            return <div style={{display: "flex", alignItems: 'center', gap: '.5rem'}}>
                                <Avatar sx={{
                                    width: 40,
                                    height: 40,
                                    bgcolor: normalize_color(stringToColor(user?.first_name ? user.first_name + ' ' + user.last_name : el.assigned_resource), 100)
                                }}
                                        aria-label="recipe" src={user.image_link}>
                                    {user?.first_name ? `${user.first_name[0]} ${user.last_name[0]}` : el.assigned_resource[0].toUpperCase()}
                                </Avatar>
                                {user?.first_name ? user.first_name + ' ' + user.last_name : el.assigned_resource}
                            </div>
                        } else if (el.assignment_type === 'UG') {
                            return <div style={{display: "flex", alignItems: 'center', gap: '.5rem'}}>
                                <Avatar sx={{
                                    width: 40,
                                    height: 40,
                                    bgcolor: normalize_color(stringToColor(el.assigned_resource), 100)
                                }} aria-label="recipe">
                                    {el.assigned_resource[0]}
                                </Avatar>
                                {el.assigned_resource}
                            </div>
                        }
                    })}
                </div>
            }
        },
        {
            field: 'follow_up_due_date',
            headerName: 'Follow Up Due Date',
            minWidth: 150,
            flex: 1,
            renderCell: (cellValues) => {
                return dayjs(cellValues.formattedValue).tz(userData.timezone).format(userData.date_format === 'mm' ? 'MM/DD/YY' : 'DD/MM/YY')
            }
        },
        {
            field: 'created_by', headerName: 'Created By', minWidth: 150, flex: 1, renderCell: cellValues => {
                const user = allUserData[cellValues.formattedValue]

                return <div style={{display: "flex", alignItems: 'center', gap: '.5rem'}}>
                    <Avatar sx={{
                        width: 40,
                        height: 40,
                        bgcolor: normalize_color(stringToColor(user.first_name ? user.first_name + ' ' + user.last_name : user.username), 100)
                    }}
                            aria-label="recipe" src={user.image_link}>
                        {user.first_name ? `${user.first_name[0]} ${user.last_name[0]}` : user.username[0].toUpperCase()}
                    </Avatar>
                    {user.first_name ? user.first_name + ' ' + user.last_name : user.username}
                </div>
            }
        },
        {
            field: 'created_date', headerName: 'Creation Date', minWidth: 150, flex: 1, renderCell: (cellValues) => {
                return dayjs(cellValues.formattedValue).tz(userData.timezone).format(userData.date_format === 'mm' ? 'MM/DD/YY' : 'DD/MM/YY')
            }
        }
    ]

    if (STO)
        columns.splice(3, 0, {
                field: 'functional_field', headerName: 'Functional Name', minWidth: 150, flex: 1
            },
            {
                field: 'technical_field', headerName: 'Technical Name', minWidth: 150, flex: 1
            })

    return <DataGrid rows={followUpData.map((el, i) => {
        return {id: i, ...el}
    })} columns={columns} hideFooter disableRowSelectionOnClick getRowHeight={() => 'auto'}/>
}

export default FollowUpDataGrid