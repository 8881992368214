import {
    Button,
    Paper,
    Table,
    TableBody,
    TableHead,
    TableCell,
    TableContainer,
    TableRow,
    Checkbox,
    FormControl, Select, MenuItem
} from "@mui/material";
import key from '../assets/key.png';
import search from '../../ManageObjects/assets/search2.png';
import './MarketTargetFields.css';
import React, {useState} from "react";
import SettingsIcon from '@mui/icons-material/Settings';
import MainModal from "../../../helpers/Modal/MainModal";
import ViewWarnings from "./ViewWarnings/ViewWarnings";
import './MarketTargetFields.css';
import ManageNotesModal from "../../ManageObjects/NotesModal/ManageNotesModal";
import ViewChangeLogModal from "../../ManageObjects/ViewChangeLog/ViewChangeLogModal";
import ManageFollowUpsModal from "../../ManageObjects/ManageFollowUpsModal/ManageFollowUpsModal";
import {DataGrid} from "@mui/x-data-grid";



const columnsData = [
    {id: 'functionalName', label: 'Functional Name', minWidth: 170, align: 'left'},
    {id: 'technicalName', label: 'Technical Name', minWidth: 150, align: 'center'},
    {
        id: 'required',
        label: 'Required?',
        minWidth: 270,
        align: 'center',
    },
    {
        id: 'links',
        label: 'Links',
        minWidth: 50,
        align: 'center',
    },
];

const rows = [
    {
        id: 1,
        loadDataset: 'Materials_2023_06_23',
        description: 'Manual test load file',
        createdBy: 'Automated',
        createdDate: 'timestamp',
        manageLoadData: 'data',
        manageValidation: 'data',
        preLoadValidatedBy: 'Not Validated',
        preLoadValidatedDate: 'Not Validated',
        postLoadValidatedBy: 'Not Validated',
        postLoadValidatedDate: 'Not Validated',
    },
    {
        id: 2,
        loadDataset: 'Materials_2023_06_23',
        description: 'Manual test load file',
        createdBy: 'Automated',
        createdDate: 'timestamp',
        manageLoadData: 'data',
        manageValidation: 'data',
        preLoadValidatedBy: 'Colin Bryan',
        preLoadValidatedDate: 'timestamp',
        postLoadValidatedBy: 'Not Validated',
        postLoadValidatedDate: 'Not Validated',
    },
    {
        id: 3,
        loadDataset: 'Materials_2023_06_23',
        description: 'Manual test load file',
        createdBy: 'Automated',
        createdDate: 'timestamp',
        manageLoadData: 'data',
        manageValidation: 'data',
        preLoadValidatedBy: 'Not Validated',
        preLoadValidatedDate: 'Not Validated',
        postLoadValidatedBy: 'Not Validated',
        postLoadValidatedDate: 'Not Validated',
    }
];


const MarkTargetFields = ({warningModalOpen, setWarningModalOpen, isRowSelected, setIsRowSelected, rowsData, setRowsData, setMarkTargetFieldsSelectedRows }) => {
    const [hoveredCellId, setHoveredCellId] = useState(null);
    // const [rows, setRows] = useState(rowsData);

    const [showNotes, setShowNotes] = useState(false);
    const [showFollowUps, setShowFollowUps] = useState(false);
    const [viewChangeLogs, setViewChangeLogs] = useState(false);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);

    const columns = [
        // {
        //     field: 'checkbox',
        //     headerName: '',
        //     width: 100,
        // },
        {
            field: 'functionalName',
            headerName: 'Functional Name',
            width: 400,
            editable: true,
        },
        {
            field: 'technicalName',
            headerName: 'Technical Name',
            width: 400,
            editable: true,
            renderCell: (params) => {
                return (
                    <div style={{display: 'flex'}} onMouseOut={_ => setHoveredCellId(null)} onMouseOver={_ => setHoveredCellId(params.id)}>
                        <span style={{width: '3.2rem'}}>{params.row.technicalName}</span>

                        <img
                            onClick={_ => setRowsData((prev) => prev.map(el => {
                                if (el.id === params.id) {
                                    return {...el, key: !el.key}
                                }
                                return {...el, key: el.key}
                            }))}
                            className='mark-target-fields-key-img'
                            style={!params.row.key ? {opacity: '30%'} : null }
                            src={key}>

                        </img>

                        {
                            params.id === hoveredCellId &&
                                <img style={{width: 22, height: 22, marginLeft: '1rem', marginBottom: '-0.4rem', cursor: 'pointer'}} src={search}></img>
                        }
                    </div>
                )
            }
        },
        {
            field: 'required',
            headerName: 'Required?',
            width: 450,
            editable: true,
            renderCell: (params) => {
                return (
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <Select
                              sx={{
                                boxShadow: 'none',
                                '.MuiOutlinedInput-notchedOutline': {border: 0},
                              }}
                              // labelId="demo-simple-select-standard-label"
                              variant='outlined'
                              id="demo-simple-select-standard"
                              value={params.row.required}
                              onChange={e => {
                                  setRowsData(prev => prev.map(el => {
                                      if (el === params.row) {
                                          return {...el, required: e.target.value}
                                      }

                                      return el
                                  }))
                              }}
                            >
                              <MenuItem value={'Yes'}>Yes</MenuItem>
                              <MenuItem value={'No'}>No</MenuItem>
                              <MenuItem value={'TBD'}>TBD</MenuItem>
                              <MenuItem value={'System Generated'}>System Generated</MenuItem>
                              <MenuItem value={'-'}>-</MenuItem>
                            </Select>
                        </FormControl>

                        <SettingsIcon
                            onClick={_ => setRowsData((prev) => prev.map(el => {
                                if (el.id === params.id) {
                                    return {...el, settings: !el.settings}
                                }
                                return {...el, settings: el.settings}
                            }))}
                            className='mark-target-fields-settings-img'
                            sx={!params.row.settings ? {opacity: '30%'} : null }
                        />

                    </div>
                )
            }
        },
        {
            field: 'links',
            headerName: 'Links',
            width: 750,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return (
                    <div style={{display: 'flex', gap: '2rem'}}>
                        <Button
                            onClick={_ => setShowFollowUps(true)}
                            sx={{whiteSpace: 'nowrap'}}
                            variant='outlined'>Follow-ups (2)
                        </Button>
                        <Button
                            onClick={_ => setShowNotes(true)}
                            sx={{whiteSpace: 'nowrap'}}
                            variant='outlined'>Notes (3)
                        </Button>
                        <Button
                            onClick={_ => setViewChangeLogs(true)}
                            sx={{whiteSpace: 'nowrap'}}
                            variant='outlined'
                            // disabled={true}
                        >Change Log
                        </Button>
                    </div>
                )

            },
        },

    ]


    return (
        <div style={{display: 'flex', justifyContent: 'center', marginTop: '2rem'}}>
            <div style={{width: '88.5%'}}>
                {/*<div style={{width: '100%', display: 'flex', minWidth: 1200, height: '4rem'}}>*/}
                {/*    <span style={{color:'white', fontSize: 30, width: '65%', height: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center', backgroundColor: '#1D4956'}}>Target Data</span>*/}
                {/*    <span style={{color:'white', fontSize: 30, width: '35%', height: '100%', alignItems: 'center', minWidth: 600, display: 'flex', justifyContent: 'center', backgroundColor: '#613FEBFC'}}>Quick Actions</span>*/}
                {/*</div>*/}
                <DataGrid
                    sx={{minWidth: 1200}}
                    checkboxSelection
                    rows={rowsData}
                    columns={columns}
                    experimentalFeatures={{ columnGrouping: true }}
                    columnGroupingModel={[
                        {
                          headerClassName: 'column-blue',
                          headerAlign: 'center',
                          freeReordering: true,
                          groupId: 'Target Data',
                          children: [{ field: 'checkbox' }, { field: 'functionalName'}, { field: 'technicalName'}, { field: 'required'}],
                        },
                        {
                          groupId: 'Quick Actions',
                          headerClassName: 'column-orange',
                          headerAlign: 'center',
                          children: [{ field: 'links' }],
                        },
                      ]}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    pageSizeOptions={[5]}
                    disableRowSelectionOnClick
                    selection
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setMarkTargetFieldsSelectedRows(newRowSelectionModel)
                        setIsRowSelected(newRowSelectionModel)
                        setRowSelectionModel(newRowSelectionModel);
                    }}
                    rowSelectionModel={rowSelectionModel}
                />

            </div>


            <MainModal
                tooltip={true}
                modalOpen={warningModalOpen}
                setModalOpen={setWarningModalOpen}
                headerText='View Warnings'
                Child={ViewWarnings}
            />

            <MainModal
                tooltip={true}
                modalOpen={showNotes}
                setModalOpen={setShowNotes}
                headerText='Manage Notes'
                Child={ManageNotesModal}/>

            <MainModal
                tooltip={true}
                modalOpen={viewChangeLogs}
                setModalOpen={setViewChangeLogs}
                headerText='View Change Log'
                Child={ViewChangeLogModal}
                selectedTemplate={'Material'}
            />

            <MainModal
                tooltip={true}
                modalOpen={showFollowUps}
                setModalOpen={setShowFollowUps}
                headerText='Manage Follow Ups'
                Child={ManageFollowUpsModal}
            />
        </div>
    )
}

export default MarkTargetFields;