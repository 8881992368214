import './DisplaySourceDataModal.css'
import {Button} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {useState} from "react";

import download from '../assets/download.png';
import poll from '../assets/poll.png';
import search from '../assets/search.png';
import warning from '../assets/warning.png';

import MainModal from "../../../helpers/Modal/MainModal";
import DataIngestionErrorModal from "../DataIngestionErrorModal/DataIngestionErrorModal";
import ManageFollowUpsModal from "../ManageFollowUpsModal/ManageFollowUpsModal";
import ManageNotesModal from "../NotesModal/ManageNotesModal";

const rows = [
        {
            id: 1,
            sourceDeatasetName: 'Customers',
            description: 'Basic Data',
            sourceSystem: 'SAP ECC',
            viewData: 'Customers',
            dataDimensions: '163053 x 100',
            uploadedBy: 'Colin Bryan',
            uploadedDate: '6/15/2023',
            active: 'Active',
            uploadedType: 'Flat File: MARA.xlsx',
            validatedBy: 'Colin Bryan',
            validatedDate: '6/15/2023'
        },
        {
            id: 2,
            sourceDeatasetName: 'Material Long Text',
            description: 'Long Text',
            sourceSystem: 'SAP ECC',
            viewData: 'Material Long Text',
            dataDimensions: '163053 x 100',
            uploadedBy: 'Colin Bryan',
            uploadedDate: '6/15/2023',
            active: 'Active',
            uploadedType: 'Flat File: MARA.xlsx',
            validatedBy: 'Colin Bryan',
            validatedDate: '6/15/2023'
        },
        {
            id: 3,
            sourceDeatasetName: 'Material Classifications',
            description: 'Plant data',
            sourceSystem: 'SAP ECC',
            viewData:  'Material Classifications',
            dataDimensions: '163053 x 100',
            uploadedBy: 'Colin Bryan',
            uploadedDate: '6/15/2023',
            active: 'Active',
            uploadedType: 'Flat File: MARA.xlsx',
            validatedBy: 'Colin Bryan',
            validatedDate: '6/15/2023'
        },
        {
            id: 4,
            sourceDeatasetName: 'Suppliers',
            description: 'Units of measure',
            sourceSystem: 'SAP ECC',
            viewData: 'Suppliers',
            dataDimensions: '163053 x 100',
            uploadedBy: 'Colin Bryan',
            uploadedDate: '6/15/2023',
            active: 'Active',
            uploadedType: 'Flat File: MARA.xlsx',
            validatedBy: 'Colin Bryan',
            validatedDate: '6/15/2023'
        },
        {
            id: 5,
            sourceDeatasetName: 'Suppliers Long Text',
            description: 'Storage location',
            sourceSystem: 'SAP ECC',
            viewData: 'Suppliers',
            dataDimensions: '163053 x 100',
            uploadedBy: 'Colin Bryan',
            uploadedDate: '6/15/2023',
            active: 'Active',
            uploadedType: 'Flat File: MARA.xlsx',
            validatedBy: 'Colin Bryan',
            validatedDate: '6/15/2023'
        }
];



const DisplaySourceDataModal = () => {
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [dataError ,setDataError] = useState(false);
    const [showNotes, setShowNotes] = useState(false);
    const [showFollowUps, setShowFollowUps] = useState(false);

    const priorityFormater = (cell) => {

        return (
            <div className='data-grid-icons-container'>

                <img className='data-grid-icon' src={search}></img>
                {
                    cell !== 'Suppliers' ?
                        <>
                        <img className='data-grid-icon' src={poll}></img>
                        <img className='data-grid-icon' src={download}></img>
                    </>
                    : <img onClick={_ => setDataError(true)} className='data-grid-icon' src={warning}></img>
                }
            </div>
        );
    };

    const columns = [
        {
            field: 'checkbox', headerName: '', width: 10 },
        {
            field: 'sourceDeatasetName',
            headerName: 'Source Dataset',
            width: 200,
            editable: true,
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 150,
            editable: true,
        },
        {
            field: 'sourceSystem',
            headerName: 'Source System',
            width: 150,
            editable: true,
        },
        {
            field: 'viewData',
            headerName: 'View Data',
            width: 120,
            renderCell: (params) => {
                return priorityFormater(params.value);
            },
        },
        {
            field: 'dataDimensions',
            headerName: 'Data Dimensions',
            width: 150,
            editable: true,
        },
        {
            field: 'uploadedBy',
            headerName: 'Uploaded By',
            width: 200,
            editable: true,
        },
        {
            field: 'uploadedDate',
            headerName: 'Uploaded Date',
            width: 200,
            editable: true,
        },
        {
            field: 'active',
            headerName: 'Active?',
            width: 200,
            editable: true,
        },
        {
            field: 'uploadedType',
            headerName: 'Uploaded Type & Name',
            width: 200,
            editable: true,
        },
        {
            field: 'validatedBy',
            headerName: 'Validated By',
            width: 200,
            editable: true,
        },
        {
            field: 'validatedDate',
            headerName: 'Validated Date',
            width: 200,
            editable: true,
        },
    ]


    return (
        <div className='display-source-data-modal-window'>
            <div className='display-source-data-btn-container'>
                <Button
                    onClick={_ => setShowFollowUps((prev) => !prev)}
                    className='display-source-data-btn'
                    variant="contained">Create Follow-Up
                </Button>
                <Button
                    onClick={_ => setShowNotes((prev) => !prev)}
                    className='display-source-data-btn'
                    variant="contained">Notes
                </Button>
                <Button
                    className='display-source-data-btn'
                    disabled={!rowSelectionModel.length}
                    variant="contained">Toggle Active Flag
                </Button>
                <Button className='display-source-data-btn' variant="contained">Mark as Validated</Button>
                <Button
                    className='display-source-data-btn'
                    variant="outlined"
                    disabled={!rowSelectionModel.length}
                    color='error'>DELETE Dataset</Button>
            </div>

            <div className='display-source-grid-container'>
                <DataGrid
                        rows={rows}
                        columns={columns}
                        initialState={{
                        pagination: {
                        paginationModel: {
                          pageSize: 5,
                        },
                        },
                        }}
                        pageSizeOptions={[5]}
                        checkboxSelection
                        disableRowSelectionOnClick
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            setRowSelectionModel(newRowSelectionModel);
                        }}
                        rowSelectionModel={rowSelectionModel}
                    />
            </div>

            <Button className='close-and-save-btn' variant='contained'>Save & Close</Button>

            <MainModal
                tooltip={true}
                modalOpen={dataError}
                setModalOpen={setDataError}
                headerText='Display Data Ingestion Errors'
                Child={DataIngestionErrorModal}

            />

            <MainModal
                tooltip={true}
                modalOpen={showFollowUps}
                setModalOpen={setShowFollowUps}
                headerText='Manage Follow Ups'
                Child={ManageFollowUpsModal}
            />

            <MainModal
                tooltip={true}
                modalOpen={showNotes}
                setModalOpen={setShowNotes}
                headerText='Manage Notes'
                Child={ManageNotesModal}/>

        </div>
    )
}

export default DisplaySourceDataModal