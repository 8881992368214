import "./ManageObjects.css";
import {useEffect, useState} from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Box,
    Button,
    TextField,
    Autocomplete,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper, InputLabel, Select, MenuItem, FormControl, Tooltip,
    FormHelperText
} from "@mui/material";
import MainModal from "../../helpers/Modal/MainModal";
import UploadSourceDataModal from "./UploadSourceDataModal/UploadSourceDataModal";
import DisplaySourceDataModal from "./DisplaySourceDataModal/DisplaySourceDataModal";

import ManageLookups from "./ManageLookups/ManageLookups";
import ChangeLoadTemplateModal from "./ChangeLoadTemplateModal/ChangeLoadTemplateModal";
import DisplayLoadDatasetsModal from "./DisplayLoadDatasetsModal/DisplayLoadDatasetsModal";
import ManageNotesModal from "./NotesModal/ManageNotesModal";
import Header from "../../helpers/header/Header";
import {useParams} from "react-router-dom";
import ViewChangeLogModal from "./ViewChangeLog/ViewChangeLogModal";
import ManageFollowUpsModal from "./ManageFollowUpsModal/ManageFollowUpsModal";
import {
    getDataObjects, getLoadMethods,
    getProjectTargetSystems,
    getProjectUsers,
    getTemplateObjectTags,
    getUserGroups,
    getUsers, updateDODescription, updateTemplateObject
} from "../../api/requests";
import ErrorIcon from "@mui/icons-material/Error";
import LinkIcon from '@mui/icons-material/Link';
import IosShareIcon from '@mui/icons-material/IosShare';
import Avatar from "@mui/material/Avatar";
import CardContent from "@mui/material/CardContent";
import {normalize_color, stringToColor} from "../../helpers/utils/stringToColor";

import {styled} from '@mui/material/styles';
import {autocompleteClasses} from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import {useAutocomplete} from '@mui/base/useAutocomplete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';


const ManageObjects = () => {
    const {projectId} = useParams();
    const [dataObjects, setDataObjects] = useState(null);
    const [selectedDataObject, setSelectedDataObject] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [uploadSourceDataModal, setUploadSourceDataModal] = useState(false);
    const [displaySourceDataModal, setDisplaySourceDataModal] = useState(false);
    const [manageLookupsModal, setManageLookupsModal] = useState(false);
    const [changeTemplateModalOpen, setChangeTemplateModal] = useState(false);
    const [displayLoadDatasetsModalOpen, setDisplayLoadDatasetsModalOpen] = useState(false);
    const [showNotes, setShowNotes] = useState(false);
    const [viewChangeLogs, setViewChangeLogs] = useState(false);
    const [showFollowUps, setShowFollowUps] = useState(false);
    const [templateObjectTags, setTemplateObjectTags] = useState([]);
    //change test value
    const [mcId, setMcId] = useState(1);
    const [userGroups, setUserGroups] = useState([]);
    const [projectTargetSystems, setProjectTargetSystems] = useState([]);
    const [projectLoadMethods, setProjectLoadMethods] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [businessStakeholder, setBusinessStakeholder] = useState(selectedTemplate?.business_stakeholder);
    const [businessStakeholderList, setBusinessStakeholderList] = useState([]);

    const [isEditing, setIsEditing] = useState(false);

    const updateTO = (type) => {
        const updatedTemplate = {...selectedTemplate};
        updatedTemplate.template_object_type = type;


        const data1 = {
            template_object_metadata: {
                "recid": 29,
                "to_name": "Source list",
                "template_object_type": "Historical",
                "description": "The template object Source list in SAP ERP is used to define the approved sources of supply for a material or a group of materials.",
                "target_system_id": 3,
                "load_method_id": 4,
                "harmonization_in_scope": false,
                "to_tags": [
                    2, 6
                ],
                "value_streams": [
                    2, 3, 4
                ],
                "business_stakeholder": "jchampagne@fissionconsulting.com",
                "user_groups_assigned": [
                    11, 14
                ],
                "users_assigned": [
                    "pmatskiv@fissionconsulting.com",
                    "cdum@fissionconsulting.com",
                    "jchampagne@fissionconsulting.com",
                    "mkovalev@fissionconsulting.com"
                ],
                "notebook_link": "https://jupyter.atom.fissionsw.com/hub/user-redirect/lab/tree/shared/Client%20Projects/GEC/Data%20Transformation/Source List (Preferred Vendors)/Source list%20-%20Transformation%20Notebook.ipynb"
            }}

        const data = {
            template_object_metadata: updatedTemplate
        }

        // updateTemplateObject(projectId, data, localStorage.getItem('token')).then(res => console.log(res)).catch(err => console.log(err))
    }


    useEffect(() => {
        getUserGroups(projectId, localStorage.getItem('token'))
            .then(res => setUserGroups(res.data.user_groups))
            .catch(err => console.log(err))

        setBusinessStakeholder(prev => selectedTemplate?.business_stakeholder)
    }, [selectedTemplate])

    useEffect(() => {
        getUsers(localStorage.getItem('token'))
            .then(res => setUsersList(res.data.users))
            .catch(err => console.log(err))

        getProjectUsers(projectId, localStorage.getItem('token'))
            .then(res => setBusinessStakeholderList(prev => res.data.project_users))
            .catch(err => console.log(err))

        getTemplateObjectTags(projectId, mcId, localStorage.getItem('token'))
            .then(res => setTemplateObjectTags(res.data.data_objects))
            .catch(err => console.log(err))
    }, [])


    useEffect(() => {
        const projectTargetSystems = async () => {
            return getProjectTargetSystems(projectId, localStorage.getItem('token')).then(res => res).catch(err => err)
        }

        projectTargetSystems().then(res => {
            setProjectTargetSystems(res.data.project_target_systems)
        })

        const projectLoadMethods = async () => {
            return getLoadMethods(projectId, localStorage.getItem('token')).then(res => res).catch(err => err)
        }

        projectLoadMethods().then(res => {
            setProjectLoadMethods(res.data.load_methods)
        })

    }, [])

    useEffect(() => {
        const dataObjects = async () => {
            return getDataObjects(projectId, mcId, localStorage.getItem('token')).then(res => res).catch(err => err)
        }

        dataObjects().then(res => {
            setDataObjects(res.data.data_objects)
        })


    }, [mcId])


    const Sidebar = () => {

        return (
            <div className='manage-objects-sidebar'>
                <h1 className='manage-objects-header manage-objects-sidebar-header'>Data Objects</h1>
                <div className='manage-objects-data-objects-container'>
                    {
                        +projectId === +mcId && dataObjects?.map(el => {
                            return (
                                <Accordion
                                    disableGutters={true}
                                    expanded={selectedDataObject?.do_name === el.do_name}
                                    className={selectedDataObject?.do_name === el.do_name ? "selected-data-object" : 'data-object-item'}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        onClick={_ => {
                                            selectedDataObject?.do_name === el.do_name ? setSelectedDataObject(null) : setSelectedDataObject(el)
                                            setSelectedTemplate(null);
                                        }}>
                                        <Typography>{el.do_name} ({el.to_count})</Typography>
                                    </AccordionSummary>
                                    {
                                        +el.to_count !== 0 ? el.template_objects.map(obj => {
                                            return (
                                                <AccordionDetails
                                                    className={selectedTemplate?.to_name === obj.to_name ? 'accordion-selected-item' : 'accordion-item'}
                                                    onClick={_ => {
                                                        setSelectedTemplate(obj)
                                                    }}>
                                                    <Typography>
                                                        {obj.to_name}
                                                    </Typography>
                                                </AccordionDetails>
                                            )
                                        }) : <AccordionDetails className='accordion-lbl'>
                                            <Typography>
                                                No Template Objects Assigned
                                            </Typography>
                                        </AccordionDetails>
                                    }

                                </Accordion>
                            )
                        })}
                </div>
            </div>
        )
    }

    const MainWindow = () => {
        const [link, setLink] = useState(selectedTemplate?.notebook_link);
        const [toType, setToType] = useState(selectedTemplate?.template_object_type)
        const [targetSystems, setTargetSystems] = useState([projectTargetSystems[0]?.system_name])
        const [loadMethods, setLoadMethods] = useState(selectedTemplate?.load_method_name)
        const [isDataHarmonization, setIsDataHarmonization] = useState(selectedTemplate?.harmonization_in_scope ? 'Yes' : 'No')
        const [toDescription, setToDescription] = useState(selectedTemplate?.description);
        const [tableData, setTableData] = useState({})

        const rows = [
            {
                name: 'Type',
                description:
                    <>
                        <Select
                            error={!toType}
                            sx={{
                                width: '70%',
                                boxShadow: 'none',
                                '.MuiOutlinedInput-notchedOutline': {border: 0},
                            }}
                            size='small'
                            value={toType}
                            onChange={e => {
                                setToType(prev => e.target.value)

                                const object_dict = JSON.stringify(selectedTemplate)
                                console.log({...selectedTemplate, template_object_type: e.target.value})
                                updateTO(e.target.value)
                                // const template_object_metadata = template_object_metadata: selectedTemplate
                                // updateTemplateObject(1, {template_object_metadata: {...selectedTemplate, template_object_type: e.target.value}}, localStorage.getItem('token')).then(res => console.log("NICE")).catch(err => console.log(err))
                            }}
                        >
                            {['Master', 'Transactional', 'Configuration', 'Historical'].map(el => {
                                return <MenuItem value={el}>{el}</MenuItem>
                            })}

                        </Select>
                        {!toType && <FormHelperText sx={{color: 'red'}} color='error'>Select a value</FormHelperText>}
                    </>
            },
            {
                name: 'Description',
                description:
                    <>
                        <TextField
                            sx={{
                                padding: 'none',
                                margin: 'none',
                                boxShadow: 'none',
                                '.MuiOutlinedInput-notchedOutline': {border: 0},
                            }}
                            fullWidth
                            value={toDescription}
                            onChange={e => setToDescription(prev => e.target.value)}
                            placeholder="Description"
                        />
                        {/*<span style={{ display:'block', width: '100%'}}>{selectedTemplate?.description}</span>*/}
                    </>

            },
            {
                name: 'Source System(s)',
                description: <span style={{padding: '0 1rem'}}>{selectedTemplate?.source_systems.map(el =>
                    <span>{el}</span>)}</span>
            },
            {
                name: 'Target System',
                description: <>
                    <Select
                        sx={{
                            width: '70%',
                            boxShadow: 'none',
                            '.MuiOutlinedInput-notchedOutline': {border: 0},
                        }}
                        size='small'
                        value={targetSystems}
                        onChange={e => setTargetSystems(prev => e.target.value)}
                    >
                        {projectTargetSystems?.map(system => {
                            return <MenuItem
                                value={system.system_name}>{system.system_name} {system.system_version}</MenuItem>
                        })}

                    </Select>
                </>
            },
            {
                name: 'Load Method',
                description: <>
                    <Select
                        sx={{
                            width: '70%',
                            boxShadow: 'none',
                            '.MuiOutlinedInput-notchedOutline': {border: 0},
                        }}
                        size='small'
                        value={loadMethods}
                        onChange={e => setLoadMethods(prev => e.target.value)}
                    >
                        {projectLoadMethods?.map(method => {
                            return <MenuItem
                                value={method.lm_name}>{method.lm_name}</MenuItem>
                        })}

                    </Select>
                </>
            },
            {
                name: 'Sub-Template Objects',
                description: <span style={{padding: '0 1rem'}}>{selectedTemplate?.enable_sto ? 'Yes' : 'No'}</span>
            },
            {
                name: 'Data Harmonization',
                description: <>
                    <Select
                        sx={{
                            width: '70%',
                            boxShadow: 'none',
                            '.MuiOutlinedInput-notchedOutline': {border: 0},
                        }}
                        size='small'
                        value={isDataHarmonization}
                        onChange={e => setIsDataHarmonization(prev => e.target.value)}
                    >
                        {['Yes', 'No'].map(el => {
                            return <MenuItem
                                onClick={_ => {
                                    updateTO()
                                }}
                                value={el}>{el}</MenuItem>
                        })}

                    </Select>
                </>
            }
        ]


        const TemplateObjectHeader = () => {
            const [isError, setIsError] = useState(false);
            const [temporary, setTemporary] = useState(false);

            return (
                <div className='template-objects-header-container'>
                    <p className='template-objects-header-label'>Template Object:</p>
                    {
                        isEditing ?
                            <>
                                <TextField
                                    sx={{minWidth: '300px'}}
                                    required
                                    error={isError}
                                    defaultValue={selectedTemplate?.to_name}
                                    helperText={isError ? 'Duplicate TO name detected.' : ''}
                                    onChange={(event) => {
                                        const exists = dataObjects.some(dataObject => dataObject.template_objects.some(templateObject => templateObject.to_name === event.target.value));

                                        if (exists) {
                                            setIsError(true)
                                        } else {
                                            setIsError(false)
                                            setTemporary(event.target.value)
                                        }

                                    }}
                                ></TextField>

                                <Button
                                    disabled={isError}
                                    onClick={_ => {
                                        setSelectedTemplate(prev => ({...prev, to_name: temporary}))
                                        setIsEditing(false);
                                    }}
                                >
                                    Save
                                </Button>
                                <Button onClick={_ => {
                                    setIsEditing(false);
                                }} color='error'>Discard</Button>
                            </>
                            :
                            <p style={{whiteSpace: 'nowrap', cursor: 'pointer'}}
                               onClick={_ => setIsEditing(true)}>{selectedTemplate?.to_name}</p>
                    }
                </div>
            )
        }

        const UserAssignment = () => {
            const groups = userGroups.filter(el => el.is_value_stream === false)

            const newGroups = selectedTemplate?.user_groups_assigned.map(el => groups.filter(el2 => el?.ug_name === el2?.ug_name)[0])
            const newUsers = selectedTemplate?.users_assigned.map(el => businessStakeholderList.filter(el2 => el === el2?.username)[0])

            const [userValues, setUserValues] = useState([...newUsers, ...newGroups])

            const optionsList = [...groups, ...businessStakeholderList]

            return (
                <Autocomplete
                    sx={{marginTop: '1%'}}
                    multiple
                    value={userValues}
                    onChange={(e, newValue) => {
                        setUserValues(newValue)
                    }}
                    options={optionsList}
                    getOptionLabel={(option) => option?.username || option?.ug_name}
                    disableCloseOnSelect

                    renderOption={(props, option, {selected}) => {
                        const user = usersList[option?.username]

                        if (user) {
                            return (
                                <li {...props} >
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        padding: '0 2rem 0 1rem'
                                    }}>
                                        <CardContent className='manage-objects-user-container' sx={{width: '80%'}}>
                                            <Avatar sx={{
                                                width: 45,
                                                height: 45,
                                                fontSize: '0.8rem',
                                                bgcolor: normalize_color(stringToColor(user?.id ? user.first_name + ' ' + user.last_name : user?.username), 100)
                                            }}
                                                    aria-label="recipe" src={user.image_link}>
                                                {`${user.first_name[0]} ${user.last_name[0]}`}
                                            </Avatar>
                                            <Typography gutterBottom variant="h6" sx={{whiteSpace: 'nowrap'}}>
                                                {user?.id ? user.first_name + ' ' + user.last_name : ''}
                                            </Typography>
                                            <Box sx={{width: '30rem'}}>
                                                <Typography variant="body2" color="text.secondary">
                                                    {[user.first_name, user.last_name].join(' ')}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    {user?.username}
                                                </Typography>
                                            </Box>
                                        </CardContent>

                                        <div style={{width: '4rem'}}>
                                            {option?.username ? 'User' : 'User Group'}
                                        </div>
                                    </div>
                                </li>
                            )
                        } else {
                            return (
                                <li {...props}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        padding: '0 2rem 0 1rem'
                                    }}>
                                        <CardContent className='manage-objects-user-container' sx={{width: '80%'}}>
                                            <Avatar sx={{
                                                width: 45,
                                                height: 45,
                                                fontSize: '0.8rem',
                                                bgcolor: normalize_color(stringToColor(option?.ug_name), 100)
                                            }}
                                                    aria-label="recipe">
                                                {option?.ug_name[0].toUpperCase()}
                                            </Avatar>
                                            <Box sx={{width: '30rem', paddingLeft: '1rem'}}>
                                                <Typography variant="body2" color="text.secondary">
                                                    {option?.ug_name}
                                                </Typography>
                                            </Box>
                                        </CardContent>

                                        <div style={{width: '4rem', whiteSpace: 'nowrap'}}>
                                            {option?.username ? 'User' : 'User Group'}
                                        </div>
                                    </div>
                                </li>
                            )
                        }

                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"

                            label="User Assignments:"
                            placeholder="Search"
                        />
                    )}
                />

            )
        }

        const ValueStreams = () => {
            const [valueStreams, setValueStreams] = useState(userGroups?.filter(userGroup => selectedTemplate?.value_streams.some(valueStream => userGroup.recid === valueStream.vs_id)));

            const processOptions = () => {
                const filteredValues = userGroups?.filter(el => el.is_value_stream === true)
                const uniqueElements = new Map();
                const resultArray = [];

                [...filteredValues, ...selectedTemplate?.value_streams].forEach((item) => {
                    const key = item.recid || item.vs_id;

                    if (!uniqueElements.has(key)) {
                        uniqueElements.set(key, true);
                        resultArray.push(item);
                    }
                });
                return resultArray;
            }


            return (
                <Autocomplete
                    sx={{marginTop: '2%'}}
                    multiple
                    options={processOptions()}
                    getOptionLabel={(option) => option?.ug_name || option.vs_name}
                    disableCloseOnSelect
                    value={valueStreams}
                    onChange={(e, newValue) => {
                        setValueStreams(prev => newValue)
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            label="Value Streams(s):"
                            placeholder="Search"
                        />
                    )}
                />
            )
        }

        const TemplateTags = () => {
            const Root = styled('div')(
                ({theme}) => `
              color: ${
                    theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)'
                };
              font-size: 14px;
              
              &.focused {
                border: none;
                width: 1000px;
              }
            `,
            );

            const Label = styled('label')`
              color: rgba(0, 0, 0, 0.6);
              padding: 0 0 4px;
              line-height: 1.5;
              display: block;
            `;

            const InputWrapper = styled('div')(
                ({theme}) => `
              width: 100%;
              border-bottom: 1px solid ${theme.palette.mode === 'dark' ? '#434343' : '#d9d9d9'};
              background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
              border-radius: 4px;
              padding: 1px;
              display: flex;
              flex-wrap: wrap;
            
              &:hover {
                border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
              }
            
              &.focused {
                // border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
                // box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
              }
            
              & input {
                background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
                color: ${
                    theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)'
                };
                height: 30px;
                box-sizing: border-box;
                padding: 4px 6px;
                width: 0;
                min-width: 30px;
                flex-grow: 1;
                border: 0;
                margin: 0;
                outline: 0;
              }
            `,
            );

            function Tag(props) {
                const {label, onDelete, ...other} = props;
                return (
                    <div {...other}>
                        <span>{label}</span>
                        <CloseIcon onClick={onDelete}/>
                    </div>
                );
            }

            Tag.propTypes = {
                label: PropTypes.string.isRequired,
                onDelete: PropTypes.func.isRequired,
            };

            const StyledTag = styled(Tag)(
                ({color}) => `
                  display: flex;
                  align-items: center;
                  height: 24px;
                  margin: 2px;
                  line-height: 22px;
                  background-color: ${color};
                  border: 1px solid ${'#e8e8e8'};
                  border-radius: 20px;
                  box-sizing: content-box;
                  padding: 0 4px 0 10px;
                  outline: 0;
                  overflow: hidden;
                
                  &:focus {
                    border-color: ${'#40a9ff'};
                    background-color: ${'#e6f7ff'};
                  }
                
                  & span {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  }
                
                  & svg {
                    font-size: 12px;
                    cursor: pointer;
                    padding: 4px;
                  }
                `,
            );

            const Listbox = styled('ul')(
                ({theme}) => `
                  width: 300px;
                  margin: 2px 0 0;
                  padding: 0;
                  position: absolute;
                  list-style: none;
                  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
                  overflow: auto;
                  max-height: 250px;
                  border-radius: 4px;
                  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
                  z-index: 1;

                  & li {
                    padding: 5px 12px;
                    display: flex;

                    & span {
                      flex-grow: 1;
                    }

                    & svg {
                      color: transparent;
                    }
                  }

                  & li[aria-selected='true'] {
                    background-color: ${theme.palette.mode === 'dark' ? '#2b2b2b' : '#fafafa'};
                    font-weight: 600;

                    & svg {
                      color: #1890ff;
                    }
                  }

                  & li.${autocompleteClasses.focused} {
                    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
                    cursor: pointer;

                    & svg {
                      color: currentColor;
                    }
                  }
                `,
            )

            const CustomizedHook = () => {
                const [selectedOptions, setSelectedOptions] = useState(templateObjectTags.filter(item1 => selectedTemplate?.to_tags.some(item2 => item1.recid === item2.tag_id)));
                const [inputValue, setInputValue] = useState('');
                const [isListOpen, setIsListOpen] = useState(false);

                const {
                    getRootProps,
                    getInputLabelProps,
                    getInputProps,
                    getTagProps,
                    getListboxProps,
                    getOptionProps,
                    groupedOptions,
                    value,
                    focused,
                    setAnchorEl,
                } = useAutocomplete({
                    id: 'customized-hook-demo',
                    disableClearable: true,
                    multiple: true,
                    value: selectedOptions,
                    onChange: (event, newValue) => {
                        setSelectedOptions(newValue);
                        setInputValue('');
                    },
                    options: templateObjectTags,
                    getOptionLabel: (option) => option.title,
                    inputValue,
                    onInputChange: (_, newInputValue) => {
                        setInputValue(newInputValue);
                        setIsListOpen(true);
                    },
                });

                const isOptionSelected = (option) => {
                    return selectedOptions.some((selectedOption) => selectedOption.tag_name === option.tag_name);
                }

                return (
                    <div style={{width: '100%'}}>
                        <Root style={{width: '100%'}}>
                            <div {...getRootProps()} style={{width: '100%'}}>
                                <Label {...getInputLabelProps()}>Tags</Label>
                                <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''}>
                                    {value.map((option, index) => (
                                        <StyledTag color={option.tag_color}
                                                   label={option.tag_name} {...getTagProps({index})}/>
                                    ))}
                                    <input placeholder='Search' {...getInputProps()} onClick={() => {
                                        setIsListOpen(true)
                                    }}/>
                                </InputWrapper>
                            </div>
                            {isListOpen && groupedOptions.length > 0 ? (
                                <Listbox {...getListboxProps()}>
                                    {groupedOptions.map((option, index) => (
                                        <li {...getOptionProps({option, index})} onClick={() => {
                                            if (!isOptionSelected(option)) {
                                                const newSelectedOptions = [...selectedOptions, option];
                                                setSelectedOptions(prev => newSelectedOptions);
                                                setInputValue('');

                                            } else {
                                                setSelectedOptions(prev => prev.filter(el => el.tag_name !== option.tag_name))
                                            }

                                            setIsListOpen(true);
                                        }}>
                                            <span>{option.tag_name}</span>
                                            <CheckIcon fontSize="small"/>
                                        </li>
                                    ))}
                                </Listbox>
                            ) : null}
                        </Root>
                    </div>

                );
            }

            return <CustomizedHook/>
        }

        const DODescription = () => {
            const [description, setDescription] = useState(selectedDataObject?.do_description)

            const updateDescription = () => {
                const data = {
                    data_object_metadata: {
                      "recid": selectedDataObject.data_object_id,
                      "do_name": selectedDataObject.do_name,
                      "description": description
                    }
                }

                updateDODescription(projectId, data , localStorage.getItem('token'))
                    .then(res => console.log(res))
                    .catch(err => console.log(err))

            }

            return (
                <div className='manage-objects-description'>
                    <TextField className='data-object-description-field'
                               id="standard-basic"
                               defaultValue={description}
                               onChange={e => setDescription(e.target.value)}
                               label="Description"
                               variant="standard"/>
                    {description !== selectedDataObject?.do_description &&
                        <>
                        <Button onClick={updateDescription}>Save</Button>
                        <Button color='error' onClick={_ => setDescription(selectedDataObject?.do_description)}>Discard</Button>
                        </>
                        }

                </div>
            )
        }

        return (
            <div className='manage-objects-main-window'>
                <div className='manage-objects-main-window-header'>
                    <p className='manage-objects-header'>Data Object:</p>
                    <p>{selectedDataObject ? selectedDataObject.do_name : "Not Selected"}</p>
                    <Tooltip
                        title={<h2>Review each data object and template object's metadata in the Object-level Management
                            View (OMV) below. You can manage the source extract files and tables, load files and tables,
                            template structure, user and user group assignments, and other metadata pertaining to a
                            specific template object on this page. You can also configure the lookup values for the
                            current migration cycle of the project here.</h2>}>
                        <ErrorIcon
                            sx={{color: '#707070', fontSize: 35, marginTop: '0.3rem'}}
                        />
                    </Tooltip>
                </div>

                <DODescription/>

                {
                    selectedTemplate && selectedDataObject &&
                    <div className='template-object-section'>
                        <div className='template-objects-header-section'>
                            <TemplateObjectHeader/>

                            <div className='template-object-header-btn-section'>
                                <Button
                                    onClick={_ => setViewChangeLogs(true)}
                                    className='template-object-headers-btn'
                                    variant="contained">View Change Log
                                </Button>
                                <Button
                                    onClick={_ => setShowFollowUps(true)}
                                    className='template-object-headers-btn'
                                    variant="contained">Follow-ups
                                </Button>
                                <Button
                                    onClick={_ => setShowNotes(true)}
                                    className='template-object-headers-btn'
                                    variant="contained">Notes
                                </Button>
                            </div>

                        </div>

                        <div className='template-object-data-container'>
                            <div className='template-object-input-container'>

                                <TemplateTags/>


                                <ValueStreams/>


                                {
                                    selectedTemplate?.business_stakeholder &&
                                    <FormControl sx={{marginTop: '0.5rem'}} fullWidth variant="standard">
                                        <InputLabel sx={{marginLeft: '1px'}}>Business Stakeholder for
                                            Approval:</InputLabel>
                                        <Select
                                            value={businessStakeholder || selectedTemplate?.business_stakeholder}
                                            variant='standard'
                                            onChange={event => setBusinessStakeholder(event.target.value)}
                                        >
                                            {businessStakeholderList?.map(el => {
                                                const user = usersList[el.username]

                                                return (
                                                    <MenuItem value={el.username}>
                                                        <CardContent className='manage-objects-user-container'>
                                                            <Avatar sx={{
                                                                width: 45,
                                                                height: 45,
                                                                fontSize: '0.8rem',
                                                                bgcolor: normalize_color(stringToColor(user?.id ? user.first_name + ' ' + user.last_name : user.username), 100)
                                                            }}
                                                                    aria-label="recipe" src={user.image_link}>
                                                                {user.first_name ? `${user.first_name[0]} ${user.last_name[0]}` : user.username[0].toUpperCase()}
                                                            </Avatar>
                                                            <Typography gutterBottom variant="h6"
                                                                        sx={{whiteSpace: 'nowrap'}}>
                                                                {user?.id ? user.first_name + ' ' + user.last_name : ''}
                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary">
                                                                {[user.first_name, user.last_name].join(' ')}
                                                            </Typography>
                                                        </CardContent>
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                }

                                <UserAssignment/>

                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1rem'}}>
                                    <TextField
                                        fullWidth
                                        sx={{marginTop: '1%', width: '100%'}}
                                        value={link}
                                        onChange={e => setLink(prev => e.target.value)}
                                        variant="standard"
                                        label="ATOM Transformation Notebook Link:"
                                        placeholder="link"
                                    />
                                    <>
                                        <LinkIcon sx={{cursor: 'pointer', paddingTop: '1rem'}} onClick={_ => {
                                            navigator.clipboard.writeText(link)
                                        }}/>
                                        <IosShareIcon sx={{cursor: 'pointer', paddingTop: '1rem'}} onClick={_ => {
                                            window.open(`${link}?token=${localStorage.getItem('token')}`)
                                        }}/>
                                    </>

                                </div>


                            </div>

                            <div className='template-object-table-container'>
                                <TableContainer component={Paper}>
                                    <Table sx={{minWidth: 650}} aria-label="simple table">
                                        <TableBody>
                                            {rows.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                                >
                                                    <TableCell component="th" scope="row"
                                                               sx={{
                                                                   width: '25%',
                                                                   backgroundColor: '#757272',
                                                                   color: 'white',
                                                                   height: '2rem',
                                                                   padding: '1rem',
                                                                   margin: '0rem'
                                                               }}>
                                                        {row.name}:
                                                    </TableCell>
                                                    <TableCell sx={{width: '60%', padding: '0rem', margin: '0rem'}}
                                                               align="left">{row.description}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </div>
                        </div>

                        <div className='template-object-btns-section'>
                            <div className='template-object-btns-container'>
                                <p className='template-object-btn-label'>Extract</p>
                                <Button
                                    onClick={_ => setUploadSourceDataModal(true)}
                                    className='template-object-btn'
                                    variant="contained">Upload Source Data
                                </Button>
                                <Button
                                    onClick={_ => setDisplaySourceDataModal(true)}
                                    className='template-object-btn'
                                    variant="contained">Display Source Data</Button>
                            </div>
                            <div className='template-object-btns-container'>
                                <p className='template-object-btn-label'>Transform</p>
                                <Button className='template-object-btn' variant="contained">Manage Mapping</Button>
                                <Button
                                    onClick={_ => setManageLookupsModal(true)}
                                    className='template-object-btn'
                                    variant="contained">Manage LOOKUPS
                                </Button>
                                <Button className='template-object-btn' variant="contained">Display transformation
                                    Summary</Button>
                            </div>
                            <div className='template-object-btns-container'>
                                <p className='template-object-btn-label'>Load</p>
                                <Button
                                    className='template-object-btn'
                                    onClick={_ => setChangeTemplateModal(true)}
                                    variant="contained">Change target structure
                                </Button>
                                <Button
                                    className='template-object-btn'
                                    onClick={_ => setDisplayLoadDatasetsModalOpen(true)}
                                    variant="contained">Display Load Datasets
                                </Button>
                            </div>
                        </div>

                        <MainModal
                            modalOpen={manageLookupsModal}
                            setModalOpen={setManageLookupsModal}
                            headerText='Manage Lookups'
                            tooltip={true}
                            tooltipLbl='Use this component to create new lookup (cross-references) tables that can later be referenced in the SMT Mapping View to automate old-to-new value conversions for specific fields in a target system.'
                            Child={ManageLookups}/>

                        <MainModal
                            modalOpen={displaySourceDataModal}
                            setModalOpen={setDisplaySourceDataModal}
                            headerText='Display Source Data'
                            tooltip={true}
                            Child={DisplaySourceDataModal}/>

                        <MainModal
                            modalOpen={uploadSourceDataModal}
                            setModalOpen={setUploadSourceDataModal}
                            headerText='Upload Source Data'
                            tooltip={true}
                            Child={UploadSourceDataModal}/>

                        <MainModal
                            tooltip={true}
                            modalOpen={changeTemplateModalOpen}
                            setModalOpen={setChangeTemplateModal}
                            headerText='Change Load Template'
                            Child={ChangeLoadTemplateModal}/>

                        <MainModal
                            tooltip={true}
                            modalOpen={displayLoadDatasetsModalOpen}
                            setModalOpen={setDisplayLoadDatasetsModalOpen}
                            headerText='Display Load Datasets'
                            Child={DisplayLoadDatasetsModal}/>

                        <MainModal
                            tooltip={true}
                            modalOpen={showNotes}
                            setModalOpen={setShowNotes}
                            headerText='Manage Notes'
                            Child={ManageNotesModal}/>

                        <MainModal
                            tooltip={true}
                            modalOpen={viewChangeLogs}
                            setModalOpen={setViewChangeLogs}
                            headerText='View Change Log'
                            Child={ViewChangeLogModal}
                            selectedTemplate={selectedTemplate}
                        />

                        <MainModal
                            tooltip={true}
                            modalOpen={showFollowUps}
                            setModalOpen={setShowFollowUps}
                            headerText='Manage Follow Ups'
                            Child={ManageFollowUpsModal}
                        />

                    </div>


                }

            </div>
        )
    }

    return (
        <div className='manage-data-objects'>
            <Header
                activePage={'Manage Objects'}
                projectId={projectId}
                setMcId={setMcId}
                setSelectedDataObject={setSelectedDataObject}
                setSelectedTemplate={setSelectedTemplate}
            />
            <div style={{display: "flex", flexDirection: 'row'}}>
                <Sidebar/>
                <MainWindow/>
            </div>
        </div>
    )
}

export default ManageObjects;