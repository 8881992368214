import './SelectSystem.css';
import {Button} from '@mui/material';
import {useState} from "react";
import SystemAccordion from "./SystemAccordion";


const SelectSystem = ({
                          handleComplete,
                          handleBack,
                          data,
                          systems,
                          setData,
                          setHelpModalOpen
                      }) => {
    const [selectedTargetSystem, setSelectedTargetSystem] = useState(data.systemData?.selectedTargetSystem ? data.systemData.selectedTargetSystem : [])
    const [selectedSourceSystem, setSelectedSourceSystem] = useState(data.systemData?.selectedSourceSystem ? data.systemData.selectedSourceSystem : [])
    const [error, setError] = useState(false);

    return (
        <>
            <SystemAccordion selectedSystems={selectedSourceSystem} setSelectedSystems={setSelectedSourceSystem}
                             systems={systems} header={'Select Source System(s)'}
                             error={error && selectedSourceSystem.length === 0}/>
            <hr/>
            <SystemAccordion selectedSystems={selectedTargetSystem} setSelectedSystems={setSelectedTargetSystem}
                             systems={systems} header={'Select Target System(s)'}
                             error={error && selectedTargetSystem.length === 0}/>

            <div className='main-page-btns'>
                <Button className='help-button' onClick={_ => setHelpModalOpen(true)}>help</Button>
                <Button className='main-page-btn close-button' onClick={handleBack}>Back</Button>
                <Button className='main-page-btn complete-button' onClick={_ => {
                    if (selectedTargetSystem.length === 0) {
                        setError(true);
                    } else {
                        setError(false)
                        setData({
                            ...data,
                            systemData: {selectedTargetSystem, selectedSourceSystem}
                        })
                        handleComplete()
                    }
                }}>Next</Button>
            </div>
        </>
    );
}

export default SelectSystem;
