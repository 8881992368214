import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import TOTabs from "./TOTabs";
import {useState} from "react";
import FollowUpAccordion from "./FollowUpAccordion";

const TOAccordion = ({object, userData, allUserData, loadMethodFilter, objectTypeFilter, systemFilter}) => {
    const [expanded, setExpanded] = useState(false)
    return <Accordion expanded={expanded} onChange={_ => setExpanded(!expanded)}
                      TransitionProps={{unmountOnExit: true}}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <div style={{display: 'flex', width: '80%', alignItems: 'center'}}>
                <span style={{flex: 2}}>{object.do_name}</span>
                <span style={{flex: 1}}>{object.do_mapping_progress_percentage} mapped</span>
                <span
                    style={{flex: 1}}>{object.do_total_follow_up_count} total follow-ups</span>
                <span style={{flex: 1}}>{object.do_value_streams.join(', ')}</span>
            </div>
        </AccordionSummary>
        <AccordionDetails>
            <FollowUpAccordion follow_up_data={object.do_follow_up_data} userData={userData}
                               allUserData={allUserData} count={object.do_follow_up_count}
                               summary={`${object.do_name} Follow-Ups (${object.do_follow_up_count})`}/>
            <TOTabs to_data={object.to_data} allUserData={allUserData} userData={userData}
                    loadMethodFilter={loadMethodFilter} objectTypeFilter={objectTypeFilter}
                    systemFilter={systemFilter}/>
        </AccordionDetails>
    </Accordion>
}

export default TOAccordion