import React, {createContext, useContext, useEffect, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import {authLanding, validateToken} from "./api/requests";
import {ATOM_UI, SMT_API} from "./api/endpoints";

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({children}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams()
    const token = searchParams.get('token')


    useEffect(() => {
        const checkAuthentication = async () => {
            const jwt = token ? token : localStorage.getItem('token')

            if (jwt) {
                await validateToken(jwt).then(r => {
                    localStorage.setItem('token', jwt)
                    setIsAuthenticated(true);
                    return r
                })
                .catch(_ => window.location.replace(`${ATOM_UI}/auth?redirect_url=${window.location.href}`))
                if (searchParams.has('token')) {
                    searchParams.delete('token')
                    setSearchParams(searchParams)
                }

                await authLanding(jwt)
                    .catch(_ => window.location.replace(ATOM_UI))

            } else {
                setIsAuthenticated(false);
                window.location.replace(`${ATOM_UI}/auth?redirect_url=${window.location.href}`)
            }

            setIsAuthenticated(!!token);
        };
        checkAuthentication()
    }, []);


    return (
        <AuthContext.Provider value={{isAuthenticated}}>
            {children}
        </AuthContext.Provider>
    );
};
