import {Button, CircularProgress} from "@mui/material";
import ArchiveIcon from '@mui/icons-material/Archive';
import {
    DataGrid,
    GridToolbarColumnsButton,
    GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton,
    useGridApiContext
} from "@mui/x-data-grid";
import {useEffect, useState} from "react";
import {Unarchive} from "@mui/icons-material";
import {archiveObjects, getArchivedObjects, unarchiveObjects} from "../../../api/requests";
import LoadingButton from "@mui/lab/LoadingButton";

const ArchiveObjects = ({projectId, mcId, getObjects}) => {
    const [activeProjects, setActiveProjects] = useState([])
    const [archivedProjects, setArchivedProjects] = useState([])
    const [loadingTables, setLoadingTables] = useState(false)
    const [loadingArchive, setLoadingArchive] = useState(false)
    const [loadingUnarchive, setLoadingUnarchive] = useState(false)

    const updateTables = () => {
        setLoadingTables(true)
        getArchivedObjects(projectId, mcId, localStorage.getItem('token')).then(r => {
            setArchivedProjects(r.data.archived.map((el, i) => {
                return {id: i, ...el}
            }))
            setActiveProjects(r.data.unarchived.map((el, i) => {
                return {id: i, ...el}
            }))
            setLoadingTables(false)
        })
    }

    useEffect(() => {
        updateTables()
    }, []);

    const columns = [
        {field: 'to_name', headerName: 'Template Object Name', minWidth: 200, flex: 1},
        {field: 'do_name', headerName: 'Data Object Name', minWidth: 200, flex: 1},
    ]
    const TOColumns = [
        {field: 'to_name', headerName: 'Template Object Name', minWidth: 200, flex: 1},
        {field: 'do_name', headerName: 'Data Object Name', minWidth: 200, flex: 1}
    ]

    const CustomArchiveToolbar = (props) => {
        const apiRef = useGridApiContext();

        return (
            <GridToolbarContainer {...props}>
                <GridToolbarColumnsButton/>
                <GridToolbarFilterButton/>
                <GridToolbarDensitySelector/>
                <LoadingButton loading={loadingArchive} startIcon={<ArchiveIcon/>} onClick={_ => {
                    setLoadingArchive(true)
                    const t = activeProjects.filter(el => apiRef.current.getSelectedRows().has(el.id)).map(el => el.template_object_id)
                    archiveObjects(projectId, t, localStorage.getItem('token')).then(_ => {
                        updateTables()
                        getObjects()
                        setLoadingArchive(false)
                    }).catch(_ => setLoadingArchive(false))
                    apiRef.current.setRowSelectionModel([])
                }}>
                    <span>Archive</span>
                </LoadingButton>
            </GridToolbarContainer>)
    }

    const CustomUnarchiveToolbar = (props) => {
        const apiRef = useGridApiContext();

        return (
            <GridToolbarContainer {...props}>
                <GridToolbarColumnsButton/>
                <GridToolbarFilterButton/>
                <GridToolbarDensitySelector/>
                <LoadingButton loading={loadingUnarchive} startIcon={<Unarchive/>} onClick={_ => {
                    setLoadingUnarchive(true)
                    const t = archivedProjects.filter(el => apiRef.current.getSelectedRows().has(el.id)).map(el => el.template_object_id)
                    unarchiveObjects(projectId, t, localStorage.getItem('token')).then(_ => {
                        updateTables()
                        getObjects()
                        setLoadingUnarchive(false)
                    }).catch(_ => setLoadingUnarchive(false))
                    apiRef.current.setRowSelectionModel([])
                }}>
                    <span>Unarchive</span>
                </LoadingButton>
            </GridToolbarContainer>)
    }
    return (
        <>
            <div className='mass-assign-body'>
                <div className='mass-assign-half'>
                    <h1>Archive Template Objects:</h1>
                    <h5>Select Template Objets from the table below to archive</h5>
                    {loadingTables ?
                        <div style={{display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
                            <CircularProgress size={'3rem'}/></div> :
                        <DataGrid columns={TOColumns} rows={activeProjects} hideFooter sx={{height: '80%', border: 0}}
                                  slots={{toolbar: CustomArchiveToolbar}} checkboxSelection/>}
                </div>
                <hr/>
                <div className='mass-assign-half'>
                    <h1>Restore Template Objects:</h1>
                    <h5>Select archived objects from the table to restore</h5>
                    {loadingTables ?
                        <div style={{display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
                            <CircularProgress size={'3rem'}/></div> :
                        <DataGrid columns={columns} rows={archivedProjects} slots={{toolbar: CustomUnarchiveToolbar}}
                                  hideFooter sx={{height: '80%', border: 0}} checkboxSelection/>}
                </div>
            </div>
        </>
    )
}

export default ArchiveObjects