import logo from "./assets/logo.ico";
import './Header.css'
import {AppBar, Box, Button, Container, IconButton, Menu, MenuItem, Select, Toolbar} from "@mui/material";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {AccountCircle, Help} from "@mui/icons-material";
import MenuIcon from '@mui/icons-material/Menu'
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {authProjectSettings, getMetadata, getMigrationCycles, getUserData} from "../../api/requests";
import HelpModal from "../HelpModal/HelpModal";
import MainModal from "../Modal/MainModal";

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#1976d2',
        },
    },
});


const Header = ({
                    activePage,
                    setUserData = null,
                    projectId = null,
                    setMcId = null,
                    setMc = null,
                    setSelectedDataObject = null,
                    setSelectedTemplate = null
                }) => {
    const [userAvatar, setUserAvatar] = useState(null)
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [project, setProject] = useState('');
    const [migrationCycles, setMigrationCycles] = useState(null);
    const [selectedMigrationCycle, setSelectedMigrationCycle] = useState(null);
    const [modalOpen, setModalOpen] = useState(false)
    const [showSettings, setShowSettings] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const token = localStorage.getItem('token')

        if (projectId) {
            const metadata = async () => {
                return getMetadata(projectId, token).then(res => res).catch(err => err)
            }

            metadata().then(res => {
                setProject(res.data.metadata[0])
            })

            const migrationCycles = async () => {
                return getMigrationCycles(projectId, token).then(res => res).catch(err => err)
            }

            migrationCycles().then(res => {
                setMigrationCycles(res.data.migration_cycles)
            })
        }
        getUserData(token).then(user => {
            if (setUserData) {
                setUserData(user.data)
            }
            setUserAvatar(user.data.image_link)

            console.log(user.data.image_link)
        })
        if (projectId)
            authProjectSettings(projectId, token).then(_ => setShowSettings(true)).catch(_ => setShowSettings(false))
    }, [])

    const extractMcId = () => {
        const mcIdParam = searchParams.get('mc_id')

        if (mcIdParam) {
            const obj = migrationCycles?.find(obj => obj.recid === +mcIdParam)
            setSelectedMigrationCycle(obj ? obj.mc_name : '')
            // eslint-disable-next-line no-unused-expressions
            !!setMcId ? setMcId(obj ? obj.recid : '') : null
            // eslint-disable-next-line no-unused-expressions
            !!setMc ? setMc(obj ? obj : null) : null
        } else {
            const obj = migrationCycles?.find(obj => obj.is_active === true);
            setSelectedMigrationCycle(obj ? obj.mc_name : '')
            // eslint-disable-next-line no-unused-expressions
            !!setMcId ? setMcId(obj ? obj.recid : '') : null
            // eslint-disable-next-line no-unused-expressions
            !!setMc ? setMc(obj ? obj : null) : null
        }
    }

    useEffect(() => {
        extractMcId()
    }, [migrationCycles])


    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const ImgExists = (url) => {
        const img = new Image();
        img.src = url;
        return img.height != 0;
    }

    const pages = [
        {name: 'Project Home', link: ''},
        {name: 'View Data Scope', link: 'dataScope'},
        {name: 'Manage Objects', link: 'object/manage'},
        {name: 'View Mapping', link: 'mapping/view'},
        {name: 'View Files', link: ''},
        {name: 'PMO Reports', link: ''},
        {name: 'Project Settings', link: 'settings'}]
    const navigate = useNavigate()

    return (
        <>
            <MainModal headerText={'Support'} Child={HelpModal} setModalOpen={setModalOpen} modalOpen={modalOpen}/>
            <ThemeProvider theme={darkTheme}>
                <AppBar position='static'>
                    <Container maxWidth='xxl'>
                        <Toolbar disableGutters>
                            {projectId &&
                                <Box sx={{flexGrow: 0, display: {xs: 'flex', md: 'none'}}}>
                                    <IconButton
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleOpenNavMenu}
                                        color="inherit"
                                    >
                                        <MenuIcon/>
                                    </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorElNav}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        open={Boolean(anchorElNav)}
                                        onClose={handleCloseNavMenu}
                                        sx={{
                                            display: {xs: 'block', md: 'none'},
                                        }}
                                    >
                                        {pages.map((page) => {
                                                if (page.link !== 'settings' || showSettings) {
                                                    return <MenuItem key={page.name} onClick={_ => {
                                                        handleCloseNavMenu()
                                                        navigate(searchParams.has('mc_id') ? `/project/${projectId}/${page.link}?mc_id=${searchParams.get('mc_id')}` : `/project/${projectId}/${page.link}`)
                                                    }}>
                                                        <Typography textAlign="center">{page.name}</Typography>
                                                    </MenuItem>
                                                }
                                            }
                                        )}
                                    </Menu>
                                </Box>
                            }
                            <IconButton onClick={_ => navigate('/')}>
                                <img className='landing-page-logo' src={logo} alt='logo' loading='lazy'/>
                            </IconButton>
                            <Box sx={{flexGrow: 1, display: 'flex'}}>
                                {projectId &&

                                    <>
                                        <Box sx={{flexGrow: 0, mr: 1, display: 'flex', gap: .5}}>
                                                <span style={{
                                                    fontSize: '1.5rem',
                                                    fontWeight: 'bold',
                                                    padding: 0,
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}>{project?.project_name}</span>
                                        </Box>

                                        {migrationCycles &&
                                            <Box sx={{flexGrow: 0, mr: 1, display: 'flex', gap: .5}}>
                                                {activePage !== 'Project Settings' &&
                                                    <>
                                                        <hr/>
                                                        <Select
                                                            disableUnderline
                                                            variant='standard'
                                                            value={selectedMigrationCycle}
                                                            sx={{
                                                                fontSize: '1.5rem',
                                                                fontWeight: 'bold',
                                                                padding: 0,
                                                                color: '#fff',
                                                                '.MuiSvgIcon-root ': {
                                                                    fill: "white !important",
                                                                },
                                                                '.MuiInput-input': {
                                                                    padding: "0 24px 0 0  !important",
                                                                }
                                                            }}
                                                        >
                                                            {
                                                                migrationCycles.map(el =>
                                                                    <MenuItem onClick={_ => {
                                                                        setSelectedMigrationCycle(el.mc_name)
                                                                        setSelectedTemplate && setSelectedTemplate(prev => null)
                                                                        setSelectedDataObject && setSelectedDataObject(prev => null)
                                                                        if (!!setMcId) {
                                                                            setMcId(el.recid)
                                                                            searchParams.set('mc_id', el.recid)
                                                                            setSearchParams(searchParams)
                                                                        }
                                                                        if (!!setMc) {
                                                                            setMc(el)
                                                                            searchParams.set('mc_id', el.recid)
                                                                            setSearchParams(searchParams)

                                                                        }
                                                                    }}
                                                                              value={el.mc_name}>{el.mc_name}
                                                                    </MenuItem>)
                                                            }
                                                        </Select>
                                                    </>
                                                }
                                            </Box>
                                        }

                                        <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}/>
                                        <Box
                                            sx={{
                                                flexGrow: 1,
                                                display: {xs: 'none', md: 'flex'},
                                                justifyContent: 'center'
                                            }}>
                                            {pages.map((page) => {
                                                    if (page.link !== 'settings' || showSettings) {
                                                        return <Button
                                                            key={page.name}
                                                            onClick={_ => {
                                                                handleCloseNavMenu()
                                                                navigate(searchParams.has('mc_id') ? `/project/${projectId}/${page.link}?mc_id=${searchParams.get('mc_id')}` : `/project/${projectId}/${page.link}`)
                                                            }}
                                                            sx={{my: 2, color: 'white', display: 'block'}}
                                                        >
                                                            {page.name}
                                                        </Button>
                                                    }
                                                }
                                            )}
                                        </Box>
                                    </>
                                }
                            </Box>

                            <Box sx={{flexGrow: 0, display: 'flex', alignItems: 'center'}}>
                                <IconButton onClick={_ => setModalOpen(true)}>
                                    <Help sx={{fontSize: 50, color: '#ccc'}}/>
                                </IconButton>
                                <hr style={{height: '50px'}}/>
                                <IconButton onClick={handleOpenUserMenu}>
                                    {userAvatar && ImgExists(userAvatar) ?
                                        <Avatar src={userAvatar} sx={{width: 45, height: 45}}/> :
                                        <AccountCircle sx={{fontSize: 50, color: '#ccc'}}/>
                                    }
                                </IconButton>
                                <Menu
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    <MenuItem onClick={handleCloseUserMenu}>Logout</MenuItem>
                                </Menu>
                            </Box>
                        </Toolbar>
                    </Container>
                </AppBar>
            </ThemeProvider>
        </>
    )
}

export default Header