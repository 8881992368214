export const stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
    return color;
}

export const normalize_color = (hexColor, brightness) => {
    // Convert hex color to RGB values
    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5, 7), 16);

    // Changing brightness of color
    const proportions = [g / r, b / r];
    const new_r = brightness * r / (0.299 * r + 0.587 * g + 0.114 * b);
    const new_color = [new_r, proportions[0] * new_r, proportions[1] * new_r];

    // Limitation to save rgb in [0, 255]
    if (new_color[0] > 255) {
        new_color[0] = 255;
    }
    if (new_color[1] > 255) {
        new_color[1] = 255;
    }
    if (new_color[2] > 255) {
        new_color[2] = 255;
    }

    // Convert RGB values back to hex color
    const normalizedHexColor = '#' + ('00' + Math.round(new_color[0]).toString(16)).slice(-2) +
        ('00' + Math.round(new_color[1]).toString(16)).slice(-2) +
        ('00' + Math.round(new_color[2]).toString(16)).slice(-2);

    return normalizedHexColor;
}