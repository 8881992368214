import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import Header from "../../helpers/header/Header";
import {useEffect, useState} from "react";
import './HomePage.css'
import {
    Alert, Autocomplete,
    Button,
    Chip, CircularProgress,
    FormControlLabel, FormLabel, IconButton,
    InputAdornment,
    MenuItem, Popover, Radio, RadioGroup,
    Select,
    Tab,
    Tabs,
    Tooltip
} from "@mui/material";
import {TextField} from "@mui/material";
import MainModal from "../../helpers/Modal/MainModal";
import AddDOPage from "./AddObjectsModal/AddDOPage";
import AddTemplates from "./AddObjectsModal/AddTemplates";
import AssignTemplates from "./AddObjectsModal/AssignTemplates";
import SearchIcon from "@mui/icons-material/Search";
import {Switch} from "@mui/material";
import FilterListIcon from '@mui/icons-material/FilterList';
import FormControl from "@mui/material/FormControl";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AssignTags from "./AssignTagsModal/AssignTags";
import ArchiveObjects from "./ArchiveObjects/ArchiveObjects";
import {authProject, getAllUserData, getAssignedObjects, getUnassignedObjects, getUserData} from "../../api/requests";
import TOAccordion from "./HomePageComponents/TOAccordion";
import {ArrowDownward, ArrowUpward, Sort} from "@mui/icons-material";


const HomePage = () => {
    const {projectId} = useParams();
    const navigate = useNavigate()
    const [input, setInput] = useState('');
    const [modalOpen, setModalOpen] = useState(false)
    const [tagsModalOpen, setTagsModalOpen] = useState(false);
    const [archiveModalOpen, setArchiveModalOpen] = useState(false);
    const [mc, setMc] = useState()
    const [unassignedObjects, setUnassignedObjects] = useState([])
    const [assignedObjects, setAssignedObjects] = useState([])
    const [userAssignedObjects, setUserAssignedObjects] = useState([])
    const [userData, setUserData] = useState()
    const [allUserData, setAllUserData] = useState()
    const [userOnlyAssignments, setUserOnlyAssignments] = useState(false)
    const [loading, setLoading] = useState(true)
    const [DOValue, setDOValue] = useState(0)
    const [searchParams, setSearchParams] = useSearchParams()
    const [sortOpen, setSortOpen] = useState(false)
    const [sortAnchor, setSortAnchor] = useState(null)
    const [sortValue, setSortValue] = useState('none')
    const [sortAsc, setSortAsc] = useState(true)
    const [filterOpen, setFilterOpen] = useState(false)
    const [filterAnchor, setFilterAnchor] = useState(null)
    const [toFilterData, setToFilterData] = useState({})
    const [toSystemFilter, setToSystemFilter] = useState(null)
    const [toLoadMethodFilter, setToLoadMethodFilter] = useState(null)
    const [toObjectTypeFilter, setToObjectTypeFilter] = useState(null)


    const creationAlert = searchParams.get('creationAlert')

    const getObjects = () => {
        const token = localStorage.getItem('token')
        if (mc) {
            const toFilterData = {targetSystem: [], objectType: [], loadMethod: []}
            getUnassignedObjects(projectId, mc.recid, token).then(r => setUnassignedObjects(r.data.objects))
            getAssignedObjects(projectId, mc.recid, token).then(r => {
                setAssignedObjects(r.data.non_user_objects)
                setUserAssignedObjects(r.data.objects)
                r.data.non_user_objects.forEach(el => {
                    el.to_data.forEach(to => {
                        to.target_system && toFilterData.targetSystem.push(to.target_system)
                        to.load_method_name && toFilterData.loadMethod.push(to.load_method_name)
                        to.template_object_type && toFilterData.objectType.push(to.template_object_type)
                    })
                })
                toFilterData.targetSystem = [...new Set(toFilterData.targetSystem)]
                toFilterData.objectType = [...new Set(toFilterData.objectType)]
                toFilterData.loadMethod = [...new Set(toFilterData.loadMethod)]

                setToFilterData(toFilterData)

                setLoading(false)
            })
        }
    }


    useEffect(() => {
        const token = localStorage.getItem('token')
        authProject(projectId, token).catch(_ => navigate('/'))
        getAllUserData(token).then(data => setAllUserData(data.data.users))
    }, []);

    useEffect(() => {
        setLoading(true)
        getObjects()
    }, [mc]);

    const AddDOModal = ({getObjects, warned, setWarned, setWarningOpen}) => {
        return (
            <>
                <Tabs value={DOValue} onChange={(_, newValue) => {
                    if (!warned) {
                        setWarningOpen(true)
                        setWarned(true)
                    } else {
                        setWarned(false)
                        setDOValue(newValue)
                    }
                }}>
                    <Tab label='Add Data Objects'/>
                    <Tab label='Add Templates'/>
                    <Tab label='Assign Templates to Data Objects'/>
                </Tabs>
                {DOValue === 0 && <AddDOPage projectId={projectId} mcId={mc?.recid} getObjects={getObjects} assignedObjects={assignedObjects.map(el => el.do_name)}/>}
                {DOValue === 1 && <AddTemplates project_id={projectId}/>}
                {DOValue === 2 && <AssignTemplates projectId={projectId} mcId={mc?.recid} getObjects={getObjects}/>}
            </>

        )
    }

    return (
        <>
            <Header activePage={'Project Home'} projectId={projectId} setMc={setMc} setUserData={setUserData}/>
            {loading ? <CircularProgress size={'10rem'}
                                         sx={{position: 'absolute', top: '40vh', left: 'calc((100vw - 10rem) / 2)'}}/> :
                <>
                    <MainModal modalOpen={modalOpen} setModalOpen={setModalOpen} headerText='Add Objects & Templates'
                               Child={AddDOModal} needsWarning getObjects={getObjects}/>
                    <MainModal modalOpen={tagsModalOpen} setModalOpen={setTagsModalOpen} headerText='Mass Assign Tags'
                               Child={AssignTags} projectId={projectId} mcId={mc.recid} getObjects={getObjects}
                               needsWarning/>
                    <MainModal modalOpen={archiveModalOpen} setModalOpen={setArchiveModalOpen}
                               headerText='Archive / Restore Objects' Child={ArchiveObjects} projectId={projectId}
                               mcId={mc.recid} getObjects={getObjects}/>
                    <div className='project-page-home'>
                        <div className='data-object-summary-block'>

                            <div className='block-header'>
                                <p>
                                    <span style={{fontWeight: 'bold'}}>Data Object Summary</span>
                                </p>
                            </div>

                            <div className='data-object-options'>
                                <TextField placeholder='Search' variant="standard"
                                           onChange={event => setInput(event.target.value)}
                                           InputProps={{
                                               endAdornment: (
                                                   <InputAdornment position='end'><SearchIcon/></InputAdornment>)
                                           }}
                                           id="outlined-basic"/>
                                <FormControlLabel control={<Switch checked={userOnlyAssignments}
                                                                   onChange={_ => setUserOnlyAssignments(!userOnlyAssignments)}/>}
                                                  label='Display My Assigments Only'/>

                                <IconButton onClick={e => {
                                    setFilterAnchor(e.currentTarget)
                                    setFilterOpen(true)
                                }}>
                                    <FilterListIcon/>
                                </IconButton>
                                <Popover open={filterOpen}
                                         anchorEl={filterAnchor}
                                         anchorOrigin={{
                                             vertical: 'bottom',
                                             horizontal: 'left',
                                         }}
                                         onClose={_ => {
                                             setFilterAnchor(null)
                                             setFilterOpen(false)
                                         }}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '.5rem',
                                        padding: 15,
                                        width: '20vw'
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            alignItems: 'center',
                                            justifyContent: 'space-between'
                                        }}>
                                            <span style={{flex: 1}}>Target System</span>
                                            <Autocomplete
                                                sx={{flex: 2}}
                                                options={toFilterData.targetSystem.map(el => {
                                                    return {label: el}
                                                })}
                                                renderInput={(params) => <TextField {...params}
                                                                                    variant='standard'/>}
                                                value={toSystemFilter || null}
                                                onChange={(event, value) => setToSystemFilter(value)}/>

                                        </div>
                                        <div style={{
                                            display: "flex",
                                            alignItems: 'center',
                                            justifyContent: 'space-between'
                                        }}>
                                            <span style={{flex: 1}}>Object Type</span>
                                            <Autocomplete
                                                sx={{flex: 2}}
                                                options={toFilterData.objectType.map(el => {
                                                    return {label: el}
                                                })}
                                                renderInput={(params) => <TextField {...params}
                                                                                    variant='standard'/>}
                                                value={toObjectTypeFilter || null}
                                                onChange={(event, value) => setToObjectTypeFilter(value)}/>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            alignItems: 'center',
                                            justifyContent: 'space-between'
                                        }}>
                                            <span style={{flex: 1}}>Load Method</span>
                                            <Autocomplete
                                                sx={{flex: 2}}
                                                options={toFilterData.loadMethod.map(el => {
                                                    return {label: el}
                                                })}
                                                renderInput={(params) => <TextField {...params}
                                                                                    variant='standard'/>}
                                                value={toLoadMethodFilter || null}
                                                onChange={(event, value) => setToLoadMethodFilter(value)}/>
                                        </div>

                                    </div>
                                </Popover>

                                <IconButton onClick={e => {
                                    setSortOpen(true)
                                    setSortAnchor(e.currentTarget)
                                }}>
                                    <Sort/>
                                </IconButton>
                                <Popover open={sortOpen}
                                         anchorEl={sortAnchor}
                                         anchorOrigin={{
                                             vertical: 'bottom',
                                             horizontal: 'left',
                                         }}
                                         onClose={_ => {
                                             setSortAnchor(null)
                                             setSortOpen(false)
                                         }}>
                                    <FormControl sx={{padding: 1}}>
                                        <FormLabel id="demo-controlled-radio-buttons-group">Sort</FormLabel>
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            value={sortValue}
                                            onChange={e => {
                                                setSortValue(e.target.value)
                                                setSortAsc(true)
                                            }}
                                        >
                                            <div style={{display: "flex"}}>
                                                <FormControlLabel value="none" control={<Radio/>} label="Alphabetical"/>
                                                {sortValue === 'none' &&
                                                    <IconButton onClick={_ => setSortAsc(!sortAsc)}>
                                                        {sortAsc ? <ArrowUpward/> : <ArrowDownward/>}
                                                    </IconButton>
                                                }
                                            </div>
                                            <div style={{display: "flex"}}>
                                                <FormControlLabel value="mapping" control={<Radio/>}
                                                                  label="Mapping Percentage"/>
                                                {sortValue === 'mapping' &&
                                                    <IconButton onClick={_ => setSortAsc(!sortAsc)}>
                                                        {sortAsc ? <ArrowUpward/> : <ArrowDownward/>}
                                                    </IconButton>
                                                }
                                            </div>

                                            <div style={{display: "flex"}}>
                                                <FormControlLabel value="follow-ups" control={<Radio/>}
                                                                  label="Total follow-ups"/>
                                                {sortValue === 'follow-ups' &&
                                                    <IconButton onClick={_ => setSortAsc(!sortAsc)}>
                                                        {sortAsc ? <ArrowUpward/> : <ArrowDownward/>}
                                                    </IconButton>
                                                }
                                            </div>
                                        </RadioGroup>
                                    </FormControl>
                                </Popover>

                                <Button variant='contained' onClick={_ => {
                                    setDOValue(0)
                                    setModalOpen(true)
                                }}>Add Objects</Button>
                                <Button variant='contained' onClick={_ => setTagsModalOpen(true)}>Mass Assign
                                    Tags</Button>
                                <Button variant='contained' onClick={_ => setArchiveModalOpen(true)}>Archive / Restore
                                    Objects</Button>
                                <Tooltip
                                    title={<h2>This table provides an overview of the progress made on the mapping and
                                        requirements gathering process for all in-scope _data objects_ in the current
                                        migration
                                        cycle. Nested within each data object are its template objects and sub-template
                                        objects.
                                        Begin with assigning the unassigned template objects to data objects through the
                                        "Add
                                        Objects" → "Assign Templates to Data Objects" feature to enable working on them
                                        in other
                                        SMT
                                        components.</h2>}
                                    sx={{marginLeft: 'auto'}}>
                                    <PriorityHighIcon/>
                                </Tooltip>
                            </div>
                            {mc?.mc_name === 'Default' &&
                                <Alert severity={'warning'} sx={{width: 'fit-content', margin: 'auto'}}><span
                                    style={{textDecoration: 'underline', fontWeight: 700}}>Rename the 'Default'
                            migration cycle through the Project Settings before working on it.</span></Alert>}

                            {creationAlert &&
                                <Alert severity={creationAlert.split(':')[0] === 'e' ? 'error' : 'success'}
                                       sx={{width: 'fit-content', margin: 'auto'}}><span
                                    style={{
                                        textDecoration: 'underline',
                                        fontWeight: 700
                                    }}>{creationAlert.split(':')[1]}</span></Alert>}
                            <hr/>
                            <div className='data-objects-block'>
                                {unassignedObjects.length > 0 &&
                                    <>
                                        <Alert severity={'warning'} sx={{width: 'fit-content'}}
                                               onClick={_ => {
                                                   setDOValue(2)
                                                   setModalOpen(true)
                                               }}>
                                    <span
                                        style={{
                                            textDecoration: 'underline',
                                            fontWeight: 700,
                                            cursor: 'pointer'
                                        }}>
                                            Unassigned Template Objects: {unassignedObjects.length}.
                                    </span>
                                        </Alert>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon/>}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header">
                                                Unassigned Template Objects
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {unassignedObjects.map(el =>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon/>}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header">
                                                            <div style={{
                                                                display: 'flex',
                                                                width: '80%',
                                                                alignItems: 'center'
                                                            }}>
                                                                <span style={{flex: 2}}>{el.to_name}</span>
                                                                <span style={{flex: 1}}>{el.target_system} mapped</span>
                                                                <span style={{flex: 1}}>{el.to_tags?.map(tag =>
                                                                    <Tooltip title={<h2>{tag.description}</h2>}>
                                                                        <Chip label={tag.tag_name}
                                                                              sx={{
                                                                                  marginLeft: .5,
                                                                                  marginTop: .2,
                                                                                  backgroundColor: tag.tag_color
                                                                              }}/>
                                                                    </Tooltip>)}</span>
                                                            </div>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <h3>Sub-template Objects:</h3>
                                                            {el.sto_names.join(', ')}
                                                        </AccordionDetails>
                                                    </Accordion>)}
                                            </AccordionDetails>
                                        </Accordion>
                                        <hr style={{width: '99%'}}/>
                                    </>
                                }
                                {(_ => userOnlyAssignments ? userAssignedObjects : assignedObjects)().sort((a, b) => {
                                    if (sortValue === 'none') {
                                        return sortAsc ? a.do_name.localeCompare(b.do_name) : b.do_name.localeCompare(a.do_name)
                                    } else if (sortValue === 'mapping') {
                                        return sortAsc ? parseFloat(a.do_mapping_progress_percentage) - parseFloat(b.do_mapping_progress_percentage) :
                                            parseFloat(b.do_mapping_progress_percentage) - parseFloat(a.do_mapping_progress_percentage)
                                    } else if (sortValue === 'follow-ups') {
                                        return sortAsc ? a.do_total_follow_up_count - b.do_total_follow_up_count :
                                            b.do_total_follow_up_count - a.do_total_follow_up_count
                                    }
                                    return 1
                                }).filter(el => el.do_name.toLowerCase().includes(input.toLowerCase()))
                                    .filter(el => (!toSystemFilter && !toLoadMethodFilter && !toObjectTypeFilter) || el.to_data.some(to => (to.target_system === toSystemFilter?.label || !toSystemFilter) &&
                                        (to.load_method_name === toLoadMethodFilter?.label || !toLoadMethodFilter) &&
                                        (to.template_object_type === toObjectTypeFilter?.label || !toObjectTypeFilter)))
                                    .map(object => <TOAccordion object={object} userData={userData}
                                                                allUserData={allUserData} systemFilter={toSystemFilter}
                                                                loadMethodFilter={toLoadMethodFilter}
                                                                objectTypeFilter={toObjectTypeFilter}/>
                                    )}
                            </div>
                        </div>
                    </div>
                </>
            }

        </>)

}

export default HomePage