import axios from 'axios'
import {
    AUTH_LANDING,
    AUTH_PCW,
    AUTH_PROJECT_HOME,
    CHECK_PROJECT_NAME,
    GET_ALL_PROJECTS,
    GET_COUNTRIES,
    GET_DIMS,
    GET_DOMAIN_USERS,
    GET_PERMISSIONS,
    GET_ROLES,
    GET_SYSTEMS,
    DATA_OBJECTS,
    GET_LOOKUP_TABLES,
    GET_MIGRATION_CYCLES,
    GET_TO_TAGS,
    GET_USER,
    INVITATION_EMAIL,
    MIGRATION_CYCLES,
    POST_IMAGE,
    REPORT_EMAIL,
    USERS,
    VALIDATE,
    METADATA,
    GET_PROJECT_PERMISSIONS,
    GET_UNASSIGNED_OBJECTS,
    GET_ASSIGNED_OBJECTS,
    GET_ALL_USER_DATA,
    AUTH_PROJECT_SETTINGS,
    ARCHIVED_OBJECTS,
    ARCHIVE_OBJECTS,
    UNARCHIVE_OBJECTS,
    DOWNLOAD,
    ALL_DATA_OBJECTS,
    TO_ALLOCATION,
    OBJECT_TAGS_ALLOCATION,
    OBJECT_TAGS,
    PUT_OBJECT_TAGS_ALLOCATION,
    GET_USER_GROUPS,
    GET_PROJECT_USERS,
    GET_USERS,
    GET_PROJECT_TARGET_SYSTEMS,
    GET_LOAD_METHODS,
    UPDATE_TEMPLATE_OBJECT,
    GET_PROJECT_SOURCE_SYSTEMS,
    GET_LOOKUP_VALUES,
    UPDATE_DO_DESCRIPTION,
    DATA_INGESTION_METHODS,
    PROJECT_PERMISSIONS_USERS
} from "./endpoints";

export const getAllProjects = async (token) => {
    return axios.get(GET_ALL_PROJECTS, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const getCountries = async (token) => {
    return axios.get(GET_COUNTRIES, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const getSystems = async (token) => {
    return axios.get(GET_SYSTEMS, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const getDomainUsers = async (token) => {
    return axios.post(GET_DOMAIN_USERS, null, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const getPermissions = async (token) => {
    return axios.get(GET_PERMISSIONS, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const getUserData = async (token) => {
    return axios.get(GET_USER, {
        headers: {
            "Authorization": `Bearer ${token}`

        }
    })
}

export const getRoles = async (token) => {
    return axios.get(GET_ROLES, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const getDims = async (token) => {
    return axios.get(GET_DIMS, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const checkProjectName = async (project_name, token) => {
    return axios.get(CHECK_PROJECT_NAME, {
        params: {project_name},
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const uploadImage = async (filepath, image, bucket_name) => {
    const formData = new FormData();
    formData.append('filepath', filepath)
    formData.append('file', image)
    formData.append('bucket_name', bucket_name)

    return axios.post(POST_IMAGE, formData, {
        headers: {
            "Content-type": "multipart/form-data",
        }
    })
}

export const postSystem = async (name, version, description, image_url, token) => {
    return axios.post(GET_SYSTEMS, {name, version, description, image_url}, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const postProject = async (name, description, project_creator, client, harmonization_in_scope, source_systems,
                                  target_systems, project_start_date, project_end_date, project_delete_date, country_code,
                                  citizenship_required, default_timezone, token) => {
    return axios.post(GET_ALL_PROJECTS, {
        name,
        description,
        project_creator,
        client,
        harmonization_in_scope,
        source_systems,
        target_systems,
        project_start_date,
        project_end_date,
        project_delete_date,
        country_code,
        citizenship_required,
        default_timezone
    }, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const putRoles = async (project_id, role_assignments, token) => {
    return axios.put(GET_ROLES, {project_id, role_assignments}, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const putMC = async (project_id, migration_cycles, token) => {
    return axios.put(MIGRATION_CYCLES, {project_id, migration_cycles}, {
        params: {project_id},
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const putDIM = async (project_id, data_ingestion_methods, token) => {
    return axios.put(GET_DIMS, {project_id, data_ingestion_methods}, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const sendInvitationEmail = async (project_name, sender, recipients, token) => {
    return axios.post(INVITATION_EMAIL, {recipients, project_name, sender}, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const addUsers = async (users, token) => {
    return axios.post(USERS, {users}, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const validateToken = async (token) => {
    return await axios.get(VALIDATE, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}


export const getMetadata = async (project_id, token) => {
    return axios.get(METADATA, {
        params: {project_id},
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const ReportIssue = async (priority, subject, description, token) => {
    return axios.post(REPORT_EMAIL, {priority, subject, description}, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}


export const getMigrationCycles = async (project_id, token) => {
    return axios.get(GET_MIGRATION_CYCLES, {
        params: {project_id},
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const authLanding = async (token) => {
    return axios.get(AUTH_LANDING, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}


export const getDataObjects = async (project_id, mc_id, token) => {
    return axios.get(DATA_OBJECTS, {
        params: {project_id, mc_id},
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const authPCW = async (token) => {
    return axios.get(AUTH_PCW, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const getLookupTables = async (project_id, mc_id, token) => {
    return axios.get(GET_LOOKUP_TABLES, {
        params: {project_id, mc_id},
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const getTemplateObjectTags = async (project_id, mc_id, token) => {
    return axios.get(GET_TO_TAGS, {
        params: {project_id, mc_id},
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const authProject = async (project_id, token) => {
    return axios.get(AUTH_PROJECT_HOME, {
        params: {project_id},
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const getProjectPermissions = async (project_id, token) => {
    return axios.get(GET_PROJECT_PERMISSIONS, {
        params: {project_id},
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const getUnassignedObjects = async (project_id, mc_id, token) => {
    return axios.get(GET_UNASSIGNED_OBJECTS, {
        params: {project_id, mc_id},
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const getAssignedObjects = async (project_id, mc_id, token) => {
    return axios.get(GET_ASSIGNED_OBJECTS, {
        params: {project_id, mc_id},
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const getAllUserData = async (token) => {
    return axios.get(GET_ALL_USER_DATA, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const authProjectSettings = async (project_id, token) => {
    return axios.get(AUTH_PROJECT_SETTINGS, {
        params: {project_id},
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const getObjectTagsAllocation = async (project_id, mc_id, token) => {
    return axios.get(OBJECT_TAGS_ALLOCATION, {
        params: {project_id, mc_id},
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const getObjectTags = async (project_id, mc_id, token) => {
    return axios.get(OBJECT_TAGS, {
        params: {project_id, mc_id},
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const putObjectTags = async (project_id, mc_id, template_object_tags, token) => {
    return axios.put(OBJECT_TAGS, {project_id, mc_id, template_object_tags}, {
        params: {project_id},
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const putObjectTagsAllocations = async (project_id, template_objects_metadata, token) => {
    return axios.put(PUT_OBJECT_TAGS_ALLOCATION, {project_id, template_objects_metadata}, {
        params: {project_id},
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const getArchivedObjects = async (project_id, mc_id, token) => {
    return axios.get(ARCHIVED_OBJECTS, {
        params: {project_id, mc_id},
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const archiveObjects = async (project_id, template_object_recids, token) => {
    console.log(template_object_recids)
    return axios.put(ARCHIVE_OBJECTS, {template_object_recids}, {
        params: {project_id},
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const unarchiveObjects = async (project_id, template_object_recids, token) => {
    return axios.put(UNARCHIVE_OBJECTS, {template_object_recids}, {
        params: {project_id},
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const download = async (public_url, project_id, token) => {
    return axios.get(DOWNLOAD, {
        params: {public_url, project_id},
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const getAllDataObjects = async (project_id, mc_id, token) => {
    return axios.get(ALL_DATA_OBJECTS, {
        params: {project_id, mc_id},
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const updateTOAllocation = async (project_id, to_allocation, token) => {
    return axios.put(TO_ALLOCATION, {to_allocation}, {
        params: {project_id},
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const createDataObjects = async (project_id, mc_id, do_metadata, token) => {
    return axios.post(DATA_OBJECTS, {do_metadata}, {
        params: {project_id, mc_id},
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const getUserGroups = async (project_id, token) => {
    return axios.get(GET_USER_GROUPS, {
        params: {project_id},
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const getProjectUsers = async (project_id, token) => {
    return axios.get(GET_PROJECT_USERS, {
        params: {project_id},
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const getUsers = async (token) => {
    return axios.get(GET_USERS, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const getProjectTargetSystems = async (project_id, token) => {
    return axios.get(GET_PROJECT_TARGET_SYSTEMS, {
        params: {project_id},
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const getProjectSourceSystems = async (project_id, token) => {
    return axios.get(GET_PROJECT_SOURCE_SYSTEMS, {
        params: {project_id},
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}


export const getLoadMethods = async (project_id, token) => {
    return axios.get(GET_LOAD_METHODS, {
        params: {project_id},
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const updateTemplateObject = async (project_id, data, token) => {
    return axios.put(UPDATE_TEMPLATE_OBJECT, data, {
        params: {project_id},
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}

export const updateDODescription = async (project_id, data, token) => {
    return axios.put(UPDATE_DO_DESCRIPTION, data, {
        params: {project_id},
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}

export const getLookupValues = async (project_id, lookup_table_id, token) => {
    return axios.get(GET_LOOKUP_VALUES, {
        params: {project_id, lookup_table_id},
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const getDataIngestionMethods = async (project_id, token) => {
    return axios.get(DATA_INGESTION_METHODS, {
        params: {project_id},
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const getProjectPermissionsUsers = async (project_id, token) => {
    return axios.get(PROJECT_PERMISSIONS_USERS, {
        params: {project_id},
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const updateUserGroups = (project_id, user_groups, token) => {
    return axios.put(GET_USER_GROUPS, {user_groups}, {
        params: {project_id},
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}