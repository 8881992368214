import './ManageNotesModal.css';
import {
    Button,
    TextField,
    IconButton,
    Popover,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    TableContainer, Paper, Table, TableBody, TableRow, TableCell
} from "@mui/material";
import FilterListIcon from '@mui/icons-material/FilterList';
import files from '../assets/files.png';
import search from '../assets/search2.png';
import React, {useEffect, useState} from "react";
import Editor from "../../../helpers/Editor/Editor";
import deleteImg from '../assets/delete.png'
import pen from '../assets/pen.png';



const templateObjects = [
    {
        id: 1,
        name: 'Only Migrate Sold-To Customers',
        templateObject: 'Customers',
        lastPostDate: 'timestamp',
        notes: [
            {
                author: 'Colin Bryan',
                date: '20.11.1998',
                dateEdited: false,
                text: 'Sold-to customers will be migrated only (account group 0001)'
            },
            {
                author: 'Job Champagne',
                date: '20.11.1998',
                dateEdited: true,
                text: 'Code updated to reflect this'
            },
        ]
    },
    {
        id: 2,
        name: 'Test',
        templateObject: 'Customers',
        lastPostDate: 'timestamp',
        notes: [
            {
                author: 'Colin Bryan',
                date: '20.11.1998',
                dateEdited: false,
                text: 'text text text'
            },
            {
                author: 'Job Champagne',
                date: '20.11.1998',
                dateEdited: true,
                text: 'test test'
            },
        ]
    },
    {
        id: 3,
        name: 'Test 3',
        templateObject: 'Material',
        lastPostDate: 'timestamp',
        notes: [
            {
                author: 'Colin Bryan',
                date: '20.11.1998',
                dateEdited: false,
                text: 'Sold-to customers will be migrated only (account group 0001)'
            },
            {
                author: 'Job Champagne',
                date: '20.11.1998',
                dateEdited: true,
                text: 'hello hello'
            },
        ]
    },

]

const ManageNotesModal = () => {
    const [selectedObject, setSelectedObject] = useState(templateObjects[0]);
    const [notes, setNotes] = useState(selectedObject.notes)
    const [addNewNote, setAddNewNote] = useState(false);
    const [input, setInput] = useState('');
    const [filterValue, setFilterValue] = useState('');

    const AddNewNote = () => {
        const [noteName, setNoteName] = useState('');
        const [noteLevel, setNoteLevel] = useState('');

        const cutTable = () => {
            return noteLevel === 'Data Object' ? 2 : noteLevel === 'Template Object' ? 3 : noteLevel === 'Sub Template Object' ? 4 : noteLevel === 'Migration Cycle' ? 1 : 5
        }

        const DropDown = (label) => {
            const [age, setAge] = useState('');

            const handleChange = (event) => {
                setAge(event.target.value);
            };

            return (
                <div className='manage-follow-ups-table-dropdown-container'>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 220, width: '100%'}}>
                        <InputLabel id="demo-simple-select-standard-label">{label}</InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={age}
                          disableUnderline
                          onChange={handleChange}
                          // label={'test'}
                          sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={10}>Ten</MenuItem>
                          <MenuItem value={20}>Twenty</MenuItem>
                          <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            )
        }


        const rows = [
            {
                name: 'Migration Cycle',
                description: _ => DropDown('Add Migration Cycle')
            },
            {
                name: 'Data Object',
                description: _ => DropDown('Add Data Object')
            },
            {
                name: 'Template Object',
                description:  _ => DropDown('Add Template Object')
            },
            {
                name: 'Sub-Template Object',
                description: _ => DropDown('Add Sub-Template Object')
            },
            {
                name: 'Field',
                description: _ => DropDown('Add Field')
            }
        ]





        return (
            <div className='manage-notes-note-info'>
                <h2>Add New Note:</h2>
                <TextField
                    variant='standard'
                    fullWidth
                    label='Note Name'
                    onChange={e => setNoteName(e.target.value)}
                    value={noteName}
                />

                <FormControl sx={{ marginTop: 1, minWidth: 150 }}>
                    <InputLabel id="demo-simple-select-helper-label">Select Note Level</InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={noteLevel}
                      placeholder='Add Note Level'
                      label="Select Note Level"
                      variant="standard"
                      onChange={e => setNoteLevel(e.target.value)}
                    >
                    <MenuItem value="">
                    <em>None</em>
                    </MenuItem>
                    <MenuItem value={'Migration Cycle'}>Migration Cycle</MenuItem>
                    <MenuItem value={'Data Object'}>Data Object</MenuItem>
                    <MenuItem value={'Template Object'}>Template Object</MenuItem>
                    <MenuItem value={'Sub Template Object'}>Sub Template Object</MenuItem>
                    <MenuItem value={'Mapping Row'}>Mapping Row</MenuItem>

                    </Select>
                    <FormHelperText>Enter the type of note to create</FormHelperText>
                </FormControl>

                {
                    noteLevel &&
                    <div style={{width: '50%', marginLeft: 'auto', marginRight: '1rem'}}>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 450 }} aria-label="simple table">
                        <TableBody>
                          {rows.slice(0, cutTable()).map((row) => (
                            <TableRow
                              key={row.name}
                            >
                              <TableCell component="th" scope="row" sx={{width: '300px', backgroundColor: '#757272', color: 'white' }}>
                                {row.name}:
                              </TableCell>
                              <TableCell sx={{width: '300px'}} component={row.description} align="left"></TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>

                </div>
                }


                <div style={{marginTop: '3rem', height: '10vh'}}>
                    <Editor
                        notes={notes}
                        setNotes={setNotes}
                    />

                </div>


            </div>

        )
    }

    const CustomFilter = () => {
      const [anchorEl, setAnchorEl] = useState(null);
      // const { setFilters } = useListContext();

      const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
      };

      const handleClose = () => {
        setAnchorEl(null);
      };

      const handleFilterSelect = (filterValue) => {
        setFilterValue(filterValue)
        handleClose();
      };

      return (
        <React.Fragment>
          <IconButton onClick={handleOpen} color="primary">
            <FilterListIcon />
          </IconButton>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={() => handleFilterSelect('Migration Cycle')}>Migration Cycle</MenuItem>
            <MenuItem onClick={() => handleFilterSelect('Data Object')}>Data Object</MenuItem>
              <MenuItem onClick={() => handleFilterSelect('Template Object')}>Template Object</MenuItem>
              <MenuItem onClick={() => handleFilterSelect('Sub-Template Object')}>Sub-Template Object</MenuItem>
              <MenuItem onClick={() => handleFilterSelect('Field-Level')}>Field-Level</MenuItem>
          </Popover>
        </React.Fragment>
      );
    };

    return (
        <div className='manage-notes-main'>
            <div className='manage-notes-template-object-container'>
                <div className='manage-notes-template-object-btns'>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1rem', height: '40%', justifyContent: 'space-between'}}>
                        {/*<Button>Filters</Button>*/}
                        <CustomFilter/>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <img src={search} style={{width: '24px', height: '24px'}}></img>
                            <TextField
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                      "& > fieldset": {
                                        border: "none"
                                      }
                                    }
                                  }}
                                id="standard-basic"
                                placeholder='Search...'
                                variant="outlined"
                                onChange={event => setInput(event.target.value)}

                            />
                        </div>

                        <Button
                            sx={{minWidth: 100}}
                            onClick={_ => setAddNewNote(true)}
                            variant='contained'
                            >+ New
                        </Button>
                    </div>

                    <span>Template Object</span>


                    {
                        templateObjects.filter(el => el.name.toLowerCase().includes(input.toLowerCase()) || el.templateObject.toLowerCase().includes(input.toLowerCase())).map(obj => {
                            return (
                                <div style={selectedObject === obj ? {boxShadow: 'none'}: null} className='manage-notes-note-card' onClick={_ => {
                                    setAddNewNote(false)
                                    setNotes(obj.notes)
                                    setSelectedObject(obj)
                                }}>
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                        <span><strong>{obj.id}</strong> | {obj.name}</span>
                                        <span>{obj.notes.length}</span>
                                    </div>

                                    <span style={{marginBottom: '1rem'}}>Template Object: {obj.templateObject}</span>

                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '1rem'}}>
                                        <span>Last Post Date: {obj.lastPostDate}</span>
                                        <img style={{width: '11px', height: '22px'}} src={files}></img>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>


            </div>

            <hr style={{margin: '1rem'}}/>

            {
                addNewNote ?
                    <AddNewNote/> :
                        <div className='manage-notes-note-info'>
                            <div className='manage-notes-list-header'>
                                <h1 style={{flex: 1, fontSize: 60, marginBottom: 0, marginTop: 0}}>{selectedObject.id}</h1>
                                <div style={{flex: 18, display: 'flex', flexDirection: 'column'}}>
                                    <h2 style={{marginBottom: '1rem', marginTop: 0}}>{selectedObject.name}</h2>
                                    <span><strong>Template Object:</strong> {selectedObject.templateObject}</span>
                                    <span>Last Post Date: {selectedObject.lastPostDate}</span>
                                </div>

                                <Button variant='contained' sx={{flex: 2}}>Copy link</Button>

                            </div>

                            {
                                notes.map(note => {
                                    return (
                                        <div className='manage-notes-note'>
                                            <span style={{color: 'gray'}}>{note.author} | {note.date} {note.dateEdited ? '(Edited)' : null}</span>
                                            {/*<p style={{fontSize: 18, marginTop: '0.3rem', fontWeight: 400}}>{note.text}</p>*/}
                                            <div style={{fontSize: 18, marginTop: '0.3rem', fontWeight: 400}} dangerouslySetInnerHTML={{ __html: note.text }} />

                                            <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '3.5rem', marginRight: '1rem', gap: '0.5rem'}}>
                                                <img style={{width: '20px', height: '20px', cursor: 'pointer'}} src={pen}></img>
                                                <img
                                                    onClick={_ => setNotes(notes.filter(el => el !== note))}
                                                    style={{width: '20px', height: '20px', cursor: 'pointer'}}
                                                    src={deleteImg}>

                                                </img>

                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <div style={{marginTop: '3rem', height: '10vh', zIndex: 201}}>
                                <Editor
                                    notes={notes}
                                    setNotes={setNotes}
                                />
                            </div>
                        </div>

            }


        </div>
    )
}

export default ManageNotesModal;