import {Box, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import ReactQuill from "react-quill";
import Quill from 'quill'
import {useState} from "react";
import 'react-quill/dist/quill.snow.css';
import LoadingButton from "@mui/lab/LoadingButton";
import {ReportIssue} from "../../api/requests";
import { ImageDrop } from 'quill-image-drop-module';

Quill.register('modules/imageDrop', ImageDrop)

const HelpModal = () => {
    const [description, setDescription] = useState()
    const [priority, setPriority] = useState()
    const [subject, setSubject] = useState()
    const [loading, setLoading] = useState(false)

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', gap: 2, width: '40vw', padding: '0 1rem'}}>
            <h3>Resources:</h3>
            <a href="#">Video Walkthrough</a>
            <a href="#">ATOM SMT Documentation</a>
            <a href="#">FAQ & Glossary</a>
            <h3>Still need help? Contact support by filling out the form below</h3>
            <FormControl variant={'standard'} fullWidth>
                <InputLabel id={'priority'}>Priority</InputLabel>
                <Select labelId={'priority'} onChange={e => setPriority(e.target.value)}>
                    <MenuItem value={'Critical'}>Critical</MenuItem>
                    <MenuItem value={'High'}>High</MenuItem>
                    <MenuItem value={'Medium'}>Medium</MenuItem>
                    <MenuItem value={'Low'}>Low</MenuItem>
                </Select>
            </FormControl>
            <TextField label={'Subject'} onChange={e => setSubject(e.target.value)}/>
            <ReactQuill theme={'snow'} value={description} onChange={setDescription} style={{height: '15vh'}} modules={{imageDrop: true}}/>
            <hr/>
            <LoadingButton loading={loading} variant={'contained'} sx={{marginLeft: 'auto'}}
                           onClick={async _ => {
                               setLoading(true)
                               await ReportIssue(priority, subject, description, localStorage.getItem('token'))
                               setLoading(false)
                           }}>
                <span>Send</span>
            </LoadingButton>
        </Box>
    )
}

export default HelpModal