import './DisplayMappingDifferencesModal.css';
import {Tab, Tabs, Box, Typography, Button, styled } from "@mui/material";
import {useState} from "react";
import {DataGrid} from "@mui/x-data-grid";
import warning from '../../assets/warning.png';
import extend from '../../assets/extend.png';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const rows = [
        {
            id: 1,
            functionalFieldName: 'Material Number',
            technicalFieldName: 'MATNR',
            description: 'Old Material',
            type: 'Char',
            length: '40',
            precision: '',
            scale: '',
            mandatoryForSystem: 'Yes',
            keyField: 'Yes',
        },
        {
            id: 2,
            functionalFieldName: 'Material Number',
            technicalFieldName: 'MATNR',
            description: 'Old Material',
            type: 'Char',
            length: '40',
            precision: '',
            scale: '',
            mandatoryForSystem: 'Yes',
            keyField: 'Yes',
        },
        {
            id: 3,
            functionalFieldName: 'Material Number',
            technicalFieldName: 'MATNR',
            description: 'Old Material',
            type: 'Char',
            length: '40',
            precision: '',
            scale: '',
            mandatoryForSystem: 'Yes',
            keyField: 'Yes',
        },
        {
            id: 4,
            functionalFieldName: 'Material Number',
            technicalFieldName: 'MATNR',
            description: 'Old Material',
            type: 'Char',
            length: '40',
            precision: '',
            scale: '',
            mandatoryForSystem: 'Yes',
            keyField: 'Yes',
        }
];

const columns = [
    {
        field: 'functionalFieldName',
        headerName: 'Functional Field Name',
        width: 250,
    },
    {
        field: 'technicalFieldName',
        headerName: 'Technical Field Name',
        width: 280,
        editable: true,
    },
    {
        field: 'description',
        headerName: 'Description',
        width: 200,
        editable: true,
    },
    {
        field: 'type',
        headerName: 'Type',
        width: 100,
        editable: true,
    },
    {
        field: 'length',
        headerName: 'Length',
        width: 100,
        editable: true,
    },
    {
        field: 'precision',
        headerName: 'Precision',
        width: 100,
        editable: true,
    },
    {
        field: 'scale',
        headerName: 'Scale',
        width: 100,
        editable: true,
    },
    {
        field: 'mandatoryForSystem',
        headerName: 'Mandatory for System',
        width: 200,
        editable: true,
    },
    {
        field: 'keyField',
        headerName: 'Key Field',
        width: 100,
        editable: true,
    },
]


const DisplayMappingDifferencesModal = ({files}) => {
   const [value, setValue] = useState(0);
   const [rowSelectionModel, setRowSelectionModel] = useState([]);
   const [selectedRows, setSelectedRows] = useState(null);
   const [extendTabs, setExtendTabs] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

    return (
        <div className='display-mapping-differences-main'>
            <h2>Template: {files[0].name}</h2>
            <hr style={{width: '20.5%', margin: 0}}/>

            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1rem'}}>
                    <Tabs visibleScrollbar variant='scrollable' sx={ extendTabs? {width: 500} : {width: 400}} value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Basic Data" />
                        <Tab label="Plant Data" />
                        <Tab label="Storage Location" />
                    </Tabs>
                    <img onClick={ _ => setExtendTabs(prevState => !prevState)} style={{width: '40px', height: '30px', cursor: 'pointer'}} src={extend}></img>

                </Box>

                <h2>Target Structure Name : s_mara</h2>

                <CustomTabPanel value={value} index={0}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        initialState={{
                        pagination: {
                        paginationModel: {
                          pageSize: 5,
                        },
                        },
                        }}
                        pageSizeOptions={[5]}
                        checkboxSelection
                        disableRowSelectionOnClick
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            const selectedRowsData = newRowSelectionModel.map((id) => rows.find((row) => row.id === id));

                            setRowSelectionModel(newRowSelectionModel);
                            setSelectedRows(selectedRowsData)
                        }}
                        rowSelectionModel={rowSelectionModel}
                    />

                    <Button className='display-mapping-differences-update-btn' variant='contained'>Update Mapping View</Button>
                </CustomTabPanel>

                <CustomTabPanel value={value} index={1}>
                    Plant Data
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <div style={{padding: '2rem', display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '3rem', alignItems: 'center', backgroundColor: '#FDEDED', width: '40%', margin: '3rem auto'}}>
                        <img style={{width: '60px'}} src={warning}></img>
                        <span style={{fontSize: 36}}>Sheet Deleted From Load Template</span>
                    </div>
                </CustomTabPanel>

            </Box>
        </div>
    )
}

export default DisplayMappingDifferencesModal;
