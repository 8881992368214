import {DataGrid} from "@mui/x-data-grid";
import MainModal from "../../../helpers/Modal/MainModal";
import ViewWarnings from "../MarkTargetFields/ViewWarnings/ViewWarnings";
import ManageNotesModal from "../../ManageObjects/NotesModal/ManageNotesModal";
import ViewChangeLogModal from "../../ManageObjects/ViewChangeLog/ViewChangeLogModal";
import ManageFollowUpsModal from "../../ManageObjects/ManageFollowUpsModal/ManageFollowUpsModal";
import React, {useState} from "react";
import key from "../assets/key.png";
import search from "../../ManageObjects/assets/search2.png";
import {Button, FormControl, MenuItem, Select} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";

const rowsData = [
    {
        id: 1,
        datasetName: 'MARA',
        technicalField: 'MATNR',
        sourceFunctionalName: 'Description',
        targetFunctionalName: 'Material Number',
        technicalName: 'MATNR',
        required: 'Yes',
        links: '',
        key: true,
        settings: true,
    },
    {
        id: 2,
        datasetName: 'MARA',
        technicalField: 'MATNR',
        sourceFunctionalName: 'Description',
        targetFunctionalName: 'Material Number',
        technicalName: 'MATNR',
        required: 'Yes',
        links: '',
        key: false,
        settings: false
    },
    {
        id: 3,
        datasetName: 'MARA',
        technicalField: 'MATNR',
        sourceFunctionalName: 'Description',
        targetFunctionalName: 'Material Number',
        technicalName: 'MATNR',
        required: 'Yes',
        links: '',
        key: false,
        settings: false
    },
    {
        id: 4,
        datasetName: 'MARA',
        technicalField: 'MATNR',
        sourceFunctionalName: 'Description',
        targetFunctionalName: 'Material Number',
        technicalName: 'MATNR',
        required: '-',
        links: '',
        key: false,
        settings: false
    },
]


const MapSourceFieldsToTargetFields = ({warningModalOpen, setWarningModalOpen, isRowSelected, setIsRowSelected}) => {
    const [hoveredCellId, setHoveredCellId] = useState(null);
    const [rows, setRows] = useState(rowsData);
    const [showNotes, setShowNotes] = useState(false);
    const [showFollowUps, setShowFollowUps] = useState(false);
    const [viewChangeLogs, setViewChangeLogs] = useState(false);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);

    const columns = [
        {
            field: 'checkbox',
            headerName: '',
            width: 50,
        },
        {
            field: 'datasetName',
            headerName: 'Dataset Name',
            width: 250,
            renderCell: (params) => {
                return (
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <Select
                              id="demo-simple-select-standard"
                              value={params.row.datasetName}
                              onChange={e => {
                                  setRows(prev => prev.map(el => {
                                      if (el === params.row) {
                                          return {...el, required: e.target.value}
                                      }

                                      return el
                                  }))
                              }}
                            >
                              <MenuItem value={'MATNR'}>MATNR</MenuItem>
                              <MenuItem value={'MATKX'}>MATKX</MenuItem>
                              <MenuItem value={'MARA'}>MARA</MenuItem>
                              <MenuItem value={'-'}>-</MenuItem>
                            </Select>
                        </FormControl>

                    </div>
                )
            }
        },
        {
            field: 'technicalField',
            headerName: 'Technical Field',
            width: 250,
            renderCell: (params) => {
                return (
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <Select
                              id="demo-simple-select-standard"
                              value={params.row.technicalField}
                              onChange={e => {
                                  setRows(prev => prev.map(el => {
                                      if (el === params.row) {
                                          return {...el, required: e.target.value}
                                      }

                                      return el
                                  }))
                              }}
                            >
                              <MenuItem value={'MATNR'}>MATNR</MenuItem>
                              <MenuItem value={'MATKX'}>MATKX</MenuItem>
                              <MenuItem value={'MARA'}>MARA</MenuItem>
                              <MenuItem value={'-'}>-</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                )
            }
        },
        {
            field: 'sourceFunctionalName',
            headerName: 'Functional Name',
            width: 250,
            editable: true,
        },

        {
            field: 'targetFunctionalName',
            headerName: 'Functional Name',
            width: 250,
            renderCell: (params) => {
                return (
                    <div style={{display: 'flex'}} onMouseOut={_ => setHoveredCellId(null)} onMouseOver={_ => setHoveredCellId(params.id)}>
                        <span style={{width: '3.2rem'}}>{params.row.technicalName}</span>

                        <img
                            onClick={_ => setRows((prev) => prev.map(el => {
                                if (el.id === params.id) {
                                    return {...el, key: true}
                                }
                                return {...el, key: false}
                            }))}
                            className='mark-target-fields-key-img'
                            style={!params.row.key ? {opacity: '30%'} : null }
                            src={key}>

                        </img>
                    </div>
                )
            }
        },
        {
            field: 'technicalName',
            headerName: 'Technical Name',
            width: 250,
            editable: true,
        },
        {
            field: 'required',
            headerName: 'Required?',
            width: 250,
            editable: true,
            renderCell: (params) => {
                return (
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <span>{params.row.required}</span>
                        <SettingsIcon
                            onClick={_ => setRows((prev) => prev.map(el => {
                                if (el.id === params.id) {
                                    return {...el, settings: true}
                                }
                                return {...el, settings: false}
                            }))}
                            className='mark-target-fields-settings-img'
                            sx={!params.row.settings ? {opacity: '30%'} : null }
                        />

                    </div>
                )
            }
        },
        {
            field: 'links',
            headerName: 'Links',
            width: 550,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return (
                    <div style={{display: 'flex', gap: '2rem'}}>
                        <Button
                            onClick={_ => setShowFollowUps(true)}
                            sx={{whiteSpace: 'nowrap'}}
                            variant='outlined'>Follow-ups (2)
                        </Button>
                        <Button
                            onClick={_ => setShowNotes(true)}
                            sx={{whiteSpace: 'nowrap'}}
                            variant='outlined'>Notes (3)
                        </Button>
                        <Button
                            onClick={_ => setViewChangeLogs(true)}
                            sx={{whiteSpace: 'nowrap'}}
                            variant='outlined'
                            // disabled={true}
                        >Change Log
                        </Button>
                    </div>
                )

            },
        },
    ]


    return (
        <div style={{display: 'flex', justifyContent: 'center', marginTop: '2rem'}}>
            <div style={{width: '90%'}}>
                <div style={{width: '100%', display: 'flex', minWidth: 1400, height: '4rem'}}>
                    <span style={{color:'white', fontSize: 30, width: '65%', height: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center', backgroundColor: '#016887CF'}}>Source Data</span>
                    <span style={{color:'white', fontSize: 30, width: '65%', height: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center', backgroundColor: '#1D4956'}}>Target Data</span>
                    <span style={{color:'white', fontSize: 30, width: '35%', height: '100%', alignItems: 'center', minWidth: 600, display: 'flex', justifyContent: 'center', backgroundColor: '#613FEBFC'}}>Quick Actions</span>
                </div>
                <DataGrid
                    sx={{minWidth: 1400}}
                    checkboxSelection
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 5,
                            },
                        },
                    }}
                    pageSizeOptions={[5]}
                    disableRowSelectionOnClick
                    selection
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setIsRowSelected(newRowSelectionModel)
                        setRowSelectionModel(newRowSelectionModel);
                    }}
                    rowSelectionModel={rowSelectionModel}
                />

            </div>


            <MainModal
                tooltip={true}
                modalOpen={warningModalOpen}
                setModalOpen={setWarningModalOpen}
                headerText='View Warnings'
                Child={ViewWarnings}
            />

            <MainModal
                tooltip={true}
                modalOpen={showNotes}
                setModalOpen={setShowNotes}
                headerText='Manage Notes'
                Child={ManageNotesModal}/>

            <MainModal
                tooltip={true}
                modalOpen={viewChangeLogs}
                setModalOpen={setViewChangeLogs}
                headerText='View Change Log'
                Child={ViewChangeLogModal}
                selectedTemplate={'Material'}
            />

            <MainModal
                tooltip={true}
                modalOpen={showFollowUps}
                setModalOpen={setShowFollowUps}
                headerText='Manage Follow Ups'
                Child={ManageFollowUpsModal}
            />
        </div>
    )
}

export default MapSourceFieldsToTargetFields;