import {useState} from "react";
import {Chip, Tab, Tabs, Tooltip} from "@mui/material";
import FollowUpAccordion from "./FollowUpAccordion";

const TOTabs = ({to_data, userData, allUserData, loadMethodFilter, systemFilter, objectTypeFilter}) => {
    const [value, setValue] = useState(0)
    const filteredTOData = to_data.filter(to => (to.target_system === systemFilter?.label || !systemFilter) &&
        (to.load_method_name === loadMethodFilter?.label || !loadMethodFilter) &&
        (to.template_object_type === objectTypeFilter?.label || !objectTypeFilter))

    return <>
        <h3>Template Objects</h3>
        <Tabs value={value} onChange={(_, newValue) => setValue(newValue)}>
            {filteredTOData.map(el => <Tab label={el.to_name}/>)}
        </Tabs>
        {filteredTOData.map((el, i) => {
            return <>
                {value === i && <>
                    <div style={{display: 'flex', gap: '3rem', alignItems: 'center'}}>
                        <p>{el.target_system}</p>
                        <p>{el.to_mapping_progress_percentage} mapped</p>
                        <p>{el.to_total_follow_up_count} total follow-ups</p>
                        <p>{el.to_value_streams.join(', ')}</p>
                        <p>{el.template_object_type}</p>
                        <p>{el.load_method_name}</p>
                        <p>{el.to_tags.map(tag => <Tooltip title={<h2>{tag.description}</h2>}>
                            <Chip label={tag.tag_name}
                                  sx={{
                                      marginLeft: .5,
                                      marginTop: .2,
                                      backgroundColor: tag.tag_color
                                  }}/>
                        </Tooltip>)}</p>
                    </div>
                    <FollowUpAccordion allUserData={allUserData} userData={userData}
                                       follow_up_data={el.to_follow_up_data} count={el.to_follow_up_count}
                                       summary={`${el.to_name} Follow-Ups (${el.to_follow_up_count})`}/>
                    <h3>Sub-Template Objects</h3>
                    {el.sto_data.map(sto =>
                        <FollowUpAccordion allUserData={allUserData} userData={userData}
                                           follow_up_data={sto.sto_follow_up_data} summary={`
                            ${sto.sto_name} | ${sto.sto_mapping_progress_percentage} mapped
                            | ${sto.sto_follow_up_count} Follow-Ups`} count={sto.sto_follow_up_count} STO/>
                    )}
                </>}
            </>
        })}
    </>
}

export default TOTabs