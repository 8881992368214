import './FileProcessModal.css';
import close from "../assets/close.png";
import checked from "../assets/checked.png";
import {Button, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {useState} from "react";

const datasetNameList = ['Material Long Text', 'Customers', 'New Dataset']


const FileProcessModal = ({isOpen, files, setFiles}) => {
    const [datasetName, setDatasetName] = useState('');


    const removeRow = (row) => {
        setFiles(files.filter(file => file.name !== row.name))
    }

    const assignBtn = () => {
        setFiles([])
    }


    return (
        <div className='assign-dataset-container'>
            {
                files.map(row => {
                    return (
                        <div className='assign-dataset-row-container'>
                            <div className='assign-dataset-row'>
                                <img
                                    alt='img'
                                    className='assign-dataset-close-img'
                                    onClick={_ => removeRow(row)}
                                    src={close}>

                                </img>
                                <img alt='img' className='assign-dataset-img' src={checked}></img>
                                <p className='assign-dataset-row-lbl'>{row.name}</p>
                            </div>

                            <FormControl className='assign-dataset-select-container'>
                                <InputLabel id="demo-simple-select-label">Dataset Name</InputLabel>
                                <Select
                                    variant="standard"
                                    labelId="assign-select-label"
                                    id="assign-select"
                                    value={datasetName}
                                    onChange={event => setDatasetName(event.target.value)}
                                >
                                    {
                                        datasetNameList.map(name => {
                                            return (<MenuItem value={name}>{name}</MenuItem>)
                                        })
                                    }
                                </Select>
                              </FormControl>
                        </div>
                    )
                })
            }

            <Button onClick={_ => {
                assignBtn()

                isOpen(false)

            }} variant="contained" className='assign-dataset-btn'>Assign</Button>

        </div>
    )
}

export default FileProcessModal;