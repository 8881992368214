import {Alert, Button, IconButton, Modal, Snackbar, Tooltip} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import './MainModal.css'
import {useState} from "react";
import ErrorIcon from '@mui/icons-material/Error';
import tooltip from '../../Project/ManageObjects/assets/tooltip.png';

const MainModal = ({modalOpen, setModalOpen, headerText, Child, needsWarning = false, tooltip= false, tooltipLbl='', saveBtn= false, ...props}) => {
    const [warned, setWarned] = useState(false)

    const [open, setOpen] = useState(false);

    const handleCloseAlert = () => {
        setOpen(false)
    }

    const handleClose = () => {
        if (needsWarning && !warned) {
            setWarned(true)
            setOpen(true)
        } else {
            setModalOpen(false)
            setWarned(false)
            setOpen(false)
        }
    }
    return (
        <Modal open={modalOpen} onClose={handleClose}
               style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div className='modal-container'>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseAlert}
                          anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                    <Alert onClose={handleCloseAlert} severity="error" sx={{width: '100%'}}>
                        All unsaved changes will be lost
                    </Alert>
                </Snackbar>
                <header>
                    <div style={{display: 'flex', alignItems: 'center', gap: '1rem'}}>
                        <h1>{headerText}</h1>
                        {
                            tooltip &&
                                <h1 style={{display: 'flex', margin: 0}}>
                                    <Tooltip title={<h2>{tooltipLbl}</h2>}>
                                        <ErrorIcon
                                            sx={{color: '#707070', fontSize: 50, marginTop: '0.3rem'}}
                                        />
                                    </Tooltip>
                                </h1>
                        }
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', gap: '2rem', flexDirection: 'row'}}>
                        {
                            saveBtn &&
                                <Button variant='contained'>{saveBtn}</Button>
                        }
                        <IconButton onClick={handleClose}>
                            <CloseIcon/>
                        </IconButton>
                    </div>

                </header>
                <hr/>
                <div style={{maxHeight: '80vh', overflowY:'auto'}}>
                    <Child setWarned={setWarned} setWarningOpen={setOpen} warned={warned} {...props}/>
                </div>
            </div>
        </Modal>
    )
}

export default MainModal