import './ViewChangeLogModal.css';
import {Button} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import search from "../assets/search.png";
import poll from "../assets/poll.png";


const ViewChangeLogModal = ({selectedTemplate}) => {
    const columns = [
        {
            field: 'changeID',
            headerName: 'Change ID',
            width: 80
        },
        {
            field: 'subTemplateObject',
            headerName: 'Sub-Template Object',
            width: 200,
        },
        {
            field: 'sourceDataset',
            headerName: 'Source Dataset(s)',
            width: 150,

        },
        {
            field: 'sourceField',
            headerName: 'Source Field(s)',
            width: 150,
        },
        {
            field: 'targetField',
            headerName: 'Target Field',
            width: 150
        },
        {
            field: 'operation',
            headerName: 'Operation',
            width: 150,
        },
        {
            field: 'changedElement',
            headerName: 'changedElement',
            width: 200,

        },
        {
            field: 'changedFrom',
            headerName: 'Changed From',
            width: 200,
        },
        {
            field: 'changedTo',
            headerName: 'Changed To',
            width: 200
        },
        {
            field: 'lastUpdatedBy',
            headerName: 'Last Updated By',
            width: 200,
        },
        {
            field: 'lastUpdatedDate',
            headerName: 'Last Updated Date',
            width: 200,

        },
        {
            field: 'restore',
            headerName: 'Restore?',
            width: 150,
        },
    ]

    const rows = [
        {
            id: 1,
            changeID: 1,
            subTemplateObject: 'Plant Data',
            sourceDataset: 'MARC',
            sourceField: 'Materials',
            targetField: 'Basic Data',
            operation: '100x52',
            changedElement: 'Materials',
            changedFrom: 'Basic Data',
            changedTo: '100x52',
            lastUpdatedBy: 'Materials',
            lastUpdatedDate: 'Basic Data',
            restore: '100x52',
        },
        {
            id: 2,
            changeID: 2,
            subTemplateObject: 'Plant Data',
            sourceDataset: 'MARC',
            sourceField: 'Materials',
            targetField: 'Basic Data',
            operation: '100x52',
            changedElement: 'Materials',
            changedFrom: 'Basic Data',
            changedTo: '100x52',
            lastUpdatedBy: 'Materials',
            lastUpdatedDate: 'Basic Data',
            restore: '100x52',
        },
        {
            id: 3,
            changeID: 3,
            subTemplateObject: 'Plant Data',
            sourceDataset: 'MARC',
            sourceField: 'Materials',
            targetField: 'Basic Data',
            operation: '100x52',
            changedElement: 'Materials',
            changedFrom: 'Basic Data',
            changedTo: '100x52',
            lastUpdatedBy: 'Materials',
            lastUpdatedDate: 'Basic Data',
            restore: '100x52',
        },
        {
            id: 4,
            changeID: 4,
            subTemplateObject: 'Plant Data',
            sourceDataset: 'MARC',
            sourceField: 'Materials',
            targetField: 'Basic Data',
            operation: '100x52',
            changedElement: 'Materials',
            changedFrom: 'Basic Data',
            changedTo: '100x52',
            lastUpdatedBy: 'Materials',
            lastUpdatedDate: 'Basic Data',
            restore: '100x52',
        },
        {
            id: 5,
            changeID: 5,
            subTemplateObject: 'Plant Data',
            sourceDataset: 'MARC',
            sourceField: 'Materials',
            targetField: 'Basic Data',
            operation: '100x52',
            changedElement: 'Materials',
            changedFrom: 'Basic Data',
            changedTo: '100x52',
            lastUpdatedBy: 'Materials',
            lastUpdatedDate: 'Basic Data',
            restore: '100x52',
        },
        {
            id: 6,
            changeID: 6,
            subTemplateObject: 'Plant Data',
            sourceDataset: 'MARC',
            sourceField: 'Materials',
            targetField: 'Basic Data',
            operation: '100x52',
            changedElement: 'Materials',
            changedFrom: 'Basic Data',
            changedTo: '100x52',
            lastUpdatedBy: 'Materials',
            lastUpdatedDate: 'Basic Data',
            restore: '100x52',
        },
        {
            id: 7,
            changeID: 7,
            subTemplateObject: 'Plant Data',
            sourceDataset: 'MARC',
            sourceField: 'Materials',
            targetField: 'Basic Data',
            operation: '100x52',
            changedElement: 'Materials',
            changedFrom: 'Basic Data',
            changedTo: '100x52',
            lastUpdatedBy: 'Materials',
            lastUpdatedDate: 'Basic Data',
            restore: '100x52',
        },
        {
            id: 8,
            changeID: 8,
            subTemplateObject: 'Plant Data',
            sourceDataset: 'MARC',
            sourceField: 'Materials',
            targetField: 'Basic Data',
            operation: '100x52',
            changedElement: 'Materials',
            changedFrom: 'Basic Data',
            changedTo: '100x52',
            lastUpdatedBy: 'Materials',
            lastUpdatedDate: 'Basic Data',
            restore: '100x52',
        },
        {
            id: 9,
            changeID: 9,
            subTemplateObject: 'Plant Data',
            sourceDataset: 'MARC',
            sourceField: 'Materials',
            targetField: 'Basic Data',
            operation: '100x52',
            changedElement: 'Materials',
            changedFrom: 'Basic Data',
            changedTo: '100x52',
            lastUpdatedBy: 'Materials',
            lastUpdatedDate: 'Basic Data',
            restore: '100x52',
        },
    ]

    return (
        <div style={{width: '80vw', height: '70vh', padding: '1'}}>
            <div style={{display: 'flex', flexDirection: 'column', fontSize: 25}}>
                <div style={{padding: '1rem', backgroundColor: '#D9D9D9'}}>
                    <span><strong>Template Object: </strong> {selectedTemplate}</span>
                </div>

                <span style={{paddingLeft: '1rem', marginBottom: '0.5rem'}}><strong>Total Number of Changes in Migration Cycle: </strong>103</span>
                <span style={{paddingLeft: '1rem', marginBottom: '0.5rem'}}><strong>Total Changes in Last 7 Days: </strong>36</span>
                <div style={{paddingLeft: '1rem', paddingRight: '1rem',marginBottom: '0.5rem', display: 'flex', justifyContent: 'space-between'}}>
                    <span><strong>Most Changes Made By: </strong>Colin Bryan (6)</span>
                    <Button variant='outlined'>Export</Button>
                </div>


            </div>

            <div style={{marginTop: '1rem'}}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    pageSizeOptions={[10]}
                    disableRowSelectionOnClick
                    selection
                />
            </div>

            <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '2rem', marginRight: '1rem'}}>
                <Button className='post-load-processing-save-close-btn' variant='contained'>Save & Close</Button>
            </div>

        </div>
    )
}

export default ViewChangeLogModal;
