import {useState} from "react";
import {Button} from "@mui/material";
import './DefineMigrationCycle.css'
import {DataGrid, GridToolbarContainer, useGridApiContext} from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import dayjs from "dayjs";
import Typography from "@mui/material/Typography";

const DefineMigrationCycle = ({handleComplete, data, setData, handleBack, setHelpModalOpen}) => {
    const [fields, setFields] = useState(data.migrationCycles ? data.migrationCycles : []);
    const [nameError, setNameError] = useState(false)
    const [activeError, setActiveError] = useState(false)
    const [dateError, setDateError] = useState(false)
    const [dateErrorMessage, setDateErrorMessage] = useState('All start dates must less then or equal to end dates.')

    const processRowUpdate = (newValue, oldValue) => {
        const activeChange = newValue.active !== oldValue.active

        if (activeChange && oldValue.active)
            return oldValue

        if (activeChange)
            setActiveError(false)

        setFields(fields.map(el => el.id === newValue.id ? newValue : activeChange ? {...el, active: false} : el))
        return newValue
    }

    const CustomToolbar = (props) => {
        const apiRef = useGridApiContext();

        return (
            <GridToolbarContainer {...props}>
                <Button startIcon={<AddIcon/>}
                        onClick={_ => {
                            setNameError(false)
                            setActiveError(false)
                            setDateError(false)
                            setFields([...fields.map(el => {
                                return {...el, active: false}
                            }), {id: fields.length, active: true}])
                        }}>Add New Row</Button>
                <Button onClick={_ => {
                    setNameError(false)
                    setActiveError(false)
                    setDateError(false)
                    const t = fields.filter(el => !apiRef.current.getSelectedRows().has(el.id))
                    setFields(t.map((el, i) => {
                        return {...el, id: i}
                    }))
                    apiRef.current.setRowSelectionModel([])
                }} startIcon={<RemoveIcon/>}>Remove Rows</Button>
            </GridToolbarContainer>
        )
    }
    const columns = [
        {
            field: 'name', headerName: 'Name', editable: true, minWidth: 150, flex: 1, cellClassName: (params) => {
                if (!nameError) {
                    return ''
                }
                if (!params.value || fields.filter(el => el.name === params.value).length > 1) {
                    return 'erroneous-cell'
                }
            }
        },
        {
            field: 'start', headerName: 'Start Date', editable: true, minWidth: 150, flex: 1, type: 'date',
            cellClassName: (params) => {
                if (!dateError) {
                    return ''
                }

                if (params.value > params.row.end) {
                    return 'erroneous-cell'
                }
            }
        },
        {
            field: 'end',
            headerName: 'Projected End Date',
            editable: true,
            minWidth: 150,
            flex: 1,
            type: 'date',
            cellClassName: (params) => {
                if (!dateError) {
                    return ''
                }

                if (params.value < params.row.start || params.value < dayjs()) {
                    return 'erroneous-cell'
                }
            }
        },
        {
            field: 'active',
            headerName: 'Active',
            editable: true,
            width: 70,
            type: 'boolean',
            cellClassName: (params) => {
                if (!activeError) {
                    return ''
                }
                return 'erroneous-cell'
            }
        }
    ]

    return (
        <div style={{padding: '0 10% 1% 5%'}}>
            {nameError &&
                <Typography variant={'h5'} sx={{color: '#b71c1c'}}>All names must be unique and not null!</Typography>}
            {dateError &&
                <Typography variant={'h5'} sx={{color: '#b71c1c'}}>{dateErrorMessage}</Typography>}
            {activeError &&
                <Typography variant={'h5'} sx={{color: '#b71c1c'}}>Exactly one migration cycle must be chosen as
                    active</Typography>}

            <DataGrid rows={fields} columns={columns} checkboxSelection slots={{toolbar: CustomToolbar}} hideFooter
                      disableRowSelectionOnClick processRowUpdate={processRowUpdate} sx={{height: '65vh', border: 0}}/>

            <div className='main-page-btns'>
                <Button className='help-button' onClick={_ => setHelpModalOpen(true)}>help</Button>
                <Button className='main-page-btn close-button' onClick={handleBack}>Back</Button>
                <Button className='main-page-btn complete-button' onClick={_ => {
                    let nameErr = false
                    let activeErr = false
                    let dateErr = false
                    if (fields.length > 0) {
                        if (fields.some(el => !el.name) || new Set(fields.map(el => el.name)).size !== fields.length) {
                            nameErr = true
                            setNameError(true)
                        }
                        if (!fields.some(el => el.active)) {
                            activeErr = true
                            setActiveError(true)
                        }
                        if (fields.some(el => el.start >= el.end)) {
                            setDateErrorMessage('All start dates must less then or equal to end dates.')
                            dateErr = true
                            setDateError(true)
                        }
                        if (fields.some(el =>  el.end < dayjs())){
                            setDateErrorMessage('Projected End Date of the project should be a date in the future.')
                            dateErr = true
                            setDateError(true)
                        }
                    }

                    if (!nameErr && !activeErr && !dateErr) {
                        const migrationCycles = fields.map(el => {
                            return {
                                ...el,
                                recid: null,
                                mc_name: el.name,
                                migration_start_date: el.start ? dayjs(el.start).format('YYYY-MM-DD') : null,
                                migration_end_date: el.end ? dayjs(el.end).format('YYYY-MM-DD') : null,
                                is_active: el.active
                            }
                        })

                        setData({
                            ...data,
                            migrationCycles
                        })
                        handleComplete()
                    }
                }
                }>Next</Button>
            </div>

        </div>
    )
}

export default DefineMigrationCycle;