import './MappingView.css';
import Header from "../../helpers/header/Header";
import tooltip from '../ManageObjects/assets/tooltip.png';

import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {
    Box,
    Checkbox,
    Stepper,
    Step,
    Select,
    MenuItem,
    IconButton,
    StepButton,
    Button,
    Typography,
    FormControl,
    ListItemText,
    Menu,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField

} from '@mui/material';

import WarningIcon from '@mui/icons-material/Warning';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import 'react-quill/dist/quill.snow.css';
import '../../helpers/Editor/editor.css';
import CloseIcon from '@mui/icons-material/Close';
import arrowRight from './assets/arrow-right.png'
import arrowLeft from './assets/arrow-left.png';
import asterisk from './assets/asterisk.png';

import MainModal from "../../helpers/Modal/MainModal";
import ManageNotesModal from "../ManageObjects/NotesModal/ManageNotesModal";
import ViewChangeLogModal from "../ManageObjects/ViewChangeLog/ViewChangeLogModal";
import ManageFollowUpsModal from "../ManageObjects/ManageFollowUpsModal/ManageFollowUpsModal";
import DesignMigrationRequirements from "./DesignMigrationRequirements/DesignMigrationRequirements";
import MarkTargetFields from "./MarkTargetFields/MarkTargetFields";
import MassEditFields from "./MassEditFields/MassEditFields";
import AutoFillMappings from "./AutoFillMappings/AutoFillMappings";
import FilterSortCriteria from "./FilterSortCriteria/FilterSortCriteria";
import ChangeLoadTemplateModal from "../ManageObjects/ChangeLoadTemplateModal/ChangeLoadTemplateModal";
import MapSourceFieldsToTargetFields from "./MapSourceFieldsToTargetFields/MapSourceFieldsToTargetFields";


const steps = [
    'Design Migration Requirements',
    'Mark Target Fields as Required',
    'Map Source Fields to Target Fields',
    'Edit Field-Level Transformation Rules',
    'Review Transformation Pipeline'
];


const templateObjects = [
    {
        id: 1,
        name: 'Only Migrate Sold-To Customers',
        templateObject: 'Customers',
        lastPostDate: 'timestamp',
        notes: [
            {
                id: 1,
                author: 'ATOM SMT',
                date: '20.11.1998',
                dateEdited: false,
                autoGenerate: true,
                editedBy: 'Job Champagne',
                text: 'General Migration Notes'
            },
            {
                id: 2,
                author: 'ATOM SMT',
                date: '20.11.1998',
                dateEdited: true,
                autoGenerate: true,
                editedBy: 'Colin Bryan',
                text: 'Extraction Approach'
            },
            {
                id: 3,
                author: 'Job Champagne',
                date: '20.11.1998',
                dateEdited: true,
                autoGenerate: false,
                editedBy: 'Colin Bryan',
                text: 'Transformation Approach'
            },
            {
                id: 4,
                author: 'Job Champagne',
                date: '20.11.1998',
                dateEdited: true,
                autoGenerate: false,
                editedBy: 'Colin Bryan',
                text: 'Load Approach'
            },
        ]
    },

]

const STO = [
    {
        name: 'Basic Data',
        required: true,
    },
    {
        name: 'Plant Data',
        required: false,
    },
    {
        name: 'Storage Location Data',
        required: false
    },
    {
        name: 'Interval of available Capacity',
        required: false,
    }
]

const rowsDataValues = [
    {
        id: 1,
        functionalName: 'Material Number',
        technicalName: 'MATNR',
        required: 'Yes',
        links: '',
        key: true,
        settings: true,
    },
    {
        id: 2,
        functionalName: 'Material Group',
        technicalName: 'MATKL',
        required: 'Yes',
        links: '',
        key: false,
        settings: false
    },
    {
        id: 3,
        functionalName: 'qwe',
        technicalName: 'asd',
        required: 'Yes',
        links: '',
        key: false,
        settings: false
    },
    {
        id: 4,
        functionalName: 'Purchasing Unit of Measure',
        technicalName: 'BSTME',
        required: '-',
        links: '',
        key: false,
        settings: false
    },
]

const MappingView = () => {
    const {projectId} = useParams();
    const [selectedObject, setSelectedObject] = useState(templateObjects[0]);
    const [notes, setNotes] = useState(selectedObject.notes)
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({});
    const [subject, setSubject] = useState('Material');
    const [subSubject, setSubSubject] = useState('Material');
    const [editNote, setEditNote] = useState(null);
    const [openInstructions, setOpenInstructions] = useState(false);
    const [openTechInfo, setOpenTechInfo] = useState(false);
    const [showNotes, setShowNotes] = useState(false);
    const [showFollowUps, setShowFollowUps] = useState(false);
    const [viewChangeLogs, setViewChangeLogs] = useState(false);
    const [warningModalOpen, setWarningModalOpen] = useState(false);
    const [changeTemplateModalOpen, setChangeTemplateModal] = useState(false);
    const [selectedItems, setSelectedItems] = useState('Basic Data');
    const [menuOpen, setMenuOpen] = useState(false);
    const [massEditModal, setMassEditModal] = useState(false);
    const [autoFillMappingsModal, setAutoFillMappingsModal] = useState(false);
    const [filterSortCriteriaModalOpen, setFilterSortCriteriaModalOpen] = useState(false);
    const [checked, setChecked] = useState([]);
    const [sto, setSto] = useState(STO);
    const [isRowSelected, setIsRowSelected] = useState([]);
    const [rowsData, setRowsData] = useState(rowsDataValues)

    const [markTargetFieldsSelectedRows, setMarkTargetFieldsSelectedRows] = useState([])

    const selectRef = useRef(null);

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setEditNote(false);
        setActiveStep(step);
    };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };

    const handleChange = (event) => {
        setSubject(event.target.value);
    };

    const StepperMenu = () => {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                backgroundColor: 'lightgray',
                padding: '1.5rem 2rem 1rem 2rem',
                justifyContent: 'space-between',
                borderBottom: '1px solid black'
            }}>
                <IconButton
                    color="primary"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                >
                    <ArrowCircleLeftIcon sx={{fontSize: 60}}/>
                </IconButton>

                <Box sx={{width: '90%'}}>
                    <Stepper alternativeLabel nonLinear activeStep={activeStep}>
                        {steps.map((label, index) => (
                            <Step key={label} completed={completed[index]}>
                                <StepButton color="inherit" onClick={handleStep(index)}>
                                    {label}
                                </StepButton>
                                <span
                                    style={{display: 'flex', justifyContent: 'center', fontSize: 13}}>0% Complete</span>
                            </Step>
                        ))}
                    </Stepper>
                </Box>


                <IconButton
                    color="primary"
                    onClick={handleNext}
                >
                    <ArrowCircleRightIcon sx={{fontSize: 60}}/>
                </IconButton>
            </div>
        )
    }

    const InstructionModal = () => {

        const BootstrapDialogTitle = (props) => {
            const {children, onClose, ...other} = props;

            return (
                <DialogTitle sx={{m: 0, p: 2, paddingBottom: 0}} {...other}>
                    {children}
                    {onClose ? (
                        <IconButton
                            aria-label="close"
                            onClick={onClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon/>
                        </IconButton>
                    ) : null}
                </DialogTitle>
            );
        }

        const handleClose = () => {
            setOpenInstructions(false)
        }

        return (
            <Dialog
                // onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                open={openInstructions}
                scroll="paper"
                PaperProps={{
                    style: {
                        display: 'flex',
                        marginBottom: '22vh',
                        marginRight: 'auto',
                        marginLeft: '4rem'
                    },
                }}
                onClose={handleClose}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Instructions
                    <Typography gutterBottom>
                        How to use this view
                    </Typography>
                </BootstrapDialogTitle>
                <DialogContent dividers>

                    <Typography gutterBottom>
                        ATOM Smart Mapping Tool has taken the fields from the template object and placed them in this
                        view. The user should review all these fields and mark them as required for migration. The
                        options are:
                    </Typography>

                    <Typography gutterBottom>
                        1. Yes - The field is required to be populated for data migration
                    </Typography>


                    <Typography gutterBottom>
                        2. No - The field is not needed for data migration
                    </Typography>

                    <Typography gutterBottom>
                        3. TBD - More research is needed. Please create a follow-up as well.
                    </Typography>

                    <Typography gutterBottom>
                        4. System Generated - This field does not need to be populated during data transformation as the
                        system will populate the data in this field upon load to the database.
                    </Typography>

                </DialogContent>

            </Dialog>
        )
    }

    const TechInfoModal = () => {

        const handleClose = () => {
            setOpenTechInfo(false)
        }

        const BootstrapDialogTitle = (props) => {
            const {children, onClose, ...other} = props;

            return (
                <header style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignContent: 'center',
                    justifyContent: 'space-between'
                }}>
                    <div style={{display: 'flex', gap: '0.3rem', flexDirection: 'column', padding: '1rem'}}>
                        <h2 style={{margin: 0}}>Technical Information</h2>
                        <span>Information for Developers</span>

                    </div>
                    <div style={{display: 'flex', alignItems: 'center', gap: '1rem', flexDirection: 'row'}}>
                        <Button
                            onClick={handleClose}
                            variant='contained'>Save & Close
                        </Button>
                        <IconButton onClick={handleClose}>
                            <CloseIcon/>
                        </IconButton>
                    </div>

                </header>
            );
        }


        return (
            <Dialog
                // onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                open={openTechInfo}
                scroll="paper"
                PaperProps={{
                    style: {
                        width: '30vw',
                        display: 'flex',
                        marginBottom: '15vh',
                        marginRight: 'auto',
                        marginLeft: '4rem'
                    },
                }}
                onClose={handleClose}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Technical Information
                    <Typography gutterBottom>
                        Information for Developers
                    </Typography>

                </BootstrapDialogTitle>

                <DialogContent dividers>

                    <TextField sx={{marginBottom: '1rem'}} fullWidth id="standard-basic" label="Target System:"
                               variant="standard"/>
                    <TextField sx={{marginBottom: '1rem'}} fullWidth id="standard-basic"
                               label="Template Object Description:" variant="standard"/>
                    <TextField sx={{marginBottom: '1rem'}} fullWidth id="standard-basic"
                               label="Sub-Template Object Description:" variant="standard"/>
                    <TextField sx={{marginBottom: '1rem'}} fullWidth id="standard-basic" label="Target Structure Name:"
                               variant="standard"/>


                </DialogContent>

            </Dialog>
        )
    }

    const handleMenuOpen = (event) => {
        setMenuOpen(true);
    };

    const handleMenuClose = (event) => {
        setMenuOpen(null);

    };

    const handleSelectChange = (event) => {
        // console.log(event.target.value)
        // if (event.target.value === 'selectAll') {
        //     setSto((prev) => prev.map(entity => {
        //         return {
        //             ...entity,
        //             required: true
        //         }
        //     }))
        // } else if (event.target.value === 'unselectAll') {
        //     setSto((prev) => prev.map(entity => {
        //         return {
        //             ...entity,
        //             required: false
        //         }
        //     }))
        // } else {
        //     console.log('tut')
        //     setSelectedItems(event.target.value);
        //     setMenuOpen(false);
        // }
        setSelectedItems(event.target.value);
    };

    const handleCheckboxClick = (value) => (event) => {
        event.stopPropagation();

        setSelectedItems(value);
        // if (selectedItems.includes(value)) {
        //     setSelectedItems(selectedItems?.filter(item => item !== value));
        // } else {
        //     setSelectedItems(value);
        // }
    };

    const removeRow = () => {
        setRowsData(prev => prev.filter(el => !markTargetFieldsSelectedRows.includes(el.id)))
    }

    const addRow = () => {
        setRowsData(prev => [
            ...prev,
            {
              id: prev.length + 1,
              functionalName: '-',
              technicalName: '-',
              required: '-',
              links: '',
              key: false,
              settings: false
            }
        ]);
        console.log(rowsData)
    }



    return (
        <div>
            <Header activePage={'View Mapping'} projectId={projectId}/>
            <StepperMenu/>
            <div style={{display: 'flex', flexDirection: 'column', padding: '0 4rem', marginTop: '2rem'}}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '1rem',
                    flexWrap: 'wrap'
                }}>
                    <div style={{display: 'flex', flexDirection: 'row', gap: '0.5rem', alignItems: 'center'}}>
                        <FormControl sx={{minWidth: 120}}>
                            <Select
                                sx={{
                                    boxShadow: 'none',
                                    '.MuiOutlinedInput-notchedOutline': {border: 0},
                                    backgroundColor: '#2196F30A'
                                }}
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                value={subject}
                                onChange={handleChange}
                            >
                                <MenuItem value={'Material'}>Material</MenuItem>
                                <MenuItem value={'Test'}>Test</MenuItem>
                                <MenuItem value={'Test2'}>Test2</MenuItem>
                            </Select>
                        </FormControl>

                        <span> / </span>

                        <FormControl sx={{minWidth: 120}}>
                            <Select
                                sx={{
                                    boxShadow: 'none',
                                    '.MuiOutlinedInput-notchedOutline': {border: 0},
                                    backgroundColor: '#2196F30A'
                                }}
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                value={subject}
                                onChange={handleChange}
                            >
                                <MenuItem value={'Material'}>Material</MenuItem>
                                <MenuItem value={'Test'}>Test</MenuItem>
                                <MenuItem value={'Test2'}>Test2</MenuItem>
                            </Select>
                        </FormControl>

                        {
                            activeStep !== 0 &&
                            <>
                                <span> / </span>

                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1rem'}}>
                                    <img
                                        onClick={_ => {
                                            const currentIndex = sto.findIndex(item => item.name === selectedItems);
                                            if (currentIndex !== 0) {
                                                for (let i = currentIndex - 1; i < sto.length; i--) {
                                                    if (sto[i].required) {
                                                        setSelectedItems(sto[i].name)
                                                    }
                                                    break;
                                                }
                                            }
                                        }}
                                        className='mapping-view-option-arrow'
                                        src={arrowLeft}>

                                    </img>

                                    <FormControl ref={selectRef} sx={{minWidth: 100}}>

                                        <Select
                                            labelId="checkbox-select-label"
                                            value={selectedItems}
                                            open={menuOpen}
                                            onClose={handleMenuClose}
                                            onOpen={handleMenuOpen}
                                            onChange={handleSelectChange}
                                            sx={{
                                                boxShadow: 'none',
                                                '.MuiOutlinedInput-notchedOutline': {border: 0},
                                                backgroundColor: '#2196F30A'
                                            }}
                                            renderValue={(selected) => {
                                                return (
                                                    <div style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center'
                                                    }}>

                                                        {selected}
                                                        <div style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            alignItems: 'center'
                                                        }}>
                                                            {
                                                                (sto.find(item => item.name === selectedItems)?.required || false) &&
                                                                <div style={{display: 'flex', alignItems: 'center', gap: '0.3rem'}}>
                                                                    <img style={{width: '10px', height: '10px'}} src={asterisk}></img>
                                                                    <span style={{fontSize: 12}}>Required</span>
                                                                </div>


                                                            }
                                                        </div>

                                                    </div>


                                                )
                                            }}
                                        >
                                            <div style={{display: 'flex', flexDirection: 'column', padding: '0.6rem', borderBottom: '1px solid #e4e4e4'}}>
                                                <span style={{fontWeight: 500}}>Sub-Template Objects</span>
                                                <span style={{ fontSize: 12}}>Checked Sub-Template Objects are Required</span>

                                                <div style={{marginTop: '0.3rem'}}>
                                                <input
                                                        type="checkbox"
                                                        checked={sto.every(el => el.required)}
                                                        onClick={_=> {
                                                            const status = !sto.some(el => el.required)

                                                            setSto((prev) => prev.map(entity => {
                                                                return {
                                                                    ...entity,
                                                                    required: status
                                                                }



                                                            }))
                                                        }}
                                                />
                                                <span style={{marginLeft: '0.3rem'}}>Select All</span>
                                            </div>
                                            </div>



                                            {
                                                sto.map(el => {
                                                    return (
                                                        <MenuItem key={el.name} value={el.name}>
                                                            <div onClick={handleCheckboxClick(el.name)}>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={el.required}
                                                                    onClick={(e) => {
                                                                        setSto((prev) => prev.map(entity => {
                                                                            if (entity.name === el.name) {
                                                                                return {
                                                                                    ...entity,
                                                                                    required: !entity.required
                                                                                }
                                                                            }

                                                                            return entity
                                                                        }))
                                                                        e.stopPropagation()
                                                                    }}
                                                                />
                                                                <span style={{marginLeft: '0.3rem'}}>{el.name}</span>
                                                            </div>
                                                        </MenuItem>
                                                    )

                                                })
                                            }

                                        </Select>
                                    </FormControl>

                                    <img
                                        onClick={_ => {
                                            const currentIndex = sto.findIndex(item => item.name === selectedItems);
                                            if (currentIndex !== -1) {
                                                for (let i = currentIndex + 1; i < sto.length; i++) {
                                                    if (sto[i].required) {
                                                        setSelectedItems(sto[i].name)
                                                    }
                                                    break;
                                                }
                                            }
                                        }}
                                        className='mapping-view-option-arrow'
                                        src={arrowRight}>

                                    </img>

                                </div>

                            </>
                        }

                    </div>

                    <div style={{display: 'flex', flexDirection: 'row', gap: '3rem', alignItems: 'center'}}>

                        {
                            activeStep !== 0 &&
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <div onClick={_ => setFilterSortCriteriaModalOpen(true)}
                                     className='mapping-view-info-container'>
                                    <ErrorOutlineIcon sx={{color: '#0288D1'}}/>
                                    <span>View Filter & Sort Criteria</span>
                                </div>

                                <div onClick={_ => setWarningModalOpen(true)}
                                     className='mapping-view-warning-container'>
                                    <WarningIcon sx={{color: '#ef7917'}}/>
                                    <span>View Warnings (2)</span>
                                </div>

                            </div>
                        }


                        <div style={{display: 'flex', flexDirection: 'row', gap: '1rem'}}>
                            <Button
                                sx={{minWidth: '150px'}}
                                onClick={_ => {
                                    setEditNote(false)
                                    setShowFollowUps(true)
                                }}
                                variant='contained'>Follow-ups
                            </Button>
                            <Button
                                onClick={_ => {
                                    setEditNote(false)
                                    setShowNotes(true)
                                }}
                                variant='contained'>Notes</Button>
                            <Button
                                sx={{minWidth: '150px'}}
                                onClick={_ => {
                                    setEditNote(false)
                                    setViewChangeLogs(true)
                                }}
                                variant='contained'>Change Log
                            </Button>
                        </div>
                    </div>


                </div>

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginTop: '1rem',
                    flexWrap: 'wrap',
                    gap: '1rem'
                }}>
                    <div style={{display: 'flex', flexDirection: 'row', gap: '1rem'}}>
                        <Button
                            onClick={_ => setOpenTechInfo(true)}
                            sx={{backgroundColor: '#f4f4f4', color: 'black', padding: '0.3rem 1rem'}}
                            endIcon={<img style={{width: 20, height: 20}} src={tooltip}></img>}>Technical
                            Information</Button>
                        <Button
                            onClick={_ => setOpenInstructions(true)}
                            sx={{backgroundColor: '#f4f4f4', color: 'black', padding: '0.3rem 1rem'}}
                            endIcon={<img style={{width: 20, height: 20}}
                                          src={tooltip}></img>}>Instructions
                        </Button>
                    </div>

                    {
                        activeStep !== 0 &&
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1rem'}}>
                            <Button sx={{whiteSpace: 'nowrap'}} variant='outlined'>Transfer Mappings</Button>
                            <Button
                                onClick={_ => setChangeTemplateModal(true)}
                                sx={{whiteSpace: 'nowrap'}}
                                variant='outlined'>Change template
                            </Button>
                            <Button
                                onClick={_ => setAutoFillMappingsModal(true)}
                                sx={{whiteSpace: 'nowrap'}}
                                variant='outlined'>Autofill Mappings
                            </Button>
                            <Button
                                disabled={!isRowSelected.length}
                                onClick={_ => setMassEditModal(true)}
                                sx={{whiteSpace: 'nowrap'}}
                                variant='outlined'>Mass EDIT
                            </Button>
                            <Button
                                onClick={addRow}
                                sx={{whiteSpace: 'nowrap'}}
                                variant='outlined'>Add Row
                            </Button>

                            <Button
                                onClick={removeRow}
                                disabled={!isRowSelected.length}
                                sx={{whiteSpace: 'nowrap'}}
                                variant='outlined'>Remove Row
                            </Button>

                        </div>
                    }

                </div>


                {
                    activeStep === 0 &&
                    <DesignMigrationRequirements
                        notes={notes}
                        selectedObject={selectedObject}
                        editNote={editNote}
                        setNotes={setNotes}
                        setEditNote={setEditNote}
                    />
                }

                {
                    activeStep === 1 &&
                    <MarkTargetFields
                        notes={notes}
                        warningModalOpen={warningModalOpen}
                        setWarningModalOpen={setWarningModalOpen}
                        selectedObject={selectedObject}
                        editNote={editNote}
                        setNotes={setNotes}
                        setEditNote={setEditNote}
                        setShowNotes={setShowNotes}
                        setShowFollowUps={setShowFollowUps}
                        setViewChangeLogs={setViewChangeLogs}
                        setIsRowSelected={setIsRowSelected}
                        rowsData={rowsData}
                        setRowsData={setRowsData}
                        setMarkTargetFieldsSelectedRows={setMarkTargetFieldsSelectedRows}

                    />
                }

                {
                    activeStep === 2 &&
                    <MapSourceFieldsToTargetFields
                        notes={notes}
                        warningModalOpen={warningModalOpen}
                        setWarningModalOpen={setWarningModalOpen}
                        selectedObject={selectedObject}
                        editNote={editNote}
                        setNotes={setNotes}
                        setEditNote={setEditNote}
                        setShowNotes={setShowNotes}
                        setShowFollowUps={setShowFollowUps}
                        setViewChangeLogs={setViewChangeLogs}
                        setIsRowSelected={setIsRowSelected}
                    />
                }

            </div>

            <InstructionModal/>
            <TechInfoModal/>

            <MainModal
                tooltip={true}
                modalOpen={showNotes}
                setModalOpen={setShowNotes}
                headerText='Manage Notes'
                Child={ManageNotesModal}/>

            <MainModal
                tooltip={true}
                modalOpen={viewChangeLogs}
                setModalOpen={setViewChangeLogs}
                headerText='View Change Log'
                Child={ViewChangeLogModal}
                selectedTemplate={'Material'}
            />

            <MainModal
                tooltip={true}
                modalOpen={showFollowUps}
                setModalOpen={setShowFollowUps}
                headerText='Manage Follow Ups'
                Child={ManageFollowUpsModal}
            />

            <MainModal
                tooltip={true}
                modalOpen={massEditModal}
                setModalOpen={setMassEditModal}
                headerText='Mass Edit Fields'
                Child={MassEditFields}
            />

            <MainModal
                tooltip={true}
                modalOpen={autoFillMappingsModal}
                setModalOpen={setAutoFillMappingsModal}
                headerText='Autofill Mappings: Required Fields'
                Child={AutoFillMappings}
            />

            <MainModal
                modalOpen={filterSortCriteriaModalOpen}
                setModalOpen={setFilterSortCriteriaModalOpen}
                headerText='View Filter & Sort Criteria'
                Child={FilterSortCriteria}
            />

            <MainModal
                tooltip={true}
                modalOpen={changeTemplateModalOpen}
                setModalOpen={setChangeTemplateModal}
                headerText='Change Load Template'
                Child={ChangeLoadTemplateModal}/>

        </div>
    )
}

export default MappingView;