import './AssignDatasetModal.css';
import {Select, InputLabel, MenuItem, FormControl, Button} from "@mui/material";
import {useState} from "react";

import close from '../assets/close.png';
import checked from '../assets/checked.png';


const AssingDatasetModal = ({selectedRows, setSelectedRows, rowSelectionModel ,setRowSelectionModel}) => {

    const [age, setAge] = useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    }

    const removeRow = (row) => {
        setSelectedRows(selectedRows.filter(el => row.sourceDatasetName !== el.sourceDatasetName))
        setRowSelectionModel(rowSelectionModel.filter(el => row.id !== el))
    }

    return (
        <div className='assign-dataset-container'>
            {
                selectedRows.map(row => {
                    return (
                        <div className='assign-dataset-row-container'>
                            <div className='assign-dataset-row'>
                                <img
                                    alt='img'
                                    className='assign-dataset-close-img'
                                    onClick={_ => removeRow(row)}
                                    src={close}>
                                </img>
                                <img alt='img' className='assign-dataset-img' src={checked}></img>
                                <p className='assign-dataset-row-lbl'>{row.sourceDatasetName}</p>
                            </div>

                            <FormControl className='assign-dataset-select-container'>
                                <InputLabel id="demo-simple-select-label">Dataset Name</InputLabel>
                                <Select
                                    variant="standard"
                                    labelId="assign-select-label"
                                    id="assign-select"
                                    value={age}
                                    onChange={handleChange}
                                >
                                  <MenuItem value={10}>test</MenuItem>
                                  <MenuItem value={20}>test</MenuItem>
                                  <MenuItem value={30}>test</MenuItem>
                                </Select>
                              </FormControl>
                        </div>
                    )
                })
            }

            <Button variant="contained" className='assign-dataset-btn'>Assign</Button>

        </div>
    )
}

export default AssingDatasetModal;