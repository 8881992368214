import {
    DataGrid,
    GridToolbarColumnsButton,
    GridToolbarContainer, GridToolbarDensitySelector,
    GridToolbarFilterButton,
    useGridApiContext
} from "@mui/x-data-grid";
import {Autocomplete, Button, CircularProgress, TextField, Tooltip} from "@mui/material";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import {useCallback, useEffect, useState} from "react";
import {getAllDataObjects, getArchivedObjects, updateTOAllocation} from "../../../api/requests";
import {Download, Save} from "@mui/icons-material";
import {download} from "../../../api/requests";
import LoadingButton from "@mui/lab/LoadingButton";

const CustomAutocomplete = (props) => {
    const {id, value, api, field} = props;
    const [val, setVal] = useState({do_name: value})
    const handleChange = useCallback(
        (event, value) => {
            setVal(value)
            api.setEditCellValue({id, field, value: value?.do_name})
            api.updateRows([{id, data_object_id: value?.data_object_id}])
        },
        [api, field, id]
    );
    const dataObjects = props.colDef.dataObjects
    return <Autocomplete value={val || null} renderInput={(params) =>
        <TextField {...params} variant='standard' InputProps={{
            ...params.InputProps,
            disableUnderline: true
        }}/>} options={dataObjects} fullWidth onChange={handleChange} getOptionLabel={option => option.do_name}/>
}
const RenderAutocomplete = (params) => {
    return <CustomAutocomplete {...params}/>
}


const RenderDownload = ({cellValues, projectId}) => {
    const [loading, setLoading] = useState(false)
    return cellValues.formattedValue &&
        <div
            style={{display: "flex", width: '100%', alignItems: 'center', justifyContent: 'space-between'}}>
            <p style={{whiteSpace: 'break-spaces'}}>{cellValues.formattedValue}</p>
            <LoadingButton loading={loading} onClick={_ => {
                setLoading(true)
                download(cellValues.row.load_template_fileurl, projectId, localStorage.getItem('token')).then(r => {
                    const url = window.URL.createObjectURL(new Blob([r.data.download_url]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${cellValues.formattedValue}`);
                    document.body.appendChild(link);
                    link.click();
                    setLoading(false)
                }).catch(_ => setLoading(false))
            }}>
                <Download/>
            </LoadingButton>
        </div>
}

const AssignTemplates = ({projectId, mcId, getObjects}) => {
    const [templateObjects, setTemplateObjects] = useState([])
    const [dataObjects, setDataObjects] = useState([])
    const [loading, setLoading] = useState(false)
    const [buttonLoading, setButtonLoading] = useState(false)
    const [unassigned, setUnassigned] = useState(0)

    const updateTable = () => {
        setLoading(true)
        const token = localStorage.getItem('token')
        let unassigned = 0
        getArchivedObjects(projectId, mcId, token).then(r => setTemplateObjects(r.data.unarchived.map((el, i) => {
            !el.do_name && unassigned++
            return {id: i, ...el}
        }))).then(_ => setUnassigned(unassigned))

        getAllDataObjects(projectId, mcId, token).then(r => {
            setDataObjects(r.data.data_objects)
            setLoading(false)
        })
    }

    const CustomToolbar = (props) => {
        const apiRef = useGridApiContext();

        return (
            <GridToolbarContainer {...props}>
                <GridToolbarColumnsButton/>
                <GridToolbarFilterButton/>
                <GridToolbarDensitySelector/>
                <LoadingButton loading={buttonLoading} startIcon={<Save/>}
                               onClick={_ => {
                                   setButtonLoading(true)
                                   const data = Array.from(apiRef.current.getRowModels(), ([name, value]) => ({...value}))
                                   updateTOAllocation(projectId, data.map(el => ({
                                       recid: el.template_object_id,
                                       data_object_id: el.data_object_id
                                   })), localStorage.getItem('token')).then(_ => {
                                           updateTable()
                                           getObjects()
                                           setButtonLoading(false)
                                       }
                                   ).catch(_ => setButtonLoading(false))
                               }}>
                    <span>Save</span>
                </LoadingButton>
            </GridToolbarContainer>)
    }

    useEffect(() => {
        updateTable()
    }, []);
    const columns = [
        {field: 'to_name', headerName: 'Template Object name', editable: false, minWidth: 150, flex: 1},
        {
            field: 'load_template_filename',
            headerName: 'Load Template File Name',
            editable: false,
            minWidth: 150,
            flex: 1,
            renderCell: (cellValues) => {
                return <RenderDownload cellValues={cellValues} projectId={projectId}/>

            }
        },
        {field: 'target_system', headerName: 'Target System', editable: false, minWidth: 150, flex: 1},
        {
            field: 'do_name',
            headerName: 'Data Object',
            editable: true,
            minWidth: 150,
            flex: 1,
            dataObjects: dataObjects,
            renderEditCell: RenderAutocomplete,
        },
    ]
    return (
        <>
            <h1>Assign Templates to Data Objects <Tooltip
                title={<h2>Each template object must be assigned to an existing data object in this view. Unassigned
                    template objects will not be viewable in the object level management view and cannot be worked on in
                    the mapping view.</h2>}><QuestionMarkIcon/></Tooltip>
            </h1>
            <h4>Template Objects (Unassigned: {unassigned})</h4>
            {loading ? <div style={{display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
                    <CircularProgress size={'3rem'}/></div> :
                <DataGrid rowHeight={60} columns={columns} rows={templateObjects} hideFooter className='datagrid'
                          sx={{width: 1300}} slots={{toolbar: CustomToolbar}}/>}

        </>

    )
}

export default AssignTemplates