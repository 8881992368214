import {
    Box,
    Button,
    Checkbox,
    Paper,
    Tab,
    Table, TableBody,
    TableCell, TableContainer,
    TableHead,
    TableRow,
    Tabs,
    Typography,
    FormControl, InputLabel,
    Select, MenuItem
} from "@mui/material";
import extend from "../../ManageObjects/assets/extend.png";
import './AutoFillMappings.css';
import warning from "../../ManageObjects/assets/warning.png";
import React, {useState} from "react";
import key from "../assets/key.png";
import search from "../../ManageObjects/assets/search2.png";
import SettingsIcon from "@mui/icons-material/Settings";
import {DataGrid} from "@mui/x-data-grid";


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const rowsData = [
        {
            id: 1,
            functionalName: 'Material Number',
            technicalName: 'MATNR',
            required: 'Yes',
            suggestRequired: 'No',
            key: true
        },
        {
            id: 2,
            functionalName: 'Material Group',
            technicalName: 'MATKL',
            required: 'Yes',
            suggestRequired: 'Yes',
        },
        {
            id: 3,
            functionalName: 'qwe',
            technicalName: 'asd',
            required: 'Yes',
            suggestRequired: 'Yes',
        },
        {   id: 4,
            functionalName: 'Purchasing Unit of Measure',
            technicalName: 'BSTME',
            required: '-',
            suggestRequired: 'Yes',
        },
    ]

    // const columns = [
    //     {
    //         id: 'functionalName',
    //         label: 'Functional Name',
    //         minWidth: 170,
    //         align: 'left'
    //     },
    //     {
    //         id: 'technicalName',
    //         label: 'Technical Name',
    //         minWidth: 150,
    //         align: 'center'
    //     },
    //     {
    //         id: 'required',
    //         label: 'Required?',
    //         minWidth: 270,
    //         align: 'center',
    //     },
    //     {
    //         id: 'suggestRequired',
    //         label: 'Required?',
    //         minWidth: 50,
    //         align: 'center',
    //     },
    // ];


const AutoFillMappings = () => {
    const [value, setValue] = useState(0);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [selectedRows, setSelectedRows] = useState(null);
    const [extendTabs, setExtendTabs] = useState(false);
    const [required, setRequired] = useState();
    const [rows, setRows] = useState(rowsData)


    const columns = [
        {
            field: 'checkbox',
            headerName: '',
            width: 100,
        },
        {
            field: 'functionalName',
            headerName: 'Functional Name',
            width: 350,
            editable: true,
        },
        {
            field: 'technicalName',
            headerName: 'Technical Name',
            width: 350,
            editable: true,
            renderCell: (params) => {
                return (
                    <div style={{display: 'flex'}}>
                        <span style={{width: '3.2rem'}}>{params.row.technicalName}</span>

                        {
                            params.row.key &&
                                <img style={{width: 15, height: 15, marginLeft: '1rem', marginBottom: '-0.2rem'}} src={key}></img>
                        }

                    </div>
                )
            }
        },
        {
            field: 'required',
            headerName: 'Required?',
            width: 350,
            editable: true,
            renderCell: (params) => {
                return (
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <span>{params.row.required}</span>

                        <SettingsIcon sx={{marginBottom: '-0.4rem', marginLeft: '0.5rem'}}/>


                    </div>
                )
            }
        },
        {
            field: 'suggestedRequired',
            headerName: 'Required?',
            width: 450,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return (
                    <div style={ params.row.suggestRequired !== params.row.required ? { backgroundColor: '#FAFF0066'} : {display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <Select
                              sx={{
                                boxShadow: 'none',
                                '.MuiOutlinedInput-notchedOutline': {border: 0},
                              }}
                              // labelId="demo-simple-select-standard-label"
                              variant='outlined'
                              id="demo-simple-select-standard"
                              value={params.row.suggestRequired}
                              onChange={e => {
                                  setRows(prev => prev.map(el => {
                                      if (el === params.row) {
                                          return {...el, suggestRequired: e.target.value}
                                      }

                                      return el
                                  }))
                              }}
                            >
                              <MenuItem value={'Yes'}>Yes</MenuItem>
                              <MenuItem value={'No'}>No</MenuItem>
                              <MenuItem value={'TBD'}>TBD</MenuItem>
                              <MenuItem value={'System Generated'}>System Generated</MenuItem>
                              <MenuItem value={'-'}>-</MenuItem>
                            </Select>
                        </FormControl>

                        {
                            params.row.suggestRequired === params.row.required &&
                                <SettingsIcon sx={{marginBottom: '-0.4rem', marginLeft: '0.5rem'}}/>
                        }
                    </div>
                )

            },
        },

    ]

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    return (
        <div className='autofill-mapping-main'>

            <Box sx={{ width: '100%' }}>
                <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1rem', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1rem'}}>
                        <Tabs visibleScrollbar variant='scrollable' sx={ extendTabs? {width: 500} : {width: 400}} value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Basic Data" />
                            <Tab label="Plant Data" />
                            <Tab label="Storage Location" />
                        </Tabs>

                        <img onClick={ _ => setExtendTabs(prevState => !prevState)} style={{width: '40px', height: '30px', cursor: 'pointer'}} src={extend}></img>
                    </div>

                    <Button className='autofill-mapping-update-btn' variant='contained'>Update Mappings</Button>

                </Box>

                <h3 style={{marginBottom: '0.3rem'}}>Instructions:</h3>
                <span>Review and edit the fields that are suggested to be required for data migration. When finished, click the “UPDATE MAPPINGS” button to commit changes. </span>

                <CustomTabPanel value={value} index={0}>

                    {/*<div style={{width: '100%', display: 'flex', minWidth: 1200, height: '4rem'}}>*/}
                    {/*    <span style={{color:'white', fontSize: 30, width: '65%', height: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center', backgroundColor: '#1D4956'}}>Current Mapping State:</span>*/}
                    {/*    <span style={{color:'white', fontSize: 30, width: '35%', height: '100%', alignItems: 'center', minWidth: 600, display: 'flex', justifyContent: 'center', backgroundColor: '#613FEBFC'}}>Suggested Mapping State:</span>*/}
                    {/*</div>*/}

                    <DataGrid
                    sx={{minWidth: 1200}}
                    checkboxSelection
                    rows={rows}
                    columns={columns}
                    columnGroupingModel={[
                        {
                          headerClassName: 'column-blue',
                          headerAlign: 'center',
                          groupId: 'Current Mapping State:',
                          children: [{ field: 'checkbox' }, { field: 'functionalName'}, { field: 'technicalName'}, { field: 'required'}],
                        },
                        {
                          groupId: 'Suggested Mapping State:',
                          headerClassName: 'column-orange',
                          headerAlign: 'center',
                          children: [{ field: 'suggestedRequired' }],
                        },
                    ]}
                    experimentalFeatures={{ columnGrouping: true }}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 5,
                            },
                        },
                    }}
                    pageSizeOptions={[5]}
                    disableRowSelectionOnClick
                    selection
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setRowSelectionModel(newRowSelectionModel);
                    }}
                    rowSelectionModel={rowSelectionModel}
                />

                    {/*<TableContainer sx={{width: '100%'}} component={Paper}>*/}
                    {/*    <Table  sx={{minWidth: 650}} aria-label="simple table">*/}
                    {/*        <TableHead sx={{fontSize: 800}}>*/}
                    {/*            <TableRow>*/}
                    {/*                <TableCell sx={{backgroundColor: '#1D4956', color: 'white', fontSize: 30}} align="center" colSpan={4}>*/}
                    {/*                    Current Mapping State:*/}
                    {/*                </TableCell>*/}
                    {/*                <TableCell sx={{backgroundColor: '#EF7D0B', color: 'white', fontSize: 30}} align="center" colSpan={1}>*/}
                    {/*                    Suggested Mapping State:*/}
                    {/*                </TableCell>*/}
                    {/*            </TableRow>*/}

                    {/*            <TableRow sx={{backgroundColor: '#CCCCCC80'}}>*/}

                    {/*                <TableCell padding="checkbox">*/}
                    {/*                  <Checkbox*/}
                    {/*                    color="primary"*/}
                    {/*                    // checked={isItemSelected}*/}

                    {/*                  />*/}
                    {/*                </TableCell>*/}

                    {/*                {columns.map((column) => (*/}
                    {/*                    <TableCell*/}
                    {/*                        key={column.id}*/}
                    {/*                        align={column.align}*/}
                    {/*                        style={{top: 57, minWidth: column.minWidth}}*/}
                    {/*                    >*/}
                    {/*                        {column.label}*/}
                    {/*                    </TableCell>*/}
                    {/*                ))}*/}
                    {/*            </TableRow>*/}
                    {/*        </TableHead>*/}
                    {/*        <TableBody>*/}

                    {/*            {rows.map((row) => (*/}
                    {/*                <TableRow*/}
                    {/*                    key={row.name}*/}
                    {/*                    sx={{'&:last-child td, &:last-child th': {border: 0}}}>*/}

                    {/*                    <TableCell padding="checkbox">*/}
                    {/*                      <Checkbox*/}
                    {/*                        color="primary"*/}
                    {/*                        // checked={isItemSelected}*/}

                    {/*                      />*/}
                    {/*                    </TableCell>*/}

                    {/*                    <TableCell component="th" scope="row">*/}
                    {/*                        {row.functionalName}*/}
                    {/*                    </TableCell>*/}

                    {/*                    <TableCell align="center">*/}
                    {/*                        <span>{row.technicalName}</span>*/}
                    {/*                        {*/}
                    {/*                            row.key &&*/}
                    {/*                                <img style={{width: 15, height: 15, marginLeft: '1rem', marginBottom: '-0.2rem'}} src={key}></img>*/}
                    {/*                        }*/}

                    {/*                    </TableCell>*/}

                    {/*                    <TableCell align="center">*/}
                    {/*                        <span>{row.required}</span>*/}

                    {/*                        <SettingsIcon sx={{marginBottom: '-0.4rem', marginLeft: '0.5rem'}}/>*/}
                    {/*                    </TableCell>*/}

                    {/*                    <TableCell sx={ row.suggestRequired !== row.required ? { backgroundColor: '#FAFF0066'} : {display: 'flex', alignItems: 'center', justifyContent: 'center'}} align="center">*/}
                    {/*                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>*/}
                    {/*                            <Select*/}
                    {/*                              sx={{*/}
                    {/*                                boxShadow: 'none',*/}
                    {/*                                '.MuiOutlinedInput-notchedOutline': {border: 0},*/}
                    {/*                              }}*/}
                    {/*                              // labelId="demo-simple-select-standard-label"*/}
                    {/*                              variant='standard'*/}
                    {/*                              id="demo-simple-select-standard"*/}
                    {/*                              value={row.suggestRequired}*/}
                    {/*                              onChange={e => {*/}
                    {/*                                  setRows(prev => prev.map(el => {*/}
                    {/*                                      if (el === row) {*/}
                    {/*                                          return {...el, suggestRequired: e.target.value}*/}
                    {/*                                      }*/}

                    {/*                                      return el*/}
                    {/*                                  }))*/}
                    {/*                              }}*/}
                    {/*                            >*/}
                    {/*                              <MenuItem value={'Yes'}>Yes</MenuItem>*/}
                    {/*                              <MenuItem value={'No'}>No</MenuItem>*/}
                    {/*                              <MenuItem value={'TBD'}>TBD</MenuItem>*/}
                    {/*                              <MenuItem value={'System Generated'}>System Generated</MenuItem>*/}
                    {/*                              <MenuItem value={'-'}>-</MenuItem>*/}
                    {/*                            </Select>*/}
                    {/*                        </FormControl>*/}

                    {/*                        {*/}
                    {/*                            row.suggestRequired === row.required &&*/}
                    {/*                                <SettingsIcon sx={{marginBottom: '-0.4rem', marginLeft: '0.5rem'}}/>*/}
                    {/*                        }*/}

                    {/*                    </TableCell>*/}
                    {/*                </TableRow>*/}
                    {/*            ))}*/}
                    {/*        </TableBody>*/}
                    {/*    </Table>*/}
                    {/*</TableContainer>*/}


                </CustomTabPanel>

                <CustomTabPanel value={value} index={1}>
                    Plant Data
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <div style={{padding: '2rem', display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '3rem', alignItems: 'center', backgroundColor: '#FDEDED', width: '40%', margin: '3rem auto'}}>
                        <img style={{width: '60px'}} src={warning}></img>
                        <span style={{fontSize: 36}}>Sheet Deleted From Load Template</span>
                    </div>
                </CustomTabPanel>

            </Box>
        </div>
    )
}

export default AutoFillMappings;
