import {
    Button,
    Checkbox,
    IconButton, InputBase,
    InputLabel,
    MenuItem,
    Popper,
    Select,
    TextField
} from "@mui/material";
import {useCallback, useLayoutEffect, useState} from "react";
import FormControl from "@mui/material/FormControl";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Paper from "@mui/material/Paper";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DateCalendar} from '@mui/x-date-pickers/DateCalendar';

const CustomSelect = (props) => {
    const {id, value, api, field} = props;
    const handleChange = useCallback(
        (event) => {
            api.setEditCellValue({id, field, value: event.target.value})
            api.stopCellEditMode({id, field})
            event.stopPropagation();
        },
        [api, field, id]
    );
    return <FormControl fullWidth>
        <Select variant='standard' disableUnderline value={value} onChange={handleChange}>
            {props.colDef.selectItems.map(el => <MenuItem value={el}>{el}</MenuItem>)}
        </Select>
    </FormControl>
}

const RenderSelect = (params) => {
    return <CustomSelect {...params}/>
}

const CustomCheckbox = (props) => {
    const {id, value, api, field} = props;
    const handleChange = useCallback(
        (event) => {
            api.setEditCellValue({id, field, value: event.target.checked})
            api.stopCellEditMode({id, field})
            event.stopPropagation();
        },
        [api, field, id]
    );
    return <Checkbox onChange={handleChange} checked={value}/>
}

const RenderCheckbox = (params) => {
    return <CustomCheckbox {...params}/>
}

const EditTextarea = (props) => {
    const {id, field, value, colDef, api, hasFocus} = props;
    const [valueState, setValueState] = useState(value);
    const [anchorEl, setAnchorEl] = useState();
    const [inputRef, setInputRef] = useState(null);

    useLayoutEffect(() => {
        if (hasFocus && inputRef) {
            inputRef.focus();
        }
    }, [hasFocus, inputRef]);

    const handleRef = useCallback((el) => {
        setAnchorEl(el);
    }, []);

    const handleChange = useCallback(
        (event) => {
            const newValue = event.target.value;
            setValueState(newValue);
            api.setEditCellValue(
                {id, field, value: newValue, debounceMs: 200},
                event,
            );
        },
        [api, field, id],
    );

    return (
        <div style={{position: 'relative', alignSelf: 'flex-start'}}>
            <div
                ref={handleRef}
                style={{
                    height: 1,
                    width: colDef.computedWidth,
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                }}
            />
            {anchorEl && (
                <Popper open anchorEl={anchorEl} placement="bottom-start">
                    <Paper elevation={1} sx={{p: 1, minWidth: colDef.computedWidth}}>
                        <InputBase
                            multiline
                            rows={4}
                            value={valueState}
                            sx={{textarea: {resize: 'both'}, width: '100%'}}
                            onChange={handleChange}
                            inputRef={(ref) => setInputRef(ref)}
                        />
                    </Paper>
                </Popper>
            )}
        </div>
    );
}

const RenderMultiline = (params) => {
    return <EditTextarea {...params}/>
}

const AddNewFieldModal = ({columns, setColumns, setOpen, defaultField = null}) => {
    const [fieldName, setFieldName] = useState(defaultField ? defaultField.headerName : null)
    const [cellComponent, setCellComponent] = useState(defaultField?.component ? defaultField.component : 'Default')
    const [selectItem, setSelectItem] = useState()
    const [selectItems, setSelectItems] = useState(defaultField?.selectItems ? defaultField.selectItems : [])
    const [error, setError] = useState(false)

    return (
        <>
            <div style={{display: 'flex', gap: '2rem'}}>
                <TextField variant='standard' label='Field Name' fullWidth
                           onChange={e => setFieldName(e.target.value)}
                           value={fieldName} error={error} helperText={error ? 'Field name is required' : ''}/>

                <FormControl fullWidth variant={'standard'}>
                    <InputLabel id='cell-component-label'>Cell Component</InputLabel>
                    <Select label={'Cell Component'} variant='standard' id='cell-component-label' value={cellComponent}
                            onChange={v => setCellComponent(v.target.value)}>
                        <MenuItem value='Default'>Default</MenuItem>
                        <MenuItem value='Checkbox'>Checkbox</MenuItem>
                        <MenuItem value='Select'>Select</MenuItem>
                        <MenuItem value='Calendar'>Calendar</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <hr/>
            <h3>Component Preview</h3>
            {cellComponent === 'Default' && <TextField/>}
            {cellComponent === 'Checkbox' && <Checkbox/>}
            {cellComponent === 'Calendar' &&
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateCalendar/>
                </LocalizationProvider>}
            {cellComponent === 'Select' &&
                <>
                    <FormControl fullWidth>
                        <Select variant='standard'>
                            {selectItems.map(el => <MenuItem value={el}>{el}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <h4>Add Select Values</h4>
                    <TextField onChange={e => setSelectItem(e.target.value)} value={selectItem} label='Select Value'
                               variant='standard' sx={{width: '85%'}}/>
                    <IconButton onClick={_ => {
                        setSelectItems([...selectItems, selectItem])
                        setSelectItem('')
                    }}><AddIcon fontSize='large'/></IconButton>
                    <div style={{overflow: 'auto', maxHeight: '15vh'}}>
                        {selectItems.map(el => {
                            return (<div>{el} <IconButton
                                    onClick={_ => setSelectItems(selectItems.filter(e => e !== el))}><RemoveIcon/></IconButton>
                                </div>
                            )
                        })}
                    </div>
                </>
            }
            <hr/>
            {defaultField && <Button color='error' onClick={_ => {
                setColumns(columns.filter(el => el.field !== defaultField.field))
                setOpen(false)
            }}>Delete</Button>}
            <Button onClick={_ => {
                if (!fieldName) {
                    setError(true)
                } else {
                    const baseColumn = {
                        field: fieldName.split(' ').map(w => w[0].toUpperCase() + w.substring(1)).join(''),
                        headerName: fieldName,
                        minWidth: 150,
                        editable: true,
                        customUserField: true
                    }
                    let newColumn;
                    if (cellComponent === 'Default') {
                        newColumn = {
                            ...baseColumn, component: 'Default',
                            renderEditCell: RenderMultiline
                        }
                    } else if (cellComponent === 'Checkbox') {
                        newColumn = {
                            ...baseColumn,
                            component: 'Checkbox',
                            renderEditCell: RenderCheckbox
                        }
                    } else if (cellComponent === 'Select') {
                        newColumn = {
                            ...baseColumn,
                            component: 'Select',
                            selectItems: selectItems,
                            renderEditCell: RenderSelect
                        }
                    } else if (cellComponent === 'Calendar') {
                        newColumn = {
                            ...baseColumn,
                            component: 'Calendar',
                            type: 'date'
                        }
                    }

                    setColumns(defaultField ? columns.map(el => el.field === defaultField.field ? newColumn : el) : [...columns, newColumn])
                    setOpen(false)
                }
            }}>Save</Button>
        </>
    )
}

export default AddNewFieldModal