import {Autocomplete, Box, Button, Checkbox, TextField, Tooltip} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {useState} from "react";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import './ProjectDetail.css'
import dayjs from "dayjs";
import LoadingButton from "@mui/lab/LoadingButton";
import {checkProjectName} from "../../api/requests";


const ProjectDetail = ({data, setData, countriesTimezones, handleComplete, setHelpModalOpen}) => {
    const [harmonizationInScope, setHarmonizationInScope] = useState(data.projectDetails?.harmonizationInScope ? data.projectDetails.harmonizationInScope : false);
    const [citizenRequired, setCitizenRequired] = useState(data.projectDetails?.citizenRequired ? data.projectDetails.citizenRequired : false);
    const [client, setClient] = useState(data.projectDetails?.client);
    const [startDate, setStartDate] = useState(data.projectDetails?.startDate ? data.projectDetails?.startDate : dayjs());
    const [endDate, setEndDate] = useState(data.projectDetails?.endDate);
    const [deletionDate, setDeletionDate] = useState(data.projectDetails?.deletionDate);
    const [description, setDescription] = useState(data.projectDetails?.description || null);
    const [country, setCountry] = useState(data.projectDetails?.country);
    const [timezone, setTimezone] = useState(data.projectDetails?.timezone);
    const [name, setName] = useState(data.projectDetails?.name);
    const [error, setError] = useState(false);
    const [nameError, setNameError] = useState(false)
    const [loading, setLoading] = useState(false)

    const countries = Object.fromEntries(countriesTimezones.map(el => [el.country_name, el.country_code]))
    const timezones = countriesTimezones.map(el => {
        return {code: el.country_code, name: el.time_zone_name + ' ' + el.gmt_offset, offset: el.gmt_offset, tzName: el.time_zone_name}
    })

    return (
        <div>
            <div className='main-page-project-name-section'>
                <p>Name project</p>
                <TextField
                    required
                    error={(error && !name) || (error && nameError)}
                    helperText={error && !name ? 'This is a required field' : nameError ? 'This name is already taken' : ''}
                    id="filled-basic"
                    onChange={e => {
                        if (e.target.value.length <= 48) {
                            setName(e.target.value)
                        }
                        setNameError(false)
                    }}
                    variant="filled"
                    value={name}/>
            </div>

            <div className='main-page-select-container'>
                <FormControl fullWidth>
                    <div className='main-page-select'>
                        <TextField
                            required
                            error={error && !client}
                            helperText={error && !client ? 'This is a required field' : ''}
                            id='filled-basic'
                            label={'Client'}
                            variant="outlined"
                            onChange={e => setClient(e.target.value)}
                            value={client}
                        />

                        <div>
                            Harmonization In-Scope: <span><Checkbox
                            checked={harmonizationInScope}
                            onChange={_ => setHarmonizationInScope(!harmonizationInScope)}/></span>
                        </div>

                        <div className='project-detail-date-picker-container'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label='Project Start Date'
                                    onChange={date => setStartDate(date)}
                                    slotProps={{
                                        textField: {
                                            variant: 'standard',
                                            error: error && !startDate,
                                            helperText: error && !startDate ? 'This is a required field' : '',
                                            required: true
                                        }
                                    }}
                                    sx={{width: '55%'}}
                                    value={startDate}
                                    maxDate={endDate}/>
                                <DatePicker
                                    label='Project End Date'
                                    slotProps={{textField: {variant: 'standard'}}}
                                    onChange={date => setEndDate(date)}
                                    sx={{width: '55%'}}
                                    value={endDate}
                                    minDate={startDate}/>
                                <DatePicker
                                    label='Date of Data Deletion'
                                    slotProps={{textField: {variant: 'standard'}}}
                                    onChange={date => setDeletionDate(date)}
                                    sx={{width: '55%'}}
                                    value={deletionDate}
                                    minDate={endDate}/>
                            </LocalizationProvider>
                        </div>

                        <TextField
                            multiline
                            rows={4}
                            id='filled-basic'
                            label={'Description'}
                            onChange={e => setDescription(e.target.value)}
                            variant="outlined"
                            value={description}/>
                        <Autocomplete renderInput={(params) => <TextField {...params} label='Country'/>}
                                      options={Object.keys(countries)} onChange={(_, v) => {
                            setTimezone(timezones.filter(el => el.code === countries[v])[0])
                            setCountry(v)
                        }} disableClearable/>


                        <div>
                            Citizenship Required:
                            <Tooltip title={country ? '' : <h2>Country must be selected</h2>}>
                                <span>
                                <Checkbox
                                    disabled={!country}
                                    checked={citizenRequired}
                                    onChange={_ => setCitizenRequired(!citizenRequired)}/>
                                </span>
                            </Tooltip>
                        </div>

                        <div className='project-detail-timezone-container'>
                            <Autocomplete renderInput={(params) => <TextField {...params} label='Timezones'
                                                                              error={error && !timezone} required/>}
                                          isOptionEqualToValue={(option, value) => option.name === value.name}
                                          value={timezone || null}
                                          options={timezones.filter(el => el.code === countries[country] || !country)}
                                          getOptionLabel={option => option.name}
                                          renderOption={(props, option) => <Box component="li" sx={{
                                              '& > img': {
                                                  mr: 2,
                                                  flexShrink: 0
                                              }
                                          }} {...props}>{option.name}</Box>}
                                          onChange={(_, v) => setTimezone(v)} disableClearable/>
                        </div>
                    </div>
                </FormControl>
            </div>

            <div className='main-page-btns'>
                <Button onClick={_ => setHelpModalOpen(true)}>help</Button>
                <LoadingButton loading={loading} variant='contained' onClick={async _ => {

                    if ([name, client, startDate, timezone].some(el => !el)) {
                        setError(true)
                    } else {
                        setLoading(true)
                        const res = await checkProjectName(name, localStorage.getItem('token'))
                        const valid = res.data.validity[0].smt_check_project_name_validity
                        setLoading(false)
                        if (!valid) {
                            setError(true)
                            setNameError(true)
                        } else {
                            setError(false)
                            setNameError(false)

                            setData({
                                ...data,
                                projectDetails: {
                                    name,
                                    client,
                                    startDate,
                                    endDate,
                                    deletionDate,
                                    description,
                                    country,
                                    timezone,
                                    harmonizationInScope, citizenRequired
                                }
                            })
                            handleComplete()
                        }
                    }
                }}>
                    <span>Next</span>
                </LoadingButton>
            </div>

        </div>
    )
}
export default ProjectDetail;