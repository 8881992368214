import {Alert, Button, Chip, CircularProgress, Tooltip} from "@mui/material";
import {DataGrid, GridToolbarContainer, useGridApiContext} from "@mui/x-data-grid"
import {MuiColorInput} from 'mui-color-input'
import {useCallback, useEffect, useState} from "react";
import './AssignTags.css'
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {getObjectTags, getObjectTagsAllocation, putObjectTags, putObjectTagsAllocations} from "../../../api/requests";
import {Save} from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

const CustomColor = (props) => {
    const {id, value, api, field} = props;
    const handleChange = useCallback(
        (event) => {
            api.setEditCellValue({id, field, value: event.toUpperCase()})
        },
        [api, field, id]
    );
    return <MuiColorInput value={value} onChange={handleChange} format='hex' isAlphaHidden
                          variant='standard' disableUnderline/>
}
const RenderColor = (params) => {
    return <CustomColor {...params}/>
}

const CustomChip = (props) => {
    const {id, value, api, field} = props;
    const [tags, setTags] = useState(value)
    const handleDelete = (event, tagId) => {
        const newValue = tags.filter((_, i) => {
            return i !== tagId
        })
        setTags(newValue)
        api.setEditCellValue({id, field, value: newValue})
        event.stopPropagation()
    }
    return <div>
        {tags.map((tag, i) => <Chip label={tag.tag_name} sx={{bgcolor: tag.tag_color}}
                                    onDelete={e => handleDelete(e, i)}/>)}
    </div>
}

const RenderChip = (params) => {
    return <CustomChip {...params}/>
}

const AssignTags = ({projectId, mcId, getObjects}) => {
    const [tagsRows, setTagsRows] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const [TORows, setTORows] = useState([])
    const [tagsChanged, setTagsChanged] = useState(false)
    const [nameError, setNameError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingAssign, setLoadingAssign] = useState(false)
    const [loadingTOAssign, setLoadingTOAssign] = useState(false)
    const [tagsLoading, setTagsLoading] = useState(false)
    const [TOLoading, setTOLoading] = useState(false)
    const [assignError, setAssignError] = useState(false)

    const updateRows = () => {
        const token = localStorage.getItem('token')
        setTagsLoading(true)
        setTOLoading(true)
        setSelectedRows([])
        getObjectTagsAllocation(projectId, mcId, token).then(r => {
            setTORows(r.data.template_object_tag_allocations.map((el, i) => {
                return {id: i, ...el}
            }))
            setTOLoading(false)
        })
        getObjectTags(projectId, mcId, token).then(r => {
            setTagsRows(r.data.data_objects.map((el, i) => {
                return {id: i, ...el}
            }))
            setTagsLoading(false)
        })
    }

    useEffect(() => {
        updateRows()
    }, []);

    const columns = [
        {
            field: 'tag_name',
            headerName: 'Tags Name',
            editable: true,
            minWidth: 200,
            flex: 1,
            cellClassName: (params) => {
                if (!nameError) {
                    return ''
                }
                if (!params.value || tagsRows.filter(el => el.tag_name === params.value).length > 1) {
                    return 'erroneous-cell'
                }
            }
        },
        {field: 'description', headerName: 'Description', editable: true, minWidth: 200, flex: 1, filterable: false},
        {
            field: 'tag_color',
            headerName: 'Color Code',
            minWidth: 200,
            filterable: false,
            flex: 1,
            renderEditCell: RenderColor,
            editable: true,
            renderCell: (cellValues) => {
                return <MuiColorInput value={cellValues.value} disabled variant={'standard'} InputProps={{
                    disableUnderline: true
                }}/>
            }
        }
    ]

    const CustomToolbar = (props) => {
        const apiRef = useGridApiContext();

        return (
            <GridToolbarContainer {...props}>
                <Button startIcon={<AddIcon/>} disabled={loading || loadingAssign}
                        onClick={_ => {
                            setTagsRows([...tagsRows, {
                                id: tagsRows.length,
                                recid: null,
                                tag_color: '#CBC3C4'
                            }])
                            setTagsChanged(true)
                        }}>
                    Add New Row
                </Button>
                <Button disabled={loading || loadingAssign} onClick={_ => {
                    const t = tagsRows.filter(el => !apiRef.current.getSelectedRows().has(el.id)).map(el => {
                        return {...el}
                    })
                    setTagsRows(t.map((el, i) => {
                        return {...el, id: i}
                    }))
                    apiRef.current.setRowSelectionModel([])
                    setTagsChanged(true)
                }} startIcon={<RemoveIcon/>}>
                    Remove Rows
                </Button>
                <LoadingButton disabled={!tagsChanged || loadingTOAssign} loading={loading} startIcon={<Save/>}
                               onClick={_ => {
                                   if (tagsRows.some(el => !el.tag_name) || new Set(tagsRows.map(el => el.tag_name)).size !== tagsRows.length) {
                                       setNameError(true)
                                   } else {
                                       setLoading(true)
                                       putObjectTags(projectId, mcId, tagsRows.map(el => {
                                           return {
                                               recid: el.recid,
                                               tag_name: el.tag_name,
                                               description: el.description,
                                               tag_color: el.tag_color
                                           }
                                       }), localStorage.getItem('token')).then(_ => {
                                           setLoading(false)
                                           setNameError(false)
                                           setTagsChanged(false)
                                           updateRows()
                                       }).catch(_ => setLoading(false))
                                   }
                               }}>
                    <span>Save</span>
                </LoadingButton>
                <LoadingButton loading={loadingAssign} startIcon={<AssignmentTurnedInIcon/>} onClick={_ => {
                    if (apiRef.current.getSelectedRows().size === 0 || selectedRows.length === 0) {
                        setAssignError(true)
                        return
                    }
                    setAssignError(false)
                    setLoadingAssign(true)
                    const t = tagsRows.filter(el => apiRef.current.getSelectedRows().has(el.id))
                    putObjectTagsAllocations(projectId, TORows.filter(el => selectedRows.includes(el.id)).map(el => {
                        return {
                            recid: el.recid,
                            to_tags: [...new Set([...el.to_tags.map(tag => tag.tag_id), ...t.map(tag => tag.recid)])]
                        }
                    }), localStorage.getItem('token')).then(_ => {
                        setLoadingAssign(false)
                        updateRows()
                        getObjects()
                    }).catch(_ => setLoadingAssign(false))
                }} disabled={tagsChanged || loadingTOAssign}>
                    <span>
                        Assign Tags
                    </span>
                </LoadingButton>
                {nameError && <Alert severity={'error'}>Names must be unique and non-empty values</Alert>}
                {(assignError && !tagsChanged) &&
                    <Alert severity={'error'}>Both tags and TO must be selected to assign</Alert>}
            </GridToolbarContainer>
        )
    }

    const CustomTOToolbar = (props) => {
        return <GridToolbarContainer {...props}>
            <LoadingButton loading={loadingTOAssign} disabled={loading || loadingAssign} startIcon={<Save/>}
                           onClick={_ => {
                               setLoadingTOAssign(true)
                               const token = localStorage.getItem('token')
                               putObjectTagsAllocations(projectId, TORows.map(el => {
                                   return {
                                       recid: el.recid,
                                       to_tags: el.to_tags.map(tag => tag.tag_id)
                                   }
                               }), token).then(_ => {
                                   setLoadingTOAssign(false)
                                   setTOLoading(true)
                                   setSelectedRows([])
                                   getObjectTagsAllocation(projectId, mcId, token).then(r => {
                                       setTORows(r.data.template_object_tag_allocations.map((el, i) => {
                                           return {id: i, ...el}
                                       }))
                                       getObjects()
                                       setTOLoading(false)
                                   })

                               })
                           }}>
                <span>
                    Save
                </span>
            </LoadingButton>
        </GridToolbarContainer>
    }
    const TOColumns = [
        {field: 'to_name', headerName: 'Template Object Name', minWidth: 200, flex: 1},
        {
            field: 'to_tags',
            filterable: false,
            headerName: 'Existing Tags',
            minWidth: 200,
            flex: 1,
            renderCell: (cellValues) => {
                return (
                    <div style={{display: "flex", gap: 3, flexWrap: 'wrap'}}>
                        {cellValues.value.map(tag => <Tooltip title={<h2>{tag.description}</h2>}><Chip
                            label={tag.tag_name}
                            sx={{bgcolor: tag.tag_color}}/></Tooltip>)}
                    </div>)
            },
            editable: true,
            renderEditCell: RenderChip
        }]
    const processRowUpdate = (newValue) => {
        setTagsChanged(true)
        setTagsRows(tagsRows.map(el => el.id === newValue.id ? newValue : el))
        return newValue
    }

    const processTORowUpdate = (newValue) => {
        setTORows(TORows.map(el => el.id === newValue.id ? newValue : el))
        return newValue
    }
    return (
        <>
            <div className='mass-assign-body'>
                <div className='mass-assign-half'>
                    <h1>1. Select Template Objects</h1>
                    <h5>Select Template Objets from the table below</h5>
                    {TOLoading ?
                        <div style={{display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
                            <CircularProgress size={'3rem'}/></div> :
                        <DataGrid columns={TOColumns} rows={TORows} hideFooter sx={{height: '70%', border: 0}}
                                  checkboxSelection processRowUpdate={processTORowUpdate}
                                  slots={{toolbar: CustomTOToolbar}}
                                  onProcessRowUpdateError={e => console.log(e)}
                                  onRowSelectionModelChange={rowSelectionModel => setSelectedRows(rowSelectionModel)}
                                  getRowHeight={() => 'auto'} disableRowSelectionOnClick/>}
                </div>
                <hr/>
                <div className='mass-assign-half'>
                    <h1>2. Select Tags to Add</h1>
                    <h5>Choose tags to assign to the selected Template Objects from the left pane</h5>
                    {tagsLoading ?
                        <div style={{display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
                            <CircularProgress size={'3rem'}/></div> :
                        <DataGrid columns={columns} rows={tagsRows} processRowUpdate={processRowUpdate}
                                  onProcessRowUpdateError={e => console.log(e)}
                                  hideFooter sx={{height: '75%', border: 0}} checkboxSelection
                                  slots={{toolbar: CustomToolbar}} disableRowSelectionOnClick/>}
                </div>
            </div>
        </>
    )
}

export default AssignTags