import {useState} from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import FollowUpDataGrid from "./FollowUpDataGrid";

const FollowUpAccordion = ({summary, count, userData, allUserData, follow_up_data, STO = false}) => {
    const [expanded, setExpanded] = useState(false)

    return (
        <>
            <Accordion expanded={expanded} onChange={_ => setExpanded(!expanded)}
                       TransitionProps={{unmountOnExit: true}}>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    {summary}
                </AccordionSummary>
                <AccordionDetails>
                    {count > 0 && <FollowUpDataGrid userData={userData} allUserData={allUserData}
                                                    followUpData={follow_up_data} STO={STO}/>}
                </AccordionDetails>
            </Accordion>


        </>
    )
}

export default FollowUpAccordion