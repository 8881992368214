import './App.css';
import {
    BrowserRouter,
    Routes,
    Route, useSearchParams
} from "react-router-dom";

import LandingPage from "./Landing/LandingPage";
import HomePage from "./Project/HomePage/HomePage";
import ProjectCreate from "./ProjectCreate/ProjectCreate";
import ProjectSettings from "./Project/ProjectSettings/ProjectSettings";
import DataScope from "./Project/DataScope/DataScope";
import ManageObjects from "./Project/ManageObjects/ManageObjects";
import {AuthProvider} from "./AuthProvider";
import MappingView from "./Project/MappingView/MappingView";

const App = () => {
    return (
        <BrowserRouter>
            <AuthProvider>
                <Routes>
                    <Route path='/' element={<LandingPage/>}/>
                    <Route path='project/:projectId' element={<HomePage/>}/>
                    <Route path='project/:projectId/dataScope' element={<DataScope/>}/>
                    <Route path='project/:projectId/object/manage' element={<ManageObjects/>}/>
                    <Route path='project/:projectId/mapping/view' element={<MappingView/>}/>
                    <Route path='create_project' element={<ProjectCreate/>}/>
                    <Route path='project/:projectId/settings' element={<ProjectSettings/>}/>
                </Routes>
            </AuthProvider>
        </BrowserRouter>
    );
}

export default App;
