import './UploadLoadDatasetModal.css';
import {Button, FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {useCallback, useState} from "react";
import {useDropzone} from "react-dropzone";
import close from "../../assets/close.png";
import MainModal from "../../../../helpers/Modal/MainModal";
import FileProcessModal from "../../FileProcessModal/FileProcessModal";
import FinishUploadingModal from "./FinishUploadingModal/FinishUploadingModal";


const uploadFileTypes = [
    'CSV File',
    'New Dataset',
    'any',
];

const UploadLoadDatasetModal = () => {
    const [ingestionMethod, setIngestionMethod] = useState('');


    const Dragndrop = () => {
        const [files, setFiles] = useState()
        const [fileProcessModal, setFileProcessModal] = useState(false);
        const [finishUploading, setFinishUploading] = useState(false);


        const onDrop = useCallback(acceptedFiles => {
            setFiles(acceptedFiles)
        }, [])

        const {getRootProps, getInputProps} = useDropzone({onDrop})

        const FileContainer = () => {

            return (
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
                    <h2>File Name</h2>

                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '80%',}}>
                        {
                            files.map(file => {
                                return (
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '2rem',
                                        padding: '2% 0', borderBottom: '1px solid lightgray', width: '100%'}}>
                                        <img onClick={_ => setFiles(files?.filter(el => el !== file))} style={{width: '16px', height: '16px', cursor: 'pointer'}} src={close}></img>

                                        <span>{file.name}</span>

                                    </div>
                                )
                            })
                        }
                    </div>

                </div>

            )
        }

        return (
            <div style={{width: '90%', padding: '1rem', border: '1px solid lightgray'}}>
                <h2 style={{padding: 0, marginTop: 0}}>Upload Files:</h2>
                <div>
                    <div className='manage-error-logs-dropzone dropzone-area'  {...(!files?.length? getRootProps() : null)}>

                        {!files?.length ? "Drag your file or select file" : <FileContainer/>}
                        {!files?.length && <input className='dropzone-area' {...getInputProps()} />}
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%', alignItems: 'center', marginBottom: '3%'}}>
                        <Button
                            disabled={!files?.length}
                            variant='contained'
                            onClick={_ => setFinishUploading(true)}
                            sx={{marginRight: '2%', marginTop: '4%'}}>Process
                        </Button>
                    </div>

                    {
                        !!files?.length &&
                            <p style={{marginLeft: '25%'}}>Processing source datsaets. Do not close the page...</p>
                    }


                    <MainModal
                        modalOpen={finishUploading}
                        setModalOpen={setFinishUploading}
                        headerText='Finish Uploading Load Dataset'
                        tooltip={true}
                        Child={FinishUploadingModal}
                        files={files}
                        setFiles={setFiles}
                        isOpen={setFileProcessModal}
                    />
                </div>
            </div>


        )
    }

    return (
        <div style={{width: '50vw', padding: '1rem'}}>
            <div style={{display: 'flex', flexDirection: 'column', width: '40%'}}>
                <h2 style={{marginBottom: 0}}>Instructions:</h2>
                <h3 style={{marginBottom: 0}}>1. Enter the Load Dataset Name and Description</h3>
                <TextField
                    id="standard-basic"
                    placeholder="Enter Descriptive Name for the Load Dataset"
                    label="Load Dataset Name"
                    variant="standard"
                />

                <TextField
                    id="standard-basic"
                    placeholder="Succinct Description"
                    label="Description:"
                    variant="standard"
                />

                <h3 style={{marginBottom: 0}}>2. Upload Files to the Load Dataset</h3>
            </div>

            <div style={{display: 'flex', flexDirection: 'row', gap: '2rem'}}>
                <FormControl variant="standard" sx={{marginTop: '1rem', marginBottom: '1rem', width: '45%'}}>
                <InputLabel sx={{marginLeft: '1px'}} id="demo-simple-select-label">Select Data Ingestion Method:</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={ingestionMethod}
                    variant='standard'
                    onChange={event => setIngestionMethod(event.target.value)}
                  >
                    {
                        uploadFileTypes.map(type => {
                            return (
                                <MenuItem value={type}>{type}</MenuItem>
                            )
                        })
                    }
                  </Select>
            </FormControl>

            <Dragndrop/>
            </div>
            <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '1rem'}}>
                <Button className='manage-load-data-save-close-btn' variant='contained'>Save & Close</Button>
            </div>
        </div>
    )
}

export default UploadLoadDatasetModal;