import {Box, Button, Checkbox} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import {styled} from "@mui/material/styles";
import {useState} from "react";
import {
    DataGrid,
    GridToolbarColumnsButton,
    GridToolbarContainer, GridToolbarDensitySelector,
    GridToolbarFilterButton,
    useGridApiContext
} from "@mui/x-data-grid";
import RemoveIcon from "@mui/icons-material/Remove";


const AddNewDataSourceModal = ({setChildModalOpen, rows, setRows, dims, columns}) => {
    const [selectedRows, setSelectedRows] = useState([])
    console.log(rows)
    const saveHandler = () => {
        setRows([...rows, ...dims.filter(row => selectedRows.includes(row.recid)).map((el, index) => {
            return {
                ...el,
                id: rows.length + index,
                dim_name: el.dim_name,
                dim_type: el.dim_type,
                dim_description: el.dim_description,
                parsing_format_id: el.recid,
                is_standard: true
            }
        })])
        setChildModalOpen(false)
    }

    const CustomToolbar = (props) => {
        return (
            <GridToolbarContainer {...props}>
                <GridToolbarColumnsButton/>
                <GridToolbarFilterButton/>
                <GridToolbarDensitySelector/>
            </GridToolbarContainer>)
    }

    return (
        <>
            <div className='select-data-source-table-section'>
                <DataGrid rows={dims.filter(el => !rows.map(row => row.recid).includes(el.recid)).map(el => {
                    return {id: el.recid, dim_name: el.dim_name, dim_type: el.dim_type, details: {...el}}
                })} columns={columns} hideFooter checkboxSelection disableRowSelectionOnClick
                          getRowHeight={() => 'auto'}
                          sx={{border: 'none'}} slots={{toolbar: CustomToolbar}} onRowSelectionModelChange={(ids) => setSelectedRows(ids)}/>
            </div>

            <div style={{display: 'flex', flexDirection: 'column', gap: '0.5rem'}}>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button onClick={saveHandler}>Save</Button>
                </div>
            </div>
        </>
    )
}

export default AddNewDataSourceModal