import './AddDataSource.css';
import {Box, Button} from "@mui/material";
import MainModal from "../../helpers/Modal/MainModal";
import {useState} from "react";
import AddNewDataSourceModal from "./AddNewDataSourceModal";
import SelectDataSourceModal from "./SelectDataSourceModal";
import dayjs from "dayjs";
import {postProject, postSystem, putDIM, putMC, putRoles, sendInvitationEmail, uploadImage} from "../../api/requests";
import {
    DataGrid,
    GridToolbarColumnsButton,
    GridToolbarContainer, GridToolbarDensitySelector,
    GridToolbarFilterButton,
    useGridApiContext
} from "@mui/x-data-grid";
import RemoveIcon from "@mui/icons-material/Remove";
import LoadingButton from "@mui/lab/LoadingButton";
import {useNavigate} from "react-router-dom";


const AddDataSource = ({
                           handleComplete,
                           data,
                           handleBack,
                           dims,
                           systems,
                           user,
                           setAlertOpen,
                           setAlertSeverity,
                           setAlertMessage,
                           setHelpModalOpen
                       }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectDataSourceModalOpen, setSelectDataSourceModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [rows, setRows] = useState(data.dataMethods ? data.dataMethods : [])
    const [defaultRow, setDefaultRow] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const CustomToolbar = (props) => {
        const apiRef = useGridApiContext();

        return (
            <GridToolbarContainer {...props}>
                <GridToolbarColumnsButton/>
                <GridToolbarFilterButton/>
                <GridToolbarDensitySelector/>
                <Button endIcon={<RemoveIcon/>}
                        onClick={_ => {
                            setRows(rows.filter(el => !apiRef.current.getSelectedRows().has(el.id)).map((el, i) => {
                                return {...el, id: i}
                            }))
                            apiRef.current.setRowSelectionModel([])
                        }}>Remove</Button>
            </GridToolbarContainer>)
    }

    const columns = [{field: 'dim_name', headerName: 'Name', minWidth: 150, flex: 1},
        {
            field: 'dim_type',
            headerName: 'Type',
            minWidth: 150,
            flex: 1,
            renderCell: (params) => <>{typeMap[params.value]}</>
        }, {
            field: 'details', headerName: 'Details', minWidth: 300, flex: 2, renderCell: (params) => {
                const row = params.value
                return <>{
                    row.dim_type === 'FF' &&
                    <div>
                        <p>Description: {row.dim_description}</p>
                        <p>File Extensions: {row.file_extensions?.join(', ')}</p>
                        <p>Delimited File: {row.delimited_file ? 'Yes' : 'No'}</p>
                        {row.delimited_file && <>
                            <p>Parsing Delimiter: <Box display='div'
                                                       sx={{
                                                           display: 'inline',
                                                           p: 1,
                                                           m: 1,
                                                           bgcolor: '#fff',
                                                           color: 'grey.800',
                                                           border: '1px solid',
                                                           borderColor: 'grey.300',
                                                           borderRadius: 2,
                                                           fontSize: '0.875rem',
                                                           fontWeight: '700',
                                                       }}>{row.parsing_delimiter}</Box>
                            </p>
                            <p>Quote Character: <Box display='div'
                                                     sx={{
                                                         display: 'inline',
                                                         p: 1,
                                                         m: 1,
                                                         bgcolor: '#fff',
                                                         color: 'grey.800',
                                                         border: '1px solid',
                                                         borderColor: 'grey.300',
                                                         borderRadius: 2,
                                                         fontSize: '0.875rem',
                                                         fontWeight: '700',
                                                     }}>{row.quotechar}</Box>
                            </p>
                            <p>Quoting: {row.quoting}</p>
                            <p>Encoding: {row.encoding}</p>
                        </>}
                    </div>
                }
                    {row.dim_type === 'SSD' &&
                        <div>
                            <p>Description: {row.dim_description}</p>
                            <p>Xpath: {row.xpath}</p>
                            <p>Namespaces: {row.namespaces}</p>
                        </div>}
                    {row.dim_type === 'DB' &&
                        <div>
                            <p>Description: {row.dim_description}</p>
                            <p>Server Name: {row.db_server_name}</p>
                            <p>Server Type: {row.db_server_type}</p>
                            <p>Database Name: {row.database_name}</p>
                            <p>Username: {row.username}</p>
                            <p>Connection Timeout: {row.connection_timeout}</p>
                            <p>Execution Timeout: {row.execution_timeout}</p>
                        </div>}</>
            }
        }]

    const typeMap = {
        'FF': 'Flat File',
        'SSD': 'Semi-structured Data',
        'UD': 'Unstructured Data',
        'DB': 'Database Connection'
    }

    return (
        <div className='data-source-section'>
            <MainModal modalOpen={modalOpen} setModalOpen={setModalOpen} headerText='Add New Data Ingestion Method'
                       Child={AddNewDataSourceModal} setRows={setRows} rows={rows} setChildModalOpen={setModalOpen}
                       dims={dims}/>

            <MainModal modalOpen={selectDataSourceModalOpen} setModalOpen={setSelectDataSourceModalOpen}
                       headerText='Select Pre-Defined Data Ingestion Methods'
                       Child={SelectDataSourceModal} setRows={setRows} rows={rows} dims={dims} columns={columns}
                       setChildModalOpen={setSelectDataSourceModalOpen}/>

            <MainModal modalOpen={editModalOpen} setModalOpen={setEditModalOpen} headerText='Edit Data Ingestion Method'
                       Child={AddNewDataSourceModal} setRows={setRows} rows={rows} setChildModalOpen={setEditModalOpen}
                       defaultRow={defaultRow} dims={dims}/>
            <div className='data-source-btns'>
                <Button variant={'contained'} onClick={_ => setSelectDataSourceModalOpen(true)}>Select Pre-Defined
                    Data Ingestion Methods</Button>
                <Button variant={'contained'} onClick={_ => setModalOpen(true)}>Add New Data Ingestion
                    Methods</Button>
            </div>
            {rows.length > 0 &&
                <div className='data-source-table-section'>
                    <DataGrid rows={rows.map(el => {
                        return {id: el.id, dim_name: el.dim_name, dim_type: el.dim_type, details: {...el}}
                    })} columns={columns} hideFooter checkboxSelection disableRowSelectionOnClick
                              getRowHeight={() => 'auto'}
                              sx={{height: '65vh', border: 'none'}} slots={{toolbar: CustomToolbar}}
                              getRowClassName={(params) => {
                                  if (params.row.details.is_standard) {
                                      return 'standard-row'
                                  }
                                  return ''
                              }} onRowDoubleClick={(params) => {
                        const row = rows.find(el => el.id === params.id)
                        if (!row.is_standard) {
                            setDefaultRow(row)
                            setEditModalOpen(true)
                        }
                    }}/>
                </div>}


            <div className='main-page-btns'>
                <Button className='help-button' onClick={_ => setHelpModalOpen(true)}>help</Button>
                <Button className='main-page-btn close-button' onClick={handleBack}>Back</Button>
                <LoadingButton loading={loading} variant='contained' onClick={async _ => {
                    if ([...new Set(rows.map(el => el.dim_name))].length < rows.length) {
                        setError(true)
                    } else {
                        setLoading(true)
                        const customSystems = systems.filter(el => el.custom && (data.systemData.selectedSourceSystem.includes(el.recid) || data.systemData.selectedTargetSystem.includes(el.recid)))
                        const newSystemMap = {}

                        const token = localStorage.getItem('token')

                        for (const el of customSystems) {
                            if (el.file) {
                                console.log(el.file)
                                const res = await uploadImage('test', el.file, 'atom-smt-images').then(async res => {
                                    console.log(res)

                                    let r = await postSystem(el.system_name, el.system_version, el.description, res.data.url, token).then(res => {
                                        newSystemMap[el.recid] = res.data.response[0][0]
                                        console.log(newSystemMap)
                                        return res
                                    })
                                    return r
                                })
                            } else {
                                const res = await postSystem(el.system_name, el.system_version, el.description, null, token).then(res => {
                                    newSystemMap[el.recid] = res.data.response[0][0]
                                })
                            }
                        }

                        const sourceSystems = data.systemData.selectedSourceSystem.map(el => Object.keys(newSystemMap).includes(el) ? newSystemMap[el] : el)
                        const targetSystems = data.systemData.selectedTargetSystem.map(el => Object.keys(newSystemMap).includes(el) ? newSystemMap[el] : el)

                        const dataRows = rows.map(el => {
                            return {...el, recid: null}
                        })

                        await postProject(data.projectDetails.name, data.projectDetails.description, user.username, data.projectDetails.client,
                            data.projectDetails.harmonizationInScope, sourceSystems, targetSystems,
                            data.projectDetails.startDate ? dayjs(data.projectDetails.startDate.$d).format('YYYY-MM-DD') : null,
                            data.projectDetails.endDate ? dayjs(data.projectDetails.endDate?.$d).format('YYYY-MM-DD') : null,
                            data.projectDetails.deletionDate ? dayjs(data.projectDetails.deletionDate?.$d).format('YYYY-MM-DD') : null,
                            data.projectDetails.timezone.code, data.projectDetails.citizenRequired,
                            data.projectDetails.timezone.tzName, token).then(async r => {

                            const projectId = r.data.project[0][0]

                            let errorList = []


                            await putRoles(projectId, data.userTeam, token).then(async _ =>
                                await sendInvitationEmail(data.projectDetails.name, user.username, data.userTeam.filter(el => el.username !== user.username).map(el => el.username), token)
                                    .catch(_ => errorList.push("invitation emails couldn't be sent"))
                            ).catch(_ => errorList.push("users couldn't be added to the project"))

                            await putMC(projectId, data.migrationCycles, token)
                                .catch(_ => errorList.push("migration cycles couldn't be added to the project"))

                            await putDIM(projectId, dataRows, token)
                                .catch(_ => errorList.push("data ingestion methods couldn't be added to the project"))

                            if (errorList.length === 0) {
                                navigate(`/project/${projectId}?creationAlert=s:${data.projectDetails.name} was created successfully.`, {replace: true})
                            } else {
                                const errMsg = data.projectDetails.name + ' was created successfully but ' + errorList.join(', ') + '. Please contact support.'
                                navigate(`/project/${projectId}?creationAlert=e:${errMsg}`, {replace: true})
                            }
                        }).catch(_ => {
                            setLoading(false)
                            setAlertMessage('Project creation failed')
                            setAlertSeverity('error')
                            setAlertOpen(true)
                        })
                    }
                }}>
                    <span>Create a project</span>
                </LoadingButton>
            </div>

        </div>
    );

}

export default AddDataSource;