export const SMT_API = `${process.env.REACT_APP_SMT_API}/api/v1/smt/`
export const API = `${process.env.REACT_APP_ATOM_API}/api/v1/atom/`
export const ATOM_UI = `${process.env.REACT_APP_ATOM_UI}`
export const GET_ALL_PROJECTS = SMT_API + 'projects'
export const GET_COUNTRIES = SMT_API + 'projects/countries'
export const GET_SYSTEMS = SMT_API + 'projects/systems'
export const GET_DOMAIN_USERS = API + 'domains/users'
export const GET_PERMISSIONS = API + 'system/permissions'
export const GET_USER = API + 'user'
export const CHECK_PROJECT_NAME = SMT_API + 'projects/project_name_validation'
export const GET_DIMS = SMT_API + 'projects/dims'
export const GET_ROLES = SMT_API + 'projects/roles'
export const POST_IMAGE = SMT_API + 'upload/image'
export const MIGRATION_CYCLES = SMT_API + 'projects/migration_cycles'
export const INVITATION_EMAIL = SMT_API + 'projects/invitation_email'
export const USERS = SMT_API + 'projects/users'
export const VALIDATE = API + 'user/token/validate'
export const METADATA = SMT_API + 'projects/metadata'
export const GET_MIGRATION_CYCLES = SMT_API + 'projects/migration_cycles'
export const DATA_OBJECTS = SMT_API + 'projects/data_objects'
export const GET_LOOKUP_TABLES = SMT_API + 'projects/lookup_tables'
export const GET_LOOKUP_VALUES = SMT_API + 'projects/lookup_values'
export const GET_TO_TAGS = SMT_API + 'projects/template_object_tags'
export const GET_USER_GROUPS = SMT_API + 'projects/user_groups'
export const GET_PROJECT_USERS = SMT_API + 'projects/project_users'
export const GET_PROJECT_TARGET_SYSTEMS = SMT_API + 'projects/project_target_systems';
export const GET_PROJECT_SOURCE_SYSTEMS = SMT_API + 'projects/project_source_systems';
export const GET_LOAD_METHODS = SMT_API + 'projects/load_methods';
export const UPDATE_DO_DESCRIPTION = SMT_API + 'projects/data_object_description'

export const REPORT_EMAIL = SMT_API + 'email/issue'
export const AUTH_LANDING = SMT_API + 'auth/landing'
export const AUTH_PCW = SMT_API + 'auth/project_creation'
export const AUTH_PROJECT_HOME = SMT_API + 'auth/project_home'
export const GET_PROJECT_PERMISSIONS = SMT_API + 'auth/project_permissions'
export const GET_UNASSIGNED_OBJECTS = SMT_API + 'projects/unassigned_objects'
export const GET_ASSIGNED_OBJECTS = SMT_API + 'projects/assigned_objects'
export const GET_ALL_USER_DATA = API + 'users'
export const AUTH_PROJECT_SETTINGS = SMT_API + 'auth/project_settings'
export const OBJECT_TAGS_ALLOCATION = SMT_API + 'projects/get_template_object_tag_allocations'
export const OBJECT_TAGS = SMT_API + 'projects/template_object_tags'
export const PUT_OBJECT_TAGS_ALLOCATION = SMT_API + 'projects/template_object_tag_allocations'
export const ARCHIVED_OBJECTS = SMT_API + 'projects/archived_objects'
export const ARCHIVE_OBJECTS = SMT_API + 'projects/archive_objects'
export const UNARCHIVE_OBJECTS = SMT_API + 'projects/unarchive_objects'
export const DOWNLOAD = SMT_API + 'download'
export const ALL_DATA_OBJECTS = SMT_API + 'projects/all_data_objects'
export const TO_ALLOCATION = SMT_API + 'projects/template_object_data_object_allocation'
export const GET_USERS = API + 'users'
export const UPDATE_TEMPLATE_OBJECT = SMT_API + 'projects/template/object'
export const DATA_INGESTION_METHODS = SMT_API + 'projects/data_ingestion_methods'
export const PROJECT_PERMISSIONS_USERS = SMT_API + 'projects/project_permissions_users'
