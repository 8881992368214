import './FinishUploadingModal.css';
import close from "../../../assets/close.png";
import checked from "../../../assets/checked.png";
import {TextField, Button} from "@mui/material";

const FinishUploadingModal = ({files, setFiles}) => {

    const removeRow = (row) => {
        setFiles(files.filter(file => file.name !== row.name))
    }

    return (
        <div style={{width: '30vw',}}>
            <h2>Number of Files: {files?.length}</h2>
            <h2>Number of Sheets per File: 4</h2>

            {
                files.map(row => {
                    return (
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                            borderBottom: '1px solid lightgray',
                            paddingBottom: '1rem',
                            width: '60%',
                            margin: '0 auto',
                            marginTop: '2rem'
                        }}>
                            <img
                                alt='img'
                                style={{width: '20px', cursor: 'pointer'}}
                                onClick={_ => removeRow(row)}
                                src={close}>
                            </img>

                            <img alt='img' style={{width: '24px', marginRight: '5rem'}} src={checked}></img>

                            <span>{row.name}</span>
                        </div>

                    )
                })
            }

            <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '1rem'}}>
                <Button variant='contained'>Save & Close</Button>
            </div>

        </div>
    )

}

export default FinishUploadingModal;
