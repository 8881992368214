import {FormControl, MenuItem, Select, InputLabel, Button} from "@mui/material";
import React, {useState} from "react";


const MassEditFields = () => {
    const [attribute, setAttribute] = useState('');
    const [value, setValue] = useState('');
    const rows = 4;

    return (
        <div style={{display: 'flex', flexDirection: 'column', width: '20vw', height: '15vh', padding: '1rem', gap: '2rem'}}>
            <span style={{fontSize: 25}}>Edit field values for {rows} rows:</span>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '1rem'}}>
                <FormControl fullWidth sx={{minWidth: 120}}>
                    <InputLabel id="demo-simple-select-label">Attribute</InputLabel>
                        <Select
                            sx={{
                                boxShadow: 'none',
                                '.MuiOutlinedInput-notchedOutline': {border: 0},
                            }}
                            labelId="demo-simple-select-filled-label"
                            variant='standard'
                            id="demo-simple-select-filled"
                            value={attribute}
                            onChange={e => setAttribute(e.target.value)}
                        >
                            <MenuItem value={'Required?'}>Required?</MenuItem>
                            <MenuItem value={'Functional Name'}>Functional Name</MenuItem>
                            <MenuItem value={'Technical Name'}>Technical Name</MenuItem>
                        </Select>
                </FormControl>

                <FormControl fullWidth sx={{minWidth: 120}}>
                    <InputLabel id="demo-simple-select-label">Value?</InputLabel>
                        <Select
                            sx={{
                                boxShadow: 'none',
                                '.MuiOutlinedInput-notchedOutline': {border: 0},
                            }}
                            labelId="demo-simple-select-filled-label"
                            variant='standard'
                            id="demo-simple-select-filled"
                            value={value}
                            onChange={e => setValue(e.target.value)}
                        >
                            <MenuItem value={'Yes'}>Yes</MenuItem>
                            <MenuItem value={'No'}>No</MenuItem>
                        </Select>
                </FormControl>
            </div>

            <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '1rem'}}>
                <Button variant='contained'>Assign</Button>
            </div>
        </div>
    )
}

export default  MassEditFields;
