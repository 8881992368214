import Header from "../../helpers/header/Header";
import {useParams} from "react-router-dom";
import {Button, InputAdornment, InputLabel, MenuItem, Select, Tab, Tabs, TextField, Tooltip} from "@mui/material";
import {useCallback, useState} from "react";
import './DataScope.css'
import {QuestionMark} from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import FormControl from "@mui/material/FormControl";
import {DataGrid, GridToolbarContainer} from "@mui/x-data-grid";
import MainModal from "../../helpers/Modal/MainModal";
import AddNewFieldModal from "./AddNewFieldModal";
import {
    useGridApiContext,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
} from '@mui/x-data-grid';

const getJson = (apiRef) => {
    const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
    const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);

    const data = filteredSortedRowIds.map((id) => {
        const row = {};
        visibleColumnsField.forEach((field) => {
            row[field] = apiRef.current.getCellParams(id, field).value;
        });
        return row;
    });

    return JSON.stringify(data, null, 2);
};


const CustomToolbar = (props) => {
    const apiRef = useGridApiContext();

    return (
        <GridToolbarContainer {...props}>
            <Button onClick={_ => console.log(getJson(apiRef))}>Export</Button>
        </GridToolbarContainer>
    )
}
const DataScope = () => {
    const {projectId} = useParams();
    const [editRowsModel, setEditRowsModel] = useState({});
    const [selectedTab, setSelectedTab] = useState(0)
    const [fieldModalOpen, setFieldModalOpen] = useState(false)
    const [defaultField, setDefaultField] = useState(null)
    const [editFieldModalOpen, setEditFieldModalOpen] = useState(false)
    const [columns, setColumns] = useState([
        {field: 'loadLevel', headerName: 'Load Sequence Level', minWidth: 150},
        {field: 'TO', headerName: 'Template Objects', minWidth: 150},
        {field: 'description', headerName: 'Template Object Description', editable: true, minWidth: 150},
        {field: 'type', headerName: 'Template Object Type', editable: true, minWidth: 150},
        {field: 'tags', headerName: 'Tags', editable: true, minWidth: 150},
        {field: 'status', headerName: 'TO Status', editable: true, minWidth: 150},
        {field: 'DO', headerName: 'Data Object', minWidth: 150},
        {field: 'STOEnabled', headerName: 'STO Enabled', minWidth: 150},
        {field: 'STONames', headerName: 'STO Names', minWidth: 150},
        {field: 'dependencies', headerName: 'Load Dependencies', editable: true, minWidth: 150},
        {field: 'method', headerName: 'Load Method', editable: true, minWidth: 150},
        {field: 'sourceSystem', headerName: 'Source System(s)', minWidth: 150},
        {field: 'sourceExtracts', headerName: 'Source Data Extracts', minWidth: 150},
        {field: 'targetSystem', headerName: 'Target System', minWidth: 150},
        {field: 'mappingProgress', headerName: 'Mapping Progress', minWidth: 150},
        {field: 'followUps', headerName: '# of Follow-Ups', minWidth: 150},
        {field: 'priority', headerName: 'Follow-Up Priority', minWidth: 150},
        {field: 'valueStreams', headerName: 'Value Streams', editable: true, minWidth: 150},
        {field: 'usersAssigned', headerName: 'Users Assigned', editable: true, minWidth: 150},
        {field: 'notes', headerName: 'Notes', editable: true, minWidth: 150},
        {field: 'stakeholder', headerName: 'Business Stakeholder', editable: true, minWidth: 150},
        {field: 'loadAttemptInfo', headerName: 'Latest Load Attempt Info', editable: true, minWidth: 200}
    ])

    const processRowUpdate = (newValue) => {
        // setFields(fields.map(el => el.id === newValue.id ? newValue : el))
        console.log(newValue)
        return newValue
    }

    const handleEditRowModelChange = useCallback((params) => {
        setEditRowsModel(params.model);
    }, []);

    const handleBlur = useCallback((params, event) => {
        event.stopPropagation();
    }, []);

    return (
        <>
            <Header activePage='View Data Scope' projectId={projectId}/>
            <MainModal headerText='Add New Field' Child={AddNewFieldModal} modalOpen={fieldModalOpen}
                       setModalOpen={setFieldModalOpen} columns={columns} setColumns={setColumns}
                       setOpen={setFieldModalOpen}/>
            <MainModal headerText='Edit Field' Child={AddNewFieldModal} modalOpen={editFieldModalOpen}
                       setModalOpen={setEditFieldModalOpen} columns={columns} setColumns={setColumns}
                       defaultField={defaultField} setOpen={setEditFieldModalOpen}/>
            <div className='data-scope-page'>
                <Tabs value={selectedTab} onChange={(_, newValue) => setSelectedTab(newValue)}>
                    <Tab label='Detailed Summary View'/>
                    <Tab label='Graph View'/>
                </Tabs>
                {selectedTab === 0 && <>
                    <h1>Data Scope: Detailed Summary View <Tooltip title={<h2>tooltip</h2>}><QuestionMark
                        fontSize='large'/></Tooltip></h1>
                    <div className='control-buttons'>
                        <TextField variant='standard' InputProps={{
                            endAdornment: (<InputAdornment position='end'><SearchIcon/></InputAdornment>)
                        }}/>
                        <Button>Assign Tags</Button>
                        <Button onClick={_ => setFieldModalOpen(true)}>Add New Field</Button>
                        <FormControl sx={{minWidth: 140}}>
                            <InputLabel id='view-label'>Select View</InputLabel>
                            <Select id='view-label' variant={'standard'}>
                                <MenuItem value={'all'}>All</MenuItem>
                                <MenuItem value={'manager'}>Project Manager</MenuItem>
                                <MenuItem value={'engineer'}>Data Engineer</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <DataGrid columns={columns} rows={[{id: 0}, {id:1}]} hideFooter checkboxSelection
                              onColumnHeaderDoubleClick={e => {
                                  if (e.colDef.customUserField) {
                                      setDefaultField(e.colDef)
                                      setEditFieldModalOpen(true)
                                  }
                              }} slots={{toolbar: CustomToolbar}} editRowsModel={editRowsModel}
                              onEditRowModelChange={handleEditRowModelChange}
                              onCellBlur={handleBlur}/>
                </>}
            </div>
        </>
    )
}

export default DataScope