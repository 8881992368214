import {
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    InputAdornment,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import {useState} from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {CardActionArea} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import MainModal from "../../helpers/Modal/MainModal";
import AddModal from "./AddModal";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import './AddTeam.css'
import {normalize_color, stringToColor} from "../../helpers/utils/stringToColor";

const AddTeam = ({handleComplete, data, setData, handleBack, users, roles, creator, setHelpModalOpen}) => {
    const [search, setSearch] = useState('')
    const [modalOpen, setModalOpen] = useState(false)
    const [selectedUsers, setSelectedUsers] = useState(data.addTeam ? data.addTeam : [creator.username])
    const [userList, setUserList] = useState(users)
    const roleMap = Object.fromEntries(roles.map(el => [el.recid, el.trust_flag]))
    const [selectedRoles, setSelectedRoles] = useState(Object.fromEntries(userList.map(user => [user.username, {
        recid: 1,
        trusted: false,
        admin: user.username === creator.username
    }])))

    return (
        <div style={{padding: '3% 10% 1% 5%'}}>
            <MainModal modalOpen={modalOpen} setModalOpen={setModalOpen} Child={AddModal} headerText='Add users'
                       setModalOpenChild={setModalOpen} userList={userList} setUserList={setUserList}
                       selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} selectedRoles={selectedRoles}
                       setSelectedRoles={setSelectedRoles}/>
            <TextField sx={{width: '100%', padding: '0 0 1rem 0'}} id="filled-basic" placeholder='Search'
                       variant="filled" InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon/>
                    </InputAdornment>
                ),
            }} onChange={e => {
                setSearch(e.target.value)
            }}/>
            <div className='users-block'>
                {userList.filter(user => (user.first_name + ' ' + user.last_name).toLowerCase().includes(search.toLowerCase()) || user.username.toLowerCase().includes(search.toLowerCase())).map(user =>
                    <Card sx={{width: 281, height: 340}} raised={!selectedUsers.includes(user.username)}>
                        <CardActionArea onClick={_ => {
                            if (user.username !== creator.username) {
                                selectedUsers.includes(user.username) ? setSelectedUsers(selectedUsers.filter(el => el !== user.username)) :
                                    setSelectedUsers([...selectedUsers, user.username])
                            }
                        }}>
                            {selectedUsers.includes(user.username) &&
                                <CheckCircleIcon fontSize='large' color='primary' sx={{position: 'absolute'}}/>}

                            <CardContent className='card-cont'>
                                <Avatar sx={{
                                    width: 100,
                                    height: 100,
                                    bgcolor: normalize_color(stringToColor(user.id ? user.first_name + ' ' + user.last_name : user.username), 100)
                                }}
                                        aria-label="recipe" src={user.image_link}>
                                    {user.id ? `${user.first_name[0]} ${user.last_name[0]}` : user.username[0].toUpperCase()}
                                </Avatar>
                                <Typography gutterBottom variant="h6" sx={{whiteSpace: 'nowrap'}}>
                                    {user.id ? user.first_name + ' ' + user.last_name : ''}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {user.username}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <FormGroup sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                            <FormControlLabel disabled={user.username === creator.username}
                                              checked={user.username === creator.username || selectedRoles[user.username].admin}
                                              value={selectedRoles[user.username].admin} control={<Checkbox/>}
                                              label='Admin' onChange={(_, checked) => setSelectedRoles({
                                ...selectedRoles,
                                [user.username]: {...selectedRoles[user.username], admin: checked}
                            })}/>
                            <FormControlLabel sx={{display: !roleMap[selectedRoles[user.username].recid] ? 'none' : ''}}
                                              checked={selectedRoles[user.username].trusted} control={<Checkbox/>}
                                              label='Trusted' onChange={(_, checked) => setSelectedRoles({
                                ...selectedRoles,
                                [user.username]: {...selectedRoles[user.username], trusted: checked}
                            })}/>
                        </FormGroup>
                        <Select value={selectedRoles[user.username].recid} onChange={e => {
                            setSelectedRoles({
                                ...selectedRoles,
                                [user.username]: {
                                    ...selectedRoles[user.username],
                                    recid: e.target.value,
                                    trusted: selectedRoles[user.username].trusted && roleMap[e.target.value]
                                }
                            })
                        }} variant='standard' sx={{width: '80%', margin: '0 10%'}}>
                            {roles.map(role => <
                                MenuItem value={role.recid}>
                                {role.role_name}
                            </MenuItem>)}
                        </Select>
                    </Card>
                )}
                <Card sx={{width: 233}}>
                    <CardActionArea onClick={_ => setModalOpen(true)}>
                        <CardContent className='card-cont'>
                            <AddIcon sx={{width: 100, height: 100}}/>
                            <Typography variant="h6" color="text.secondary">
                                Add new
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </div>

            <div className='main-page-btns'>
                <Button className='help-button' onClick={_ => setHelpModalOpen(true)}>help</Button>
                <Button className='main-page-btn close-button' onClick={handleBack}>Back</Button>
                <Button className='main-page-btn complete-button' onClick={_ => {
                    const userTeam = selectedUsers.map(el => {
                        return {
                            username: el,
                            role_id: selectedRoles[el].recid,
                            is_trusted: selectedRoles[el].trusted && roleMap[selectedRoles[el].recid],
                            is_admin: selectedRoles[el].admin
                        }
                    })
                    setData({
                        ...data,
                        userTeam
                    })
                    handleComplete()
                }}>Next</Button>
            </div>

        </div>
    )
}

export default AddTeam