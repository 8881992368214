import {Button} from "@mui/material";
import pen from "../../ManageObjects/assets/pen.png";
import deleteImg from "../../ManageObjects/assets/delete.png";
import React, {useState} from "react";
import CustomToolbar from "../../../helpers/Editor/QuillEditor/CustomToolbar";
import ReactQuill from "react-quill";


const DesignMigrationRequirements = ({notes, selectedObject, editNote, setNotes, setEditNote}) => {
    const Editor = () => {
        const [text, setText] = useState(editNote ? editNote.text : '');

        const modules = {
            toolbar: {
                container: "#toolbar",
            },
        }

        const handleChange = (html) => {
            setText(html);
        }

        const handleDiscard = () => {
            setEditNote(null);
        }

        const setObject = (id) => {
            setNotes(notes.map(note => {
                if (note.id === editNote.id) {
                    return {
                        ...note,
                        text: text,
                        editedBy: 'Maksym Kovalev'
                    }
                }

                return note;
            }))
        }

        return (
            <div>
                <CustomToolbar/>
                <ReactQuill
                    style={{height: '15vh'}}
                    modules={modules}
                    onChange={handleChange}
                    formats={[
                        'font', 'size',
                        'bold', 'italic', 'underline', 'strike',
                        'color', 'background',
                        'script',
                        'header', 'blockquote', 'code-block',
                        'indent', 'list',
                        'direction', 'align',
                        'link', 'image', 'video', 'formula',
                    ]}
                    value={text}
                    // theme="snow"
                />

                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    padding: '1rem',
                    gap: '1.5rem',
                    borderLeft: '1px solid #ccc',
                    borderRight: '1px solid #ccc'
                }}>
                    <Button
                        variant='outlined'
                        color='error'
                        onClick={handleDiscard}

                    >Discard
                    </Button>
                    <Button
                        variant='contained'
                        onClick={setObject}

                    >Submit
                    </Button>

                </div>


            </div>
        )
    }

    return (
        <div style={{width: '60%', margin: '1rem auto'}}>
                    <div className='manage-notes-list-header'>
                        <h1 style={{flex: 1, fontSize: 60, marginBottom: 0, marginTop: 0}}>{selectedObject.id}</h1>
                        <div style={{flex: 18, display: 'flex', flexDirection: 'column'}}>
                            <h2 style={{marginBottom: '1rem', marginTop: 0}}>{selectedObject.name}</h2>
                            <span><strong>Template Object:</strong> {selectedObject.templateObject}</span>
                            <span>Last Post Date: {selectedObject.lastPostDate}</span>
                        </div>

                        <Button variant='contained' sx={{flex: 2}}>Copy link</Button>

                    </div>

                    {
                        notes.map(note => {
                            return (
                                <div className='mapping-view-note' style={editNote === note ? {
                                    margin: '1rem 0',
                                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
                                } : null}>
                                    <div className='mapping-view-note-content'
                                         style={editNote === note ? {backgroundColor: '#d2dbdd'} : null}>
                                        <span
                                            style={{color: 'gray'}}>{note.author} | {note.date} {note.dateEdited ? '(Edited)' : null}
                                        </span>

                                        <div
                                            style={{fontSize: 18, marginTop: '0.3rem', fontWeight: 400, height: '5rem'}}
                                            dangerouslySetInnerHTML={{__html: note.text}}/>
                                    </div>


                                    {editNote !== note &&
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            marginTop: '3.5rem',
                                            marginRight: '1rem',
                                            paddingBottom: '1rem',
                                            gap: '0.7rem'
                                        }}>
                                        <span
                                            style={{color: '#8c8c8c'}}>Last Edited By: {note.editedBy} | {note.date}
                                        </span>
                                            <img
                                                onClick={_ => setEditNote((prev) => prev === note ? null : note)}
                                                style={{width: '20px', height: '20px', cursor: 'pointer'}}
                                                src={pen}>

                                            </img>
                                            {
                                                !note.autoGenerate &&
                                                <img
                                                    onClick={_ => setNotes(notes.filter(el => el !== note))}
                                                    style={{width: '20px', height: '20px', cursor: 'pointer'}}
                                                    src={deleteImg}>

                                                </img>
                                            }


                                        </div>
                                    }

                                    {note === editNote &&
                                        <div style={{zIndex: 201}}>
                                            <Editor
                                                notes={notes}
                                                setNotes={setNotes}
                                            />

                                        </div>
                                    }
                                </div>
                            )
                        })
                    }

                </div>
    )
}

export default DesignMigrationRequirements;