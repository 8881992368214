import {useState} from "react";
import {Button, CardActionArea, CardMedia, TextField, Tooltip} from "@mui/material";
import MainModal from "../../helpers/Modal/MainModal";
import Card from "@mui/material/Card";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import ErrorIcon from '@mui/icons-material/Error';
import StorageIcon from '@mui/icons-material/Storage';
import {v4} from 'uuid';

const SystemAccordion = ({selectedSystems, setSelectedSystems, systems, header, error}) => {
    const [modalOpen, setModalOpen] = useState(false);

    const cardClickHandler = (card) => {
        console.log(card)
        selectedSystems.includes(card) ? setSelectedSystems(selectedSystems.filter(el => el !== card)) :
            setSelectedSystems([...selectedSystems, card])
    }

    const AddSystemModal = ({setChildModalOpen}) => {
        const [systemName, setSystemName] = useState(null);
        const [systemVersion, setSystemVersion] = useState(null);
        const [systemDescription, setSystemDescription] = useState(null);
        const [image, setImage] = useState();
        const [imgName, setImgName] = useState(null)
        const [file, setFile] = useState()
        const [error, setError] = useState(false);

        const handleUploadFile = (e) => {

            if (e.target.files[0]) {
                setImgName(e.target.files[0].name)
                setFile(e.target.files[0])
                setImage(URL.createObjectURL(e.target.files[0]))
            }
        }

        return (
            <div className='modal-section'>
                <TextField
                    required
                    error={error && !systemName}
                    className='system-modal-textfield'
                    id="standard-basic"
                    label="System Name:"
                    onChange={e => setSystemName(e.target.value)}
                    variant="standard"/>
                <TextField
                    required
                    error={error && !systemVersion}
                    className='system-modal-textfield'
                    id="standard-basic"
                    label="System Version:"
                    onChange={e => setSystemVersion(e.target.value)}
                    variant="standard"/>
                <TextField
                    className='system-modal-textfield system-modal-textfield-description '
                    id="outlined-multiline-static"
                    label="Description:"
                    onChange={e => setSystemDescription(e.target.value)}
                    multiline
                    rows={4}/>

                <div className='modal-btn-section'>
                    <Button
                        variant="contained"
                        component="label"
                    >
                        Upload File
                        <input
                            type="file"
                            hidden
                            multiple={false}
                            onChange={handleUploadFile}
                        />
                    </Button>
                    <Button
                        onClick={_ => {
                            if (!systemName || !systemVersion) {
                                setError(true)
                            } else {
                                setError(false)
                                systems.push({
                                    recid: v4(),
                                    system_name: systemName,
                                    image_url: image,
                                    system_version: systemVersion,
                                    description: systemDescription,
                                    custom: true,
                                    file: file
                                })
                                setChildModalOpen(false)
                            }
                        }}
                        variant="contained">
                        Save
                    </Button>
                </div>
                {imgName}
            </div>
        )
    }

    const Cards = () => {
        return (
            <>
                <MainModal modalOpen={modalOpen} setModalOpen={setModalOpen} headerText='Add New System'
                           Child={AddSystemModal} setChildModalOpen={setModalOpen}/>
                {
                    systems.map(card => {
                        return (
                            <Card
                                sx={{
                                    minWidth: 200,
                                    width: 290,
                                    borderRadius: 5
                                }}
                                raised={!selectedSystems?.includes(card.recid)}>

                                <CardActionArea onClick={_ => cardClickHandler(card.recid)}>
                                    {selectedSystems?.includes(card.recid) &&
                                        <CheckCircleTwoToneIcon fontSize='large' color='primary'
                                                                sx={{position: 'absolute'}}/>}
                                    {card.image_url ? <CardMedia
                                        component="img"
                                        height="140"
                                        width='100'
                                        image={card.image_url}
                                        sx={{objectFit: 'contain'}}
                                        alt="img"
                                    /> : <div style={{
                                        width: '100%',
                                        height: 140,
                                        display: "flex",
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <StorageIcon sx={{fontSize: 80}}/>
                                    </div>}
                                    <CardContent className='source-card-cont'>
                                        <Tooltip
                                            title={<h2>{card.description ? card.description : 'No description'}</h2>}>
                                            <Typography gutterBottom variant="h7" component="div">
                                                {card.system_name + ' ' + card.system_version}
                                            </Typography>
                                        </Tooltip>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        )
                    })
                }
            </>
        )
    }

    const AddNewCard = () => {
        return (
            <>
                <Card sx={{minWidth: 200, width: 250, borderRadius: 5}}>
                    <CardActionArea onClick={_ => {
                        setModalOpen(true)
                    }}>
                        <CardContent className='card-cont'>
                            <AddIcon sx={{width: 100, height: 100}}/>
                            <Typography variant="h7" color="text.secondary">
                                Add new
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </>
        )
    }

    return (
        <Accordion sx={{width: '85%', margin: 'auto!important'}} defaultExpanded={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <Typography variant={'h4'}>{header} {error &&
                    <ErrorIcon fontSize='large' sx={{color: 'rgb(211, 47, 47)'}}/>}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div className='card-section'>
                    <Cards/>
                    <AddNewCard/>
                </div>
            </AccordionDetails>
        </Accordion>
    )
}

export default SystemAccordion;