import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {TextField, Button} from "@mui/material";
import {useState} from "react";
import './AddModal.css'
import {addUsers} from "../../api/requests";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";

const AddModal = ({
                      setModalOpenChild,
                      userList,
                      setUserList,
                      selectedUsers,
                      setSelectedUsers,
                      selectedRoles,
                      setSelectedRoles
                  }) => {
    const [newUserList, setNewUserList] = useState([])
    const [username, setUsername] = useState('');
    const [inputInvalid, setInputInvalid] = useState(false)
    const [invalidMessage, setInvalidMessage] = useState();
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    const usernames = userList.map(el => el.username)

    return (
        <>
            <div className='add-modal'>
                {error && newUserList.some(el => usernames.includes(el)) &&
                    <Typography variant={'h5'} sx={{color: '#ef9a9a'}}> User emails already exist</Typography>}
                <div className='text-field-add'>
                    <TextField error={inputInvalid}
                               variant='standard'
                               helperText={inputInvalid ? invalidMessage : ''}
                               sx={{width: '75%'}}
                               placeholder='Enter email'
                               onChange={event => {
                                   setUsername(event.target.value)
                               }}/>
                    <span style={{fontSize: '38px', cursor: 'pointer'}} onClick={() => {
                        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(username)) {
                            setInputInvalid(true)
                            setInvalidMessage("Please enter a valid email.")
                        } else if (newUserList.includes(username) ||usernames.includes(username)) {
                            setInputInvalid(true)
                            setInvalidMessage("Duplicate user email.")
                        } else {
                            setInputInvalid(false)
                            setNewUserList([...newUserList, username])
                        }
                    }}>
                    <AddIcon/>
                </span>
                </div>
                {newUserList.map((el) => {
                    return (<div className='users-list-el'>
                        <Typography variant={'span'}
                                    sx={error && usernames.includes(el) && {color: '#ef9a9a'}}>{el}</Typography>
                        <span onClick={_ => setNewUserList(newUserList.filter(e => e !== el))}
                              style={{fontSize: '38px', cursor: 'pointer'}}><RemoveIcon/></span>
                    </div>)
                })}
                <hr/>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <LoadingButton loading={loading} onClick={async _ => {
                        if (!newUserList.some(el => usernames.includes(el))) {
                            setLoading(true)
                            await addUsers(newUserList, localStorage.getItem('token'))
                            setUserList([...userList, ...newUserList.map(el => {
                                return {
                                    username: el,
                                    permissions: ['atom.user', 'atom.service.smt', 'atom.service.harmonization']
                                }
                            })])
                            setSelectedUsers([...selectedUsers, ...newUserList])
                            setSelectedRoles({
                                ...selectedRoles, ...Object.fromEntries(newUserList.map(el => [el, {
                                    recid: 1,
                                    trusted: false,
                                    admin: false
                                }]))
                            })
                            setLoading(false)
                            setModalOpenChild(false)
                        } else {
                            setError(true)
                        }
                    }}>
                        <span>Add users</span>
                    </LoadingButton>
                </div>
            </div>
        </>
    )
}

export default AddModal