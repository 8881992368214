import {Accordion, AccordionSummary, AccordionDetails, Typography} from "@mui/material";
import './ViewWarning.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ViewWarnings = () => {
    const errors = [
        {
            status: 'Critical',
            errorMsg: 'Error: Sample Traceback error: Sample Traceback error: Sample Traceback error: Sample Sample Traceback error: Error: Sample Traceback error: Sample Traceback error: Sample Traceback error: Sample Sample Traceback error: Sample Error: Sample Traceback Sample Error: Sample Traceback error: Sample Traceback error: Sample Traceback error: Sample Sample Traceback error: Sample Error: Sample Traceback error: Sample Traceback error: Sample Traceback error: Sample Sample Traceback error: Sample',
        },
        {
            status: 'Informational',
            errorMsg: 'Traceback error: Sample',
        },
        {
            status: 'Informational',
            errorMsg: 'Error: Sample Traceback error: Sample Traceback error: Sample Traceback error: Sample Sample Traceback error: Sample Error: Sample Traceback error: Sample Traceback error: Sample Traceback error: Sample Sample Traceback error: Sample Error: Sample Traceback error: Sample Traceback error: Sample Traceback error: Sample Sample Traceback error: Sample',
        },
    ]

    return (
        <div className='ingestion-error-accordion-container ingestion-error-accordion-scroll'>

            {
                errors.map(error => {
                    return (
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon size='large' />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <div className='ingestion-error-accordion-summary' style={{width: '95%'}}>
                                    <span className='truncate-text'>{error.errorMsg}</span>
                                    <span>{error.status}</span>
                                    <span>timestamp</span>
                                </div>
                            </AccordionSummary>

                            <AccordionDetails>
                                <Typography className='ingestion-error-accordion-details-header' variant='h4'>
                                    Warning Message
                                </Typography>


                                <Typography className='ingestion-error-accordion-error-msg ingestion-error-accordion-scroll' style={{maxHeight: '10vh'}}>
                                    {error.errorMsg}
                                </Typography>

                            </AccordionDetails>
                        </Accordion>
                    )
                })
            }
        </div>
    )
}

export default ViewWarnings;