import {useEffect, useState} from "react";
import './landingPage.css';
import {Chip, CircularProgress, IconButton, TextField, Tooltip} from "@mui/material";
import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import {useNavigate, useSearchParams} from "react-router-dom";
import Header from "../helpers/header/Header";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import {CardActionArea} from '@mui/material';
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import {getAllProjects} from "../api/requests";


dayjs.extend(relativeTime);
dayjs.extend(utc)
dayjs.extend(timezone)

const LandingPage = () => {
    const [projectCards, setProjectCards] = useState([])
    const [userData, setUserData] = useState()
    const [loading, setLoading] = useState(true)
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const token = searchParams.get('token')

        if (token) {
            localStorage.setItem('token', token)
        }

        async function getProjects() {
            let projects = await getAllProjects(localStorage.getItem('token'))
            projects = projects.data.projects
            setProjectCards(projects)
        }

        getProjects().then(_ => setLoading(false))


    }, [userData])

    const [input, setInput] = useState('')
    const navigate = useNavigate()
    return (
        <>
            <Header setUserData={setUserData}/>
            {loading ? <CircularProgress size={'10rem'} sx={{position: 'absolute', top: '40vh', left: 'calc((100vw - 10rem) / 2)'}}/> :
                <div className='landing-page'>
                    {userData?.permissions.some(el => ['atom.admin', 'atom.superadmin', 'atom.project.create'].includes(el)) &&
                        <div className='project-section'>
                            <h2>Create a project</h2>
                            <Card sx={{width: '26rem'}}>
                                <CardContent>
                                    <h1>New Project</h1>
                                    <Chip label='New Project' color='primary'/>
                                </CardContent>
                                <CardActions sx={{justifyContent: 'flex-end'}}>
                                    <IconButton onClick={_ => {
                                        navigate(`create_project`)
                                    }}>
                                        <AddIcon color='primary' sx={{fontSize: 50}}/>
                                    </IconButton>
                                </CardActions>
                            </Card>
                        </div>
                    }
                    <div className='project-section'>
                        <div className='projects-search-section'>
                            <h2 className='all-projects-label'>All Projects</h2>
                            <TextField placeholder='Search' variant="standard"
                                       onChange={event => setInput(event.target.value)}
                                       id="outlined-basic"/>
                        </div>

                        <div className='projects-list'>
                            {projectCards.filter(el => el?.project_name?.toLowerCase().includes(input.toLowerCase())).map(card => {
                                return <Card sx={{width: '100%', marginBottom: 2}}>
                                    <CardActionArea onClick={() => {
                                        navigate(`project/${card.recid}`)
                                    }}>
                                        <CardContent>
                                            <h1>
                                                {card?.project_name}
                                            </h1>
                                            <Typography variant="subtitle2">
                                                {card.project_start_date ? dayjs(card.project_start_date).tz(userData?.timezone).format(userData?.date_format === 'mm' ? 'MM/DD/YY' : 'DD/MM/YY') : 'TBD'} - {card.project_end_date ? dayjs(card.project_end_date).format(userData?.date_format === 'mm' ? 'MM/DD/YY' : 'DD/MM/YY') : 'TBD'}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <CardContent>
                                        <Tooltip title={<h2>{card.tag_description}</h2>}>
                                            <Chip label={card.tag_name}
                                                  sx={{
                                                      marginLeft: .5,
                                                      marginTop: .2,
                                                      backgroundColor: card.tag_color
                                                  }}/>
                                        </Tooltip>
                                    </CardContent>
                                    <CardContent sx={{maxHeight: 90, overflow: 'auto'}}>
                                        <Typography variant="body1" color="text.secondary">
                                            {card.description}
                                        </Typography>
                                        <Typography variant='body2'>
                                            Source
                                            Systems: {card.source_systems ? card.source_systems.join(', ') : 'None'}
                                        </Typography>
                                        <Typography variant='body2'>
                                            Target
                                            Systems: {card.target_systems ? card.target_systems.join(', ') : 'None'}
                                        </Typography>
                                    </CardContent>

                                </Card>
                            })}
                        </div>
                    </div>
                </div>
            }
        </>
    )

}

export default LandingPage;
