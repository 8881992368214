import './ManageLoadDataModal.css';
import {Button} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";

import download from '../../assets/download.png';
import manage from '../../assets/manage.png';
import search from '../../assets/search.png';
import poll from '../../assets/poll.png';
import warning from '../../assets/warning.png';
import {useState} from "react";
import UploadLoadDatasetModal from "../UploadLoadDatasetModal/UploadLoadDatasetModal";
import MainModal from "../../../../helpers/Modal/MainModal";
import DataIngestionErrorModal from "../../DataIngestionErrorModal/DataIngestionErrorModal";


const ManageLoadDataModal = () => {
    const [uploadLoadFile ,setUploadLoadFile] = useState(false);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [dataError ,setDataError] = useState(false);

    const fileColumns = [
        {
            field: 'loadFileName',
            headerName: 'Load File Name',
            width: 250
        },
        {
            field: 'size',
            headerName: 'Size',
            width: 150,
        },
        {
            field: 'uploadedBy',
            headerName: 'Uploaded By',
            width: 150,
        },
        {
            field: 'uploadedDate',
            headerName: 'Uploaded Date',
            width: 200,
        },
        {
            field: 'downloadFile',
            headerName: 'Download File',
            width: 120,
            renderCell: (params) => {
                return <img className='manage-load-data-file-table-icon manage-load-data-table-icon' src={download}></img>
            },
        },
        // {
        //     field: 'manageErrorLogs',
        //     headerName: 'Manage Error Logs',
        //     width: 150,
        //     renderCell: (params) => {
        //         return <img className='manage-load-data-file-table-icon manage-load-data-table-icon' src={manage}></img>
        //     },
        // },
    ]

    const tableColumns = [
        {
            field: 'templateObject',
            headerName: 'Template Object',
            width: 250
        },
        {
            field: 'subTemplateObject',
            headerName: 'Sub-Template Object',
            width: 300,
        },
        {
            field: 'dimensions',
            headerName: 'Dimensions',
            width: 200,

        },
        {
            field: 'viewData',
            headerName: 'View Data',
            width: 200,
            renderCell: (params) => {
                return (
                    <div style={{display: 'flex', flexDirection: 'row', gap: '1rem'}}>
                        <img className='manage-load-data-table-icon'  src={search}></img>
                        <img className='manage-load-data-table-icon'  src={poll}></img>
                    </div>
                )
            },
        },
    ]


    const fileRows = [
        {
            id: 1,
            loadFileName: 'Materials_01.xml',
            size: '100 KB',
            uploadedBy: 'Colin Bryan',
            uploadedDate: 'timestamp',
        },
        {
            id: 2,
            loadFileName: 'Materials_01.xml',
            size: '100 KB',
            uploadedBy: 'Colin Bryan',
            uploadedDate: 'timestamp',
        },
        {
            id: 3,
            loadFileName: 'Materials_01.xml',
            size: '100 KB',
            uploadedBy: 'Colin Bryan',
            uploadedDate: 'timestamp',
        },
        {
            id: 4,
            loadFileName: 'Materials_01.xml',
            size: '100 KB',
            uploadedBy: 'Colin Bryan',
            uploadedDate: 'timestamp',
        },
    ]

    const tableRows = [
        {
            id: 1,
            templateObject: 'Materials',
            subTemplateObject: 'Basic Data',
            dimensions: '100x52',
        },
        {
            id: 2,
            templateObject: 'Materials',
            subTemplateObject: 'Plant Data',
            dimensions: '100x52',
        },
        {
            id: 3,
            templateObject: 'Materials',
            subTemplateObject: 'Storage Location Data',
            dimensions: '100x52',
        },
        {
            id: 4,
            templateObject: 'Materials',
            subTemplateObject: 'Alternative Units of Measure',
            dimensions: '300x105',
        },
    ]

    return (
        <div className='manage-load-data-main'>
            <div className='manage-load-data-error-section' style={{cursor: 'pointer'}} onClick={_ => setDataError(true)}>
                <img style={{width: '25px'}} src={warning}></img>
                <span>View Data Ingestion Errors (2)</span>
            </div>

            <div className='manage-load-data-tables-section'>
                <div className='manage-load-data-table-container'>
                    <h2 className='manage-load-data-header'>Analyze Load Data</h2>
                    <span>Select a table to view the data in the Mapping View</span>
                    <h2 style={{marginTop: '2rem'}}>Table Count: 4</h2>

                    <div className='manage-load-data-table'>
                        <DataGrid
                            rows={tableRows}
                            columns={tableColumns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 5,
                                    },
                                },
                            }}
                            pageSizeOptions={[5]}
                            disableRowSelectionOnClick
                            selection
                        />
                    </div>


                </div>

                <hr style={{margin: '1rem'}}/>

                <div className='manage-load-data-table-container'>
                    <h2 className='manage-load-data-header'>Manage Load Files</h2>
                    <span>Choose tags to assign to the selected Template Objects from the left pane</span>
                    <div className='manage-load-data-file-header-container'>
                        <h2 style={{marginTop: 0}}>File Count: 4</h2>
                        <div className='manage-load-data-file-header-btn-container'>
                            <Button variant='contained'>Download All</Button>
                            <Button
                                onClick={_ => setUploadLoadFile(true)}
                                variant='contained'>Upload File(s)
                            </Button>
                            <Button
                                variant='outlined'
                                disabled={!rowSelectionModel.length}
                                color='error'>Delete File(s)
                            </Button>
                        </div>
                    </div>


                    <div>
                        <DataGrid
                        checkboxSelection
                        rows={fileRows}
                        columns={fileColumns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        pageSizeOptions={[5]}
                        disableRowSelectionOnClick
                        selection
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                                setRowSelectionModel(newRowSelectionModel);
                            }}
                            rowSelectionModel={rowSelectionModel}
                    />
                    </div>

                </div>
            </div>

            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button className='manage-load-data-save-close-btn' variant='contained'>Save & Close</Button>
            </div>

            <MainModal
                tooltip={true}
                modalOpen={uploadLoadFile}
                setModalOpen={setUploadLoadFile}
                headerText='Upload Load File(s)'
                Child={UploadLoadDatasetModal}/>

            <MainModal
                tooltip={true}
                modalOpen={dataError}
                setModalOpen={setDataError}
                headerText='Display Data Ingestion Errors'
                Child={DataIngestionErrorModal}

            />

        </div>
    )
}

export default ManageLoadDataModal;