import {useEffect, useState} from "react";
import {Alert, Autocomplete, Box, Button, CircularProgress, Tab, Tabs, TextField, Tooltip} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
    DataGrid,
    GridToolbarColumnsButton,
    GridToolbarContainer, GridToolbarDensitySelector,
    GridToolbarFilterButton,
    useGridApiContext
} from "@mui/x-data-grid";
import Header from "../../helpers/header/Header";
import {useNavigate, useParams} from "react-router-dom";
import {Help, Save} from "@mui/icons-material";
import RemoveIcon from "@mui/icons-material/Remove";
import {
    authProjectSettings,
    getAllUserData,
    getProjectPermissionsUsers,
    getUserGroups, updateUserGroups
} from "../../api/requests";
import Avatar from "@mui/material/Avatar";
import {normalize_color, stringToColor} from "../../helpers/utils/stringToColor";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";

var isEqual = require('lodash.isequal');


const ProjectSettings = () => {
    const [value, setValue] = useState(0)
    const [user, setUser] = useState({})
    const {projectId} = useParams();
    const {navigate} = useNavigate()

    useEffect(() => {
        const token = localStorage.getItem('token')
        authProjectSettings(projectId, token).catch(_ => navigate('/'))
    }, []);

    const ManageUserGroups = () => {
        const CustomAutocomplete = (props) => {
            const {id, value, api, field} = props;
            const [userValues, setUserValues] = useState(value)
            const [roles, setRoles] = useState({})
            useEffect(() => {
                setRoles(Object.fromEntries(allProjectUserData?.map(el => [el.username, el.role_name])))
            }, []);
            const handleChange = (e, newValue) => {
                setUserValues(newValue)
                api.setEditCellValue({id, field, value: newValue})
                e.stopPropagation()
            }
            return <Autocomplete
                fullWidth
                multiple
                value={userValues}
                onChange={(e, newValue) => handleChange(e, newValue)}
                options={allProjectUserData.map(el => el.username)}
                getOptionLabel={(option) => option}
                disableCloseOnSelect
                renderOption={(props, option, {selected}) => {
                    const user = allUserData[option]
                    const role = roles[option]

                    return (
                        <li {...props} >
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                padding: '0 2rem 0 1rem'
                            }}>
                                <CardContent className='manage-objects-user-container' sx={{width: '80%'}}>
                                    <Avatar sx={{
                                        width: 45,
                                        height: 45,
                                        fontSize: '0.8rem',
                                        bgcolor: normalize_color(stringToColor(user?.id ? user.first_name + ' ' + user.last_name : user?.username), 100)
                                    }}
                                            aria-label="recipe" src={user.image_link}>
                                        {`${user.first_name[0]} ${user.last_name[0]}`}
                                    </Avatar>
                                    <Typography gutterBottom variant="h6" sx={{whiteSpace: 'nowrap'}}>
                                        {user?.id ? user.first_name + ' ' + user.last_name : ''}
                                    </Typography>
                                    <Box sx={{width: '30rem'}}>
                                        <Typography variant="body2" color="text.secondary">
                                            {[user.first_name, user.last_name].join(' ')}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {role}
                                        </Typography>
                                    </Box>
                                </CardContent>

                                <div style={{width: '4rem'}}>
                                    {option?.username ? 'User' : 'User Group'}
                                </div>
                            </div>
                        </li>
                    )
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label="Users:"
                        placeholder="Search"
                    />
                )}
            />
        }

        const RenderAutocomplete = (props) => {
            return <CustomAutocomplete {...props}/>
        }

        const columns = [
            {
                field: 'is_value_stream',
                headerName: 'Is Value Stream?',
                minWidth: 150,
                type: 'boolean',
                editable: true
            },
            {
                field: 'ug_name',
                headerName: 'Name',
                editable: true,
                minWidth: 200,
                flex: 1,
                cellClassName: (params) => {
                    if (!nameError) {
                        return ''
                    }
                    if (!params.value || userGroups.filter(el => el.ug_name === params.value).length > 1) {
                        return 'erroneous-cell'
                    }
                }
            },
            {field: 'description', headerName: 'Description', editable: true, minWidth: 200, flex: 1},
            {
                field: 'users', headerName: 'Users', editable: true, minWidth: 200, flex: 1,
                renderCell: cellValues => {
                    return <div style={{display: 'flex', flexDirection: 'column', gap: '.5rem'}}>
                        {cellValues.formattedValue?.map(el => {
                            const user = allUserData[el]
                            return <div style={{display: "flex", alignItems: 'center', gap: '.5rem'}}>
                                <Avatar sx={{
                                    width: 40,
                                    height: 40,
                                    bgcolor: normalize_color(stringToColor(user?.first_name ? user.first_name + ' ' + user.last_name : el), 100)
                                }}
                                        aria-label="recipe" src={user.image_link}>
                                    {user?.first_name ? `${user.first_name[0]} ${user.last_name[0]}` : el[0].toUpperCase()}
                                </Avatar>
                                {user?.first_name ? user.first_name + ' ' + user.last_name : el}
                            </div>
                        })}
                    </div>
                },
                renderEditCell: RenderAutocomplete,

            },
            {
                field: 'created_by',
                headerName: 'Created By',
                editable: false,
                minWidth: 200,
                flex: 1,
                renderCell: cellValues => {
                    const el = cellValues.formattedValue
                    const user = allUserData[el]
                    return <div style={{display: "flex", alignItems: 'center', gap: '.5rem'}}>
                        <Avatar sx={{
                            width: 40,
                            height: 40,
                            bgcolor: normalize_color(stringToColor(user?.first_name ? user.first_name + ' ' + user.last_name : el), 100)
                        }}
                                aria-label="recipe" src={user.image_link}>
                            {user?.first_name ? `${user.first_name[0]} ${user.last_name[0]}` : el[0].toUpperCase()}
                        </Avatar>
                        {user?.first_name ? user.first_name + ' ' + user.last_name : el}
                    </div>
                }
            }
        ]
        const [userGroups, setUserGroups] = useState()
        const [allUserData, setAllUserData] = useState()
        const [allProjectUserData, setAllProjectUserData] = useState()
        const [loading, setLoading] = useState(true)
        const [changes, setChanges] = useState(false)
        const [saveLoading, setSaveLoading] = useState(false)
        const [nameError, setNameError] = useState(false)

        useEffect(() => {
            const token = localStorage.getItem('token')
            getUserGroups(projectId, token).then(r => {
                setUserGroups(r.data.user_groups)
                getProjectPermissionsUsers(projectId, token).then(r => setAllProjectUserData(r.data.users))
                getAllUserData(token).then(data => {
                    setAllUserData(data.data.users)
                    setLoading(false)
                })
            })
        }, []);
        const CustomToolbar = (props) => {
            const apiRef = useGridApiContext();

            return (
                <GridToolbarContainer {...props}>
                    <GridToolbarColumnsButton/>
                    <GridToolbarFilterButton/>
                    <GridToolbarDensitySelector/>
                    <Button startIcon={<AddIcon/>}
                            onClick={_ => {
                                setUserGroups([...userGroups, {
                                    recid: null,
                                    created_by: user.username,
                                    is_value_stream: false
                                }])
                                setChanges(true)
                                setNameError(false)
                            }}>Add new row
                    </Button>
                    <Button startIcon={<RemoveIcon/>}
                            onClick={_ => {
                                setUserGroups(userGroups?.filter((el, i) => !apiRef.current.getSelectedRows().has(i)))
                                apiRef.current.setRowSelectionModel([])
                                setChanges(true)
                                setNameError(false)
                            }}>Remove</Button>
                    <LoadingButton loading={saveLoading} disabled={!changes} startIcon={<Save/>}
                                   onClick={_ => {
                                       const token = localStorage.getItem('token')
                                       const data = Array.from(apiRef.current.getRowModels(), ([name, value]) => ({...value}))
                                           .map(({id, ...data}) => data)
                                       if (data.some(el => !el.ug_name) || new Set(data.map(el => el.ug_name)).size !== data.length) {
                                           setNameError(true)
                                       } else {
                                           setNameError(false)
                                           setSaveLoading(true)
                                           updateUserGroups(projectId, data, token).then(_ => {
                                               setLoading(true)
                                               getUserGroups(projectId, token).then(r => {
                                                   setUserGroups(r.data.user_groups)
                                                   setSaveLoading(false)
                                                   setLoading(false)
                                               }).catch(_ => {
                                                   setLoading(false)
                                                   setSaveLoading(false)
                                               })
                                           }).catch(_ => {
                                               setLoading(false)
                                               setSaveLoading(false)
                                           })
                                       }
                                   }}>
                        <span>Save</span>
                    </LoadingButton>
                    {nameError && <Alert severity={'error'}>Names must be unique and non-empty values</Alert>}
                </GridToolbarContainer>
            )
        }

        return (
            <div style={{width: '90%', margin: 'auto'}}>
                <h1 style={{display: 'flex', alignItems: 'center'}}>Manage User Groups<Tooltip
                    title='Description of what to do on this screen.'><Help color={'disabled'}
                                                                            fontSize={'large'}/></Tooltip>
                </h1>
                {loading ? <div
                        style={{display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
                        <CircularProgress size={'3rem'}/></div> :
                    <DataGrid columns={columns}
                              rows={userGroups?.map((el, id) => ({id, ...el}))} hideFooter
                              sx={{maxHeight: '70vh'}}
                              disableRowSelectionOnClick getRowHeight={() => 'auto'}
                              checkboxSelection slots={{toolbar: CustomToolbar}}
                              processRowUpdate={(newValue, oldValue) => {
                                  if (!isEqual(oldValue, newValue)) {
                                      setChanges(true)
                                      setUserGroups(userGroups.map((el, id) => (id === newValue.id ? {...newValue} : el)))
                                  }
                                  return newValue
                              }}/>}
            </div>
        )
    }

    return (
        <>
            <Header activePage='Project Settings' projectId={projectId} setUserData={setUser}/>
            <Tabs value={value} onChange={(_, newValue) => setValue(newValue)}>
                <Tab label='Manage User Groups'/>
            </Tabs>
            {value === 0 && <ManageUserGroups/>}
        </>
    )
}

export default ProjectSettings