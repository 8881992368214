import {Button, CircularProgress, IconButton, InputLabel, MenuItem, Tab, Tabs, TextField} from "@mui/material";
import {useCallback, useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import './AddTemplates.css'
import MainModal from "../../../helpers/Modal/MainModal";
import {DataGrid} from "@mui/x-data-grid";
import DeleteIcon from '@mui/icons-material/Delete';
import PreviewIcon from '@mui/icons-material/Preview';
import {getDataIngestionMethods, getProjectTargetSystems} from "../../../api/requests";

const AddTemplates = ({project_id}) => {
    const [value, setValue] = useState(0)
    const [files, setFiles] = useState([])
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [targetSystems, setTargetSystems] = useState([])
    const [dims, setDims] = useState([])
    const [selectedSystem, setSelectedSystem] = useState()
    const [selectedDim, setSelectedDim] = useState()
    const onDrop = useCallback(acceptedFiles => {
        setFiles([...files, ...acceptedFiles])
    }, [])

    useEffect(() => {
        const token = localStorage.getItem('token')
        setLoading(true)
        getProjectTargetSystems(project_id, token).then(ts => {
            setTargetSystems(ts.data.project_target_systems)
            getDataIngestionMethods(project_id, token).then(r => {
                setDims(r.data.dims)
                setLoading(false)
            }).catch(_ => setLoading(false))
        }).catch(_ => setLoading(false))
    }, []);

    const ReviewTO = ({setWarned}) => {
        const [open, setOpen] = useState()
        const [fields, setFields] = useState([
            {id: 0, name: 'Material', status: 'Successful', statusDetails: 'Successful', sto: 'Yes'}
        ])
        const processRowUpdate = useCallback((newValue) => {
            setFields(fields.map(el => el.id === newValue.id ? newValue : el))
            return newValue
        }, []);
        const TechnicalTable = ({setWarned}) => {
            const rows = [{
                id: 0,
                funcName: 'Material',
                techName: 'MATNR',
                description: '',
                type: 'Char',
                length: '40',
                mandatory: 'Yes'
            }]
            const columns = [
                {field: 'funcName', headerName: 'Functional Field Name', editable: true, minWidth: 150, flex: 1},
                {field: 'techName', headerName: 'Technical Field Name', editable: true, minWidth: 150, flex: 1},
                {field: 'description', headerName: 'Description', editable: true, minWidth: 150, flex: 1},
                {field: 'type', headerName: 'Type', editable: true, minWidth: 150, flex: 1},
                {field: 'length', headerName: 'Length', editable: true, minWidth: 150, flex: 1},
                {field: 'precision', headerName: 'Precision', editable: true, minWidth: 150, flex: 1},
                {field: 'mandatory', headerName: 'Mandatory for System', editable: true, minWidth: 150, flex: 1},
            ]
            return (<>
                <div className='material-sto-block'>
                    <TextField variant='standard' label='Technical Table Name' sx={{flex: 1}}/>
                    <FormControl sx={{flex: 1}}>
                        <InputLabel id='STO-select'>STO</InputLabel>
                        <Select id='STO-select' variant='standard'>
                            <MenuItem>1</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <DataGrid columns={columns} rows={rows} hideFooter className='datagrid' sx={{width: 1300}}/>
                <hr/>
                <Button variant='contained' onClick={_ => setWarned(true)}>Save</Button>
            </>)
        }
        const columns = [
            {
                field: 'buttons', headerName: '', renderCell: (cellValues) => {
                    return (
                        <>
                            <IconButton>
                                <DeleteIcon/>
                            </IconButton>
                            <IconButton onClick={_ => setOpen(true)}>
                                <PreviewIcon/>
                            </IconButton>
                        </>
                    )
                }
            },
            {field: 'name', headerName: 'Template Object Name', editable: true, minWidth: 200, flex: 1},
            {field: 'status', headerName: 'Status', editable: false, minWidth: 200, flex: 1},
            {field: 'statusDetails', headerName: 'Status Details', editable: false, minWidth: 200, flex: 1},
            {field: 'sto', headerName: 'STOs Enabled?', editable: false, minWidth: 200, flex: 1},
        ]
        return (
            <div>
                <MainModal modalOpen={open} setModalOpen={setOpen} headerText='Material' Child={TechnicalTable}
                           needsWarning={true}/>
                <DataGrid getRowId={row => row.id} columns={columns} rows={fields} hideFooter className='datagrid'
                          processRowUpdate={processRowUpdate} onProcessRowUpdateError={e => console.log(e)}
                          sx={{width: 1000}}/>
                <hr/>
                <Button variant='contained' onClick={_ => setWarned(true)}>Save</Button>
            </div>
        )
    }

    const {getRootProps, getInputProps} = useDropzone({
        onDrop
    })
    return (
        <>
            <Tabs value={value} onChange={(_, newValue) => setValue(newValue)}>
                <Tab label='Mass Upload'/>
                <Tab label='Add Via Spreadsheet'/>
            </Tabs>
            {value === 0 && <>
                <MainModal modalOpen={open} setModalOpen={setOpen} headerText='Review Uploaded Template Objects'
                           Child={ReviewTO} needsWarning={true}/>
                <h2>Add Templates</h2>
                <h4>Add template objects through mass upload</h4>
                {loading ? <div style={{display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
                        <CircularProgress size={'3rem'}/></div> :
                    <div className='files-upload-block'>
                        <h4 style={{alignSelf: 'baseline'}}>Upload Files:</h4>
                        <FormControl fullWidth>
                            <InputLabel id='target-system-label'>Target System</InputLabel>
                            <Select labelId='target-system-label' label={'Target System'} value={selectedSystem}
                                    onChange={e => setSelectedSystem(e.target.value)}>
                                {targetSystems?.map(el => <MenuItem value={el.recid}>
                                    {el.system_name} {el.system_version}
                                </MenuItem>)}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id='data-ingestion-label'>Data Ingestion Method</InputLabel>
                            <Select labelId='data-ingestion-method-label' label={'Data Ingestion Method'}
                                    value={selectedDim} onChange={e => setSelectedDim(e.target.value)}>
                                {dims?.map(el => <MenuItem value={el.recid}>
                                    {el.dim_name}
                                </MenuItem>)}
                            </Select>
                        </FormControl>
                        <div className='dropzone-area' {...getRootProps()}> Drag your file or select file
                            <input className='dropzone-area' {...getInputProps()} />
                        </div>
                        <div
                            style={{display: 'flex', width: '100%', alignItems: 'center'}}>
                            <Button variant='contained' sx={{alignSelf: 'end'}}
                                    onClick={_ => setOpen(true)}>Preview</Button>
                        </div>
                    </div>}
            </>}
        </>)
}

export default AddTemplates