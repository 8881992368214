import './PostLoadProcessingModal.css';
import {DataGrid} from "@mui/x-data-grid";
import {Button} from "@mui/material";
import {useState} from "react";
import MainModal from "../../../../helpers/Modal/MainModal";
import ManageErrorLogsModal from "./ManageErrorLogsModal/ManageErrorLogsModal";

const PostLoadProcessingModal = () => {
    const [manageErrorLogsModal, setManageErrorLogsModal] = useState(false);

    const rows = [
        {
            id: 1,
            loadDatasetName: 'Materials_2023_06_23',
            loadDuration: '',
            attemptedRecordCount: '',
            successfulRecordCount: '',
            successfulPercentage: '',
        },
        {
            id: 2,
            loadDatasetName: 'Materials_2023_06_23',
            loadDuration: '',
            attemptedRecordCount: '',
            successfulRecordCount: '',
            successfulPercentage: '',
        },
        {
            id: 3,
            loadDatasetName: 'Materials_2023_06_23',
            loadDuration: '',
            attemptedRecordCount: '',
            successfulRecordCount: '',
            successfulPercentage: '',
        },
    ]

    const columns = [
        {
            field: 'loadDatasetName',
            headerName: 'Load Dataset Name',
            width: 200,
        },
        {
            field: 'loadDuration',
            headerName: 'Load Duration',
            width: 200,
            editable: true,
        },
        {
            field: 'attemptedRecordCount',
            headerName: 'Attempted Record Count',
            width: 200,
            editable: true,
        },
        {
            field: 'successfulRecordCount',
            headerName: 'Successful Record Count',
            width: 200,
            editable: true,
        },
        {
            field: 'successfulPercentage',
            headerName: 'Successful Percentage',
            width: 200,
            editable: true,
        },
        {
            field: 'manageErrorLogs',
            headerName: 'Manage Error Logs',
            width: 200,
            renderCell: (params) => {
                return <Button onClick={_ => setManageErrorLogsModal(true)} variant='contained'>Open</Button>
            }
        },
    ]

    return (
        <div className='post-load-processing-main'>
            <div>
                <DataGrid
                    sx={{marginTop: '1rem', "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {display: "none"}}}
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 5,
                            },
                        },
                    }}
                    pageSizeOptions={[5]}
                    disableRowSelectionOnClick
                    selection
                />
            </div>

            <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '2rem'}}>
                <Button className='post-load-processing-save-close-btn' variant='contained'>Save & Close</Button>
            </div>

            <MainModal
                tooltip={true}
                modalOpen={manageErrorLogsModal}
                setModalOpen={setManageErrorLogsModal}
                headerText='Manage Error Logs'
                Child={ManageErrorLogsModal}/>

        </div>
    )
}

export default PostLoadProcessingModal;
