import Drawer from '@mui/material/Drawer';
import {
    Alert,
    Button, CircularProgress, Snackbar,
    StepButton,
    StepConnector,
    stepConnectorClasses,
    StepLabel,
    styled, Tooltip,
} from "@mui/material";

import {createTheme, ThemeProvider} from "@mui/material/styles";
import CloseIcon from '@mui/icons-material/Close';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import SettingsSystemDaydreamOutlinedIcon from '@mui/icons-material/SettingsSystemDaydreamOutlined';
import RecyclingOutlinedIcon from '@mui/icons-material/RecyclingOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import {useEffect, useState} from "react";
import AddTeam from "./AddTeam/AddTeam";
import SelectSystem from "./SelectSystem/SelectSystem";
import './ProjectCreate.css'
import PropTypes from "prop-types";
import ProjectDetail from "./ProjectDetail/ProjectDetail";
import DefineMigrationCycle from "./DefineMigrationCycle/DefineMigrationCycle";
import AddDataSource from './AddDataSource/AddDataSource'
import {useNavigate} from "react-router-dom";
import {
    authPCW,
    getCountries,
    getDims,
    getDomainUsers,
    getPermissions,
    getRoles,
    getSystems,
    getUserData
} from "../api/requests";
import {Help} from "@mui/icons-material";
import MainModal from "../helpers/Modal/MainModal";
import HelpModal from "../helpers/HelpModal/HelpModal";


const ProjectCreate = () => {
    const [activeStep, setActiveStep] = useState(0)
    const [completed, setCompleted] = useState({})
    const [unlockedStep, setUnlockedStep] = useState({0: true})
    const [data, setData] = useState({});
    const [countries, setCountries] = useState()
    const [systems, setSystems] = useState()
    const [domainUsers, setDomainUsers] = useState()
    const [loading, setLoading] = useState(true)
    const [roles, setRoles] = useState()
    const [dims, setDims] = useState()
    const [user, setUser] = useState()
    const [alertOpen, setAlertOpen] = useState()
    const [alertSeverity, setAlertSeverity] = useState()
    const [alertMessage, setAlertMessage] = useState()
    const [helpModalOpen, setHelpModalOpen] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        async function getData() {
            const token = localStorage.getItem('token')

            await authPCW(token).catch(_ => navigate('/'))

            const countries = await getCountries(token)
            const systems = await getSystems(token)
            const domainUsers = await getDomainUsers(token)
            const roles = await getRoles(token)
            const dims = await getDims(token)
            const user = await getUserData(token)

            setCountries(countries.data.countries)
            setSystems(systems.data.systems)
            setDomainUsers(domainUsers.data.users)
            setRoles(roles.data.roles)
            setDims(dims.data.dims)
            setUser(user.data)
        }

        getData().then(_ => setLoading(false))
    }, [])


    const steps = [
        {
            step: 'Enter Project Details'
        },
        {
            step: 'Select a system'
        },
        {
            step: 'Add team'
        },
        {
            step: 'Define Migration Cycles',
            tooltip: "Add rows to the table below to list the migration cycles for the project being created. " +
                "If the table below doesn't contain any records, a default migration cycle will be created " +
                "that can later be renamed via the project settings page"
        },
        {
            step: 'Add Data Ingestion Methods',
            tooltip: 'To edit non-standard DIMs (non-gray) rows double click them'
        },
    ]

    const ColorlibConnector = styled(StepConnector)(({theme}) => ({
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage: 'linear-gradient(0deg,  rgba(33,199,85,1) 0%, rgba(29,151,153,1) 100%)',
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            flexDirection: 'column',
            height: '10vh',
            width: 2,
            marginLeft: 10,
            border: 0,
            backgroundColor:
                theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
            borderRadius: 1,
        },
    }));

    const ColorlibStepIconRoot = styled('div')(({theme, ownerState}) => ({
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ...(ownerState.active && {
            backgroundColor: '#fff',
            color: 'blue',
            boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        }),
        ...(ownerState.completed && {
            backgroundColor: '#fff',
        }),
        ...(ownerState.unlocked && {
            backgroundColor: '#fff'
        })
    }));

    function ColorlibStepIcon(props) {
        const {active, completed, className, unlocked} = props;
        const icons = {
            1: <DescriptionOutlinedIcon/>,
            2: <SettingsSystemDaydreamOutlinedIcon/>,
            3: <GroupsOutlinedIcon/>,
            4: <RecyclingOutlinedIcon/>,
            5: <CloudUploadOutlinedIcon/>
        };

        return (
            <ColorlibStepIconRoot ownerState={{completed, active, unlocked}} className={className}>
                {completed ? <CheckCircleIcon color='success'/> : icons[String(props.icon)]}
            </ColorlibStepIconRoot>
        );
    }

    ColorlibStepIcon.propTypes = {
        active: PropTypes.bool,
        className: PropTypes.string,
        completed: PropTypes.bool,
        unlocked: PropTypes.bool,
        icon: PropTypes.node,
    };

    const theme = createTheme({
        palette: {
            primary: {
                main: '#fff',
            },
            secondary: {
                main: '#80d8ff',
            },
        },
    })

    const handleStep = (step) => () => {
        (completed[step] || unlockedStep[step]) && setActiveStep(step)
    }

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        const newUnlocked = unlockedStep
        newUnlocked[activeStep + 1] = true
        setUnlockedStep(newUnlocked)
        setActiveStep((activeStep + 1) % 5)
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1)
    }

    const handleCloseAlert = () => {
        setAlertOpen(false)
    }

    return (<>
        {loading ? <CircularProgress size={'10rem'}
                                     sx={{position: 'absolute', top: '40vh', left: 'calc((100vw - 10rem) / 2)'}}/> :
            <div className='main'>
                <MainModal modalOpen={helpModalOpen} setModalOpen={setHelpModalOpen} headerText={'Support'}
                           Child={HelpModal}/>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: {xs: 'block'},
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: '20%',
                            backgroundColor: 'rgba(0,117,229, 0.85)'
                        },
                    }}
                >
                    <div>
                        <ThemeProvider theme={theme}>
                            <Button variant='outlined' color='primary' startIcon={<CloseIcon/>}
                                    onClick={_ => navigate('/')}
                                    sx={{borderRadius: 40, margin: 2}}>Close</Button>
                        </ThemeProvider>
                        <div className='sidebar'>
                            <Stepper className='stepper' nonLinear activeStep={activeStep} orientation='vertical'
                                     connector={<ColorlibConnector/>}>
                                {steps.map((el, index) => (
                                    <Step key={el.step} completed={completed[index]}>
                                        <StepButton onClick={handleStep(index)}>
                                            <StepLabel StepIconComponent={ColorlibStepIcon}
                                                       unlocked={unlockedStep[index]}>
                                                {el.step}
                                            </StepLabel>
                                        </StepButton>
                                    </Step>))}
                            </Stepper>
                        </div>
                    </div>
                </Drawer>

                <div className='main-page'>
                    <div className='main-page-header'>
                        <h2>{steps[activeStep].step} {steps[activeStep].tooltip &&
                            <Tooltip title={<h2>{steps[activeStep].tooltip}</h2>}>
                                <Help fontSize={'large'} color={'disabled'}/>
                            </Tooltip>}</h2>
                        <p>step {activeStep + 1} from 5</p>
                    </div>
                    <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleCloseAlert}
                              anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                        <Alert onClose={handleCloseAlert} severity={alertSeverity} sx={{width: '100%'}}>
                            {alertMessage}
                        </Alert>
                    </Snackbar>

                    {
                        activeStep === 0 && <ProjectDetail
                            data={data}
                            setData={setData}
                            countriesTimezones={countries}
                            handleComplete={handleComplete}
                            setHelpModalOpen={setHelpModalOpen}
                        />
                    }
                    {
                        activeStep === 1 && <SelectSystem
                            handleComplete={handleComplete}
                            handleBack={handleBack}
                            data={data}
                            setData={setData}
                            systems={systems}
                            setHelpModalOpen={setHelpModalOpen}
                        />
                    }
                    {
                        activeStep === 2 && <AddTeam
                            handleComplete={handleComplete}
                            handleBack={handleBack}
                            data={data}
                            setData={setData}
                            users={domainUsers}
                            roles={roles}
                            creator={user}
                            setHelpModalOpen={setHelpModalOpen}
                        />
                    }
                    {
                        activeStep === 3 && <DefineMigrationCycle
                            handleComplete={handleComplete}
                            handleBack={handleBack}
                            data={data}
                            setData={setData}
                            setHelpModalOpen={setHelpModalOpen}
                        />
                    }

                    {
                        activeStep === 4 && <AddDataSource
                            handleComplete={handleComplete}
                            handleBack={handleBack}
                            data={data}
                            setData={setData}
                            dims={dims}
                            systems={systems}
                            user={user}
                            setAlertOpen={setAlertOpen}
                            setAlertSeverity={setAlertSeverity}
                            setAlertMessage={setAlertMessage}
                            setHelpModalOpen={setHelpModalOpen}
                        />
                    }


                    <div/>

                </div>
            </div>}
    </>)
}

export default ProjectCreate