import './ManageLookups.css';
import {Button, TextField, CircularProgress} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {useEffect, useState} from "react";
import pen from '../assets/pen.png';
import MainModal from "../../../helpers/Modal/MainModal";
import EditLookupTableModal from "./EditLookupTableModal/EditLookupTableModal";
import {getDataObjects, getLookupTables} from "../../../api/requests";

const rows = [
        {
            id: 1,
            name: 'Plant Lookups',
            whereUsed: 'Materials > Materials > Plant Data > Plant',
            createdBy: 'Colin Bryan',
            createdDate: ' 6/15/2023',
            updatedBy: 'Colin Bryan',
            updatedDate: '6/15/2023',
            sourceSystem: 'SAP ECC',
            targetSystem: 'SAP S/4HANA',
            comments: 'Old plant to new plant',
        },
        {
            id: 2,
            name: 'UoM Lookups',
            whereUsed: 'Multiple',
            createdBy: 'Colin Bryan',
            createdDate: '6/15/2023',
            updatedBy: 'Colin Bryan',
            updatedDate: '6/15/2023',
            sourceSystem: 'SAP ECC',
            targetSystem: 'SAP S/4HANA',
            comments: 'Old UoM to new UoM',
        },
        {
            id: 3,
            name: 'Plant Lookups',
            whereUsed: 'Materials > Materials > Plant Data > Plant',
            createdBy: 'Colin Bryan',
            createdDate: ' 6/15/2023',
            updatedBy: 'Colin Bryan',
            updatedDate: '6/15/2023',
            sourceSystem: 'SAP ECC',
            targetSystem: 'SAP S/4HANA',
            comments: 'Old plant to new plant',
        },
        {
            id: 4,
            name: 'UoM Lookups',
            whereUsed: 'Multiple',
            createdBy: 'Colin Bryan',
            createdDate: '6/15/2023',
            updatedBy: 'Colin Bryan',
            updatedDate: '6/15/2023',
            sourceSystem: 'SAP ECC',
            targetSystem: 'SAP S/4HANA',
            comments: 'Old UoM to new UoM',
        },
        {
            id: 5,
            name: 'Plant Lookups',
            whereUsed: 'Materials > Materials > Plant Data > Plant',
            createdBy: 'Colin Bryan',
            createdDate: ' 6/15/2023',
            updatedBy: 'Colin Bryan',
            updatedDate: '6/15/2023',
            sourceSystem: 'SAP ECC',
            targetSystem: 'SAP S/4HANA',
            comments: 'Old plant to new plant',
        }
];


const ManageLookups = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [searchInput, setSearchInput] = useState('');
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [showEditLookupTable, setShowEditLookupTable] = useState(false);
    const [lookupTables, setLookupTables] = useState([]);
    const [selectedLookupTable, setSelectedLookupTable]= useState(null);

    useEffect( () => {
        const lookupTables = async () => {
            return getLookupTables(1,1, localStorage.getItem('token')).then(res => res).catch(err => err)
        }

        lookupTables().then(res => {
            setLookupTables(res.data.data_objects.map((el) => {
                return {
                    id: el.recid,
                    edit: el,
                    name: el.lookup_table_name,
                    whereUsed: el.mapping_use_cases[0],
                    createdBy: el.created_by,
                    createdDate: el.created_date,
                    updatedBy: el.updated_by,
                    updatedDate: el.updated_date,
                    sourceSystem: el.source_system,
                    targetSystem: el.target_system,
                    comments: el.comments,
                }
            }))
            setIsLoading(false);

        })


        // return subscription.current = false;
    }, [])

    const priorityFormater = (cell) => {
    return (
        <div className='data-grid-icons-container'>
            <img onClick={_ => {
                setSelectedLookupTable(cell)
                setShowEditLookupTable(true)
            }} className='data-grid-icon' style={{width: '24px', height: '24px'}} src={pen}></img>
        </div>
    );
};

const columns = [
    {
        field: 'edit',
        headerName: '',
        width: 60,
        renderCell: (params) => {
            return priorityFormater(params.value);
    },
    },
    {
        field: 'name',
        headerName: 'Name',
        width: 200,

    },{
        field: 'whereUsed',
        headerName: 'Where-Used',
        width: 400,
    },
    {
        field: 'createdBy',
        headerName: 'Created By',
        width: 200,
    },
    {
        field: 'createdDate',
        headerName: 'Created Date',
        width: 100,
    },
    {
        field: 'updatedBy',
        headerName: 'Updated By',
        width: 200,
    },
    {
        field: 'updatedDate',
        headerName: 'Updated Date',
        width: 100,
    },
    {
        field: 'sourceSystem',
        headerName: 'Source System',
        width: 200,
    },
    {
        field: 'targetSystem',
        headerName: 'Target System',
        width: 200,
    },
    {
        field: 'comments',
        headerName: 'Comments',
        width: 400,
    },
]

    return (
    <div className='display-source-data-modal-window'>
        {
            isLoading ? <CircularProgress style={{margin: 'auto auto'}} size={'10rem'}/> :
                <>
                <div className='manage-lookups-btn-container'>
            <TextField
                id="standard-basic"
                onChange={e => setSearchInput(e.target.value)}
                label="Search"
                variant="standard"
                className='manage-lookups-search-field'
            />

            <div className='manage-lookups-btns'>
                <Button
                    onClick={_ => setShowEditLookupTable(true)}
                    className='display-source-data-btn'
                    variant="contained">Add Lookup
                </Button>
                <Button
                    className='display-source-data-btn'
                    color='error'
                    disabled={!rowSelectionModel.length}
                    variant="outlined">Delete LookupS
                </Button>
            </div>

        </div>

        <div className='manage-lookups-grid-container'>
            <DataGrid
                rows={lookupTables.filter(el => el.name.toLowerCase().includes(searchInput.toLowerCase()) || el.createdBy.toLowerCase().includes(searchInput.toLowerCase()))}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                pageSizeOptions={[10]}
                checkboxSelection
                disableRowSelectionOnClick
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                }}
                rowSelectionModel={rowSelectionModel}
            />
        </div>

        <MainModal
            tooltip={true}
            selectedLookupTable={selectedLookupTable}
            modalOpen={showEditLookupTable}
            setModalOpen={setShowEditLookupTable}
            headerText='Manage Lookups: Edit Lookup Table'
            Child={EditLookupTableModal}/>

        <Button className='manage-lookups-close-and-save-btn' variant='contained'>Save & Close</Button>
            </>
        }

    </div>
    )
}

export default ManageLookups;
