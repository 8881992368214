import './ManageFollowUpsModal.css';
import {
    Button,
    FormControl,
    TextField,
    InputLabel,
    Select,
    MenuItem,
    Autocomplete,
    FormHelperText, TableContainer, Paper, Table, TableBody, TableRow, TableCell, IconButton, Popover
} from "@mui/material";
import search from "../assets/search2.png";
import dayjs from 'dayjs';
import arrow from "../assets/arrow.png";
import React, {useState} from "react";
import pen from "../assets/pen.png";
import deleteImg from "../assets/delete.png";
import Editor from "../../../helpers//Editor/Editor";
import FilterListIcon from "@mui/icons-material/FilterList";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


const users = [
    'Param Somane',
    'Colin Bryan',
    'Developers'
]

const users2 = [
    {id: 1, name: 'Param Somane'},
    {id: 2, name: 'Colin Bryan'},
    {id: 3, name: 'Developers'}
]


const objects = {
    migrationCycles: [
        {
            id: 1,
            name: 'Migration Cycle example',
            templateObject: 'Material',
            migrationCycles: 'Migration Cycle',
            dueDate: '07/24/2023',
            status: 'In Progress',
            priority: 'High',
            assigned: ['Colin Bryan'],
            notes: [
                {
                    author: 'Colin Bryan',
                    date: '20.11.1998',
                    edited: false,
                    text: 'Sold-to customers will be migrated only (account group 0001)'
                },
                {
                    author: 'Job Champagne',
                    date: '20.11.1998',
                    edited: true,
                    text: 'Code updated to reflect this'
                },
            ]
        }
    ],
    dataObjects: [
        {
            id: 2,
            name: 'Migration Cycle example',
            templateObject: 'Material',
            migrationCycles: 'Migration Cycle',
            dataObject: 'test value',
            dueDate: '07/24/2023',
            status: 'In Progress',
            priority: 'High',
            assigned: ['Colin Bryan'],
            notes: [
                {
                    author: 'Colin Bryan',
                    date: '20.11.1998',
                    edited: false,
                    text: 'Sold-to customers will be migrated only (account group 0001)'
                },
                {
                    author: 'Job Champagne',
                    date: '20.11.1998',
                    edited: true,
                    text: 'Code updated to reflect this'
                },
            ]
        }
    ],
    templateObjects: [
        {
            id: 1,
            name: 'Update Customers Template',
            templateObject: 'Material',
            dueDate: '07/24/2023',
            status: 'In Progress',
            priority: 'High',
            assigned: ['Colin Bryan'],
            notes: [
                {
                    author: 'Colin Bryan',
                    date: '20.11.1998',
                    edited: false,
                    text: 'Sold-to customers will be migrated only (account group 0001)'
                },
                {
                    author: 'Job Champagne',
                    date: '20.11.1998',
                    edited: true,
                    text: 'Code updated to reflect this'
                },
            ]
        }
    ],
    subTemplateObjects: [
        {
            id: 2,
            name: 'Confirm leading zeroes are needed',
            templateObject: 'Material',
            subTemplateObjects: 'General Data',
            dueDate: '07/24/2023',
            status: 'In Progress',
            priority: 'Critical',
            assigned: ['OTC'],
            notes: [
                {
                    author: 'Colin Bryan',
                    date: '20.11.1998',
                    edited: false,
                    text: 'Sold-to customers will be migrated only (account group 0001)'
                },
                {
                    author: 'Job Champagne',
                    date: '20.11.1998',
                    edited: true,
                    text: 'Code updated to reflect this'
                },
            ]
        }
    ],
    fieldLevels: [
        {
            id: 3,
            name: 'Confirm leading zeroes are needed',
            templateObject: 'Customers',
            subTemplateObjects: 'General Data',
            field: 'KUNNR',
            dueDate: '07/24/2023',
            status: 'In Progress',
            priority: 'Critical',
            assigned: ['OTC'],
            notes: [
                {
                    author: 'Colin Bryan',
                    date: '20.11.1998',
                    edited: false,
                    text: 'Sold-to customers will be migrated only (account group 0001)'
                },
                {
                    author: 'Job Champagne',
                    date: '20.11.1998',
                    edited: true,
                    text: 'Code updated to reflect this'
                },
            ]
        },
        {
            id: 4,
            name: 'Confirm leading zeroes are needed',
            templateObject: 'Customers',
            subTemplateObjects: 'General Data',
            field: 'KUNNR',
            dueDate: '07/24/2023',
            status: 'In Progress',
            priority: 'Critical',
            assigned: ['OTC'],
            notes: [
                {
                    author: 'Colin Bryan',
                    date: '20.11.1998',
                    edited: false,
                    text: 'Sold-to customers will be migrated only (account group 0001)'
                },
                {
                    author: 'Job Champagne',
                    date: '20.11.1998',
                    edited: true,
                    text: 'Code updated to reflect this'
                },
            ]
        }
    ],
}



const ManageFollowUpsModal = () => {
    const [objectsList, setObjectsList] = useState(objects);
    const [selectedObject, setSelectedObject] = useState(objects.fieldLevels[0]);
    const [notes, setNotes] = useState(selectedObject.notes)
    const [addNewFollowUp, setAddNewFollowUp] = useState(false);
    const [status, setStatus] = useState('In Progress');
    const [priority, setPriority] = useState('High');
    const [selectedUser, setSelectedUser] = useState(users2.filter(user => user.name === 'Colin Bryan'));
    const [showFieldsLevels, setShowFieldsLevel] = useState(false);
    const [showTemplateObjects, setShowTemplateObjects] = useState(false);
    const [showSubTemplateObjects, setShowSubTemplateObjects] = useState(false);
    const [input, setInput] = useState('');
    const [filterValue, setFilterValue] = useState('');

    const DateComponent = () => {
        return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker', 'DatePicker']}>
                    <DatePicker
                        sx={{overflowY: 'hidden', overflowX: 'hidden', width: '100%', height: '100%'}}
                        label="Due Date"
                        slotProps={{ textField: { variant: 'standard', } }}
                        // defaultValue={dayjs('2022-04-17')}
                    />
                </DemoContainer>
            </LocalizationProvider>

        )
    }


    const Sidebar = () => {


        const TemplateObjects = () => {

            return (
                <div style={{marginBottom: '0.5rem'}}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '1rem',
                            cursor: 'pointer'
                        }}
                        onClick={_ => setShowTemplateObjects((prev) => !prev)}>
                        <span>Template Object</span>
                        <img style={!showTemplateObjects ? {
                            width: '13px',
                            height: '8px',
                            transform: 'rotate(-90deg)'
                        } : {width: '13px', height: '8px'}} src={arrow}></img>
                    </div>

                    {
                        showTemplateObjects &&
                        objects.templateObjects.filter(el => el.name.toLowerCase().includes(input.toLowerCase()) || el.templateObject.toLowerCase().includes(input.toLowerCase())).map(obj => {
                            return (
                                <div
                                    onClick={_ => {
                                        setAddNewFollowUp(false)
                                        setNotes(obj.notes)
                                        setSelectedObject(obj)
                                    }}
                                    style={selectedObject === obj ? {boxShadow: 'none'}: null}
                                    className='manage-follow-ups-card'
                                >
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between'
                                    }}>
                                        <span><strong>{obj.id}</strong> | {obj.name}</span>
                                        <span style={{
                                            backgroundColor: '#ebebeb',
                                            padding: '0.15rem 0.6rem',
                                            borderRadius: 50
                                        }}>{obj.notes.length}</span>
                                    </div>

                                    <span style={{marginBottom: '1rem'}}>Template Object: {obj.templateObject}</span>

                                    <div style={{
                                        display: 'flex',
                                        marginTop: '1rem',
                                        flexDirection: 'column',
                                        gap: '0.5rem'
                                    }}>
                                        <span>Due Date: {obj.dueDate}</span>
                                        <span>Status: {obj.status}</span>
                                        <span>Priority: {obj.priority}</span>
                                        <span>Assigned: {obj.assigned}</span>
                                        {/*<img style={{width: '11px', height: '22px'}} src={files}></img>*/}
                                    </div>
                                </div>
                            )
                        })

                    }
                </div>
            )
        }

        const SubTemplateObjects = () => {

            return (
                <div style={{marginBottom: '0.5rem'}}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '1rem',
                            cursor: 'pointer'
                        }}
                        onClick={_ => setShowSubTemplateObjects((prev) => !prev)}>
                        <span>Sub-Template Object</span>
                        <img style={!showSubTemplateObjects ? {
                            width: '13px',
                            height: '8px',
                            transform: 'rotate(-90deg)'
                        } : {width: '13px', height: '8px'}} src={arrow}></img>
                    </div>

                    {
                        showSubTemplateObjects &&
                        objects.subTemplateObjects.filter(el => el.name.toLowerCase().includes(input.toLowerCase()) || el.templateObject.toLowerCase().includes(input.toLowerCase())).map(obj => {
                            return (
                                <div
                                    onClick={_ => {
                                        setAddNewFollowUp(false)
                                        setNotes(obj.notes)
                                        setSelectedObject(obj)
                                    }}
                                    style={selectedObject === obj ? {boxShadow: 'none'}: null}
                                    className='manage-follow-ups-card'
                                >
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between'
                                    }}>
                                        <span><strong>{obj.id}</strong> | {obj.name}</span>
                                        <span style={{
                                            backgroundColor: '#ebebeb',
                                            padding: '0.15rem 0.6rem',
                                            borderRadius: 50
                                        }}>{obj.notes.length}</span>
                                    </div>

                                    <span style={{marginBottom: '0.1rem'}}>Template Object: {obj.templateObject}</span>
                                    <span
                                        style={{marginBottom: '1rem'}}>Sub-Template Object: {obj.templateObject}</span>

                                    <div style={{
                                        display: 'flex',
                                        marginTop: '1rem',
                                        flexDirection: 'column',
                                        gap: '0.5rem'
                                    }}>
                                        <span>Due Date: {obj.dueDate}</span>
                                        <span>Status: {obj.status}</span>
                                        <span>Priority: {obj.priority}</span>
                                        <span>Assigned: {obj.assigned}</span>
                                        {/*<img style={{width: '11px', height: '22px'}} src={files}></img>*/}
                                    </div>
                                </div>
                            )
                        })

                    }
                </div>
            )
        }

        const FieldLevel = () => {

            return (
                <div style={{marginBottom: '0.5rem'}}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '1rem',
                            cursor: 'pointer'
                        }}
                        onClick={_ => setShowFieldsLevel((prev) => !prev)}>
                        <span>Field-Level</span>
                        <img style={!showFieldsLevels ? {
                            width: '13px',
                            height: '8px',
                            transform: 'rotate(-90deg)'
                        } : {width: '13px', height: '8px'}} src={arrow}></img>
                    </div>

                    {
                        showFieldsLevels &&
                        objects.fieldLevels.filter(el => el.name.toLowerCase().includes(input.toLowerCase()) || el.templateObject.toLowerCase().includes(input.toLowerCase())).map(obj => {
                            return (
                                <div
                                    onClick={_ => {
                                        setAddNewFollowUp(false)
                                        setNotes(obj.notes)
                                        setSelectedObject(obj)
                                    }}
                                    style={selectedObject === obj ? {boxShadow: 'none'}: null}
                                    className='manage-follow-ups-card'
                                >
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between'
                                    }}>
                                        <span><strong>{obj.id}</strong> | {obj.name}</span>
                                        <span style={{
                                            backgroundColor: '#ebebeb',
                                            padding: '0.15rem 0.6rem',
                                            borderRadius: 50
                                        }}>{obj.notes.length}</span>
                                    </div>

                                    <span style={{marginBottom: '0.1rem'}}>Template Object: {obj.templateObject}</span>
                                    <span
                                        style={{marginBottom: '0.1rem'}}>Sub-Template Object: {obj.subTemplateObjects}</span>
                                    <span style={{marginBottom: '1rem'}}>Field: {obj.field}</span>

                                    <div style={{
                                        display: 'flex',
                                        marginTop: '1rem',
                                        flexDirection: 'column',
                                        gap: '0.5rem'
                                    }}>
                                        <span>Due Date: {obj.dueDate}</span>
                                        <span>Status: {obj.status}</span>
                                        <span>Priority: {obj.priority}</span>
                                        <span>Assigned: {obj.assigned}</span>
                                        {/*<img style={{width: '11px', height: '22px'}} src={files}></img>*/}
                                    </div>
                                </div>
                            )
                        })

                    }
                </div>
            )
        }

        const ObjectsProcessing = () => {
            switch (filterValue) {
                case 'Field-Level':
                    setShowFieldsLevel(true)
                    return <FieldLevel/>
                case 'Template Object':
                    setShowTemplateObjects(true)
                    return <TemplateObjects/>
                case 'Sub-Template Object':
                    setShowSubTemplateObjects(true)
                    return <SubTemplateObjects/>
                default:
                    return (
                        <>
                            <TemplateObjects/>
                            <SubTemplateObjects/>
                            <FieldLevel/>
                        </>
                    )
            }
        }
        return <ObjectsProcessing/>
    }

    const AddNewFollowUp = () => {
        const [noteName, setNoteName] = useState('');
        const [noteLevel, setNoteLevel] = useState('');
        const [dueDate, setDueDate] = useState('')
        const [status, setStatus] = useState('')
        const [priority, setPriority] = useState('')
        const [assigned, setAssigned] = useState('')
        const [assignedUser, setAssignedUser] = useState('');



        const DropDown = (label) => {
            const [age, setAge] = useState('');

            const handleChange = (event) => {
                setAge(event.target.value);
            };

            return (
                <div className='manage-follow-ups-table-dropdown-container'>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 220 , width: '95%'}}>
                    <InputLabel id="demo-simple-select-standard-label">{label}</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      disableUnderline
                      value={age}
                      onChange={handleChange}
                      // label={'test'}
                      sx={{boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                </FormControl>
                </div>

            )
        }

        const rows = [
            {
                name: 'Migration Cycle',
                description: _ => DropDown('Add Migration Cycle')
            },
            {
                name: 'Data Object',
                description: _ => DropDown('Add Data Object')
            },
            {
                name: 'Template Object',
                description:  _ => DropDown('Add Template Object')
            },
            {
                name: 'Sub-Template Object',
                description: _ => DropDown('Add Sub-Tempalte Object')
            },
            {
                name: 'Field',
                description: _ => DropDown('Add Field')
            }
        ]


        const cutTable = () => {
            return noteLevel === 'Data Object' ? 2 : noteLevel === 'Template Object' ? 3 : noteLevel === 'Sub Template Object' ? 4 : noteLevel === 'Migration Cycle' ? 1 : 5
        }

        return (
            <div style={{padding: '0 1rem'}}>
                <h2>Add New Follow-Up:</h2>
                <TextField
                    variant='standard'
                    fullWidth
                    label='Note Name'
                    onChange={e => setNoteName(e.target.value)}
                    value={noteName}
                />

                <FormControl sx={{ marginTop: 1, minWidth: 250 }}>
                    <InputLabel id="demo-simple-select-helper-label">Select Follow-Up Level</InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={noteLevel}
                      placeholder='Add Follow-Up Level'
                      label="Select Note Level"
                      variant="standard"
                      onChange={e => setNoteLevel(e.target.value)}
                    >
                    <MenuItem value="">
                    <em>None</em>
                    </MenuItem>
                    <MenuItem value={'Migration Cycle'}>Migration Cycle</MenuItem>
                    <MenuItem value={'Data Object'}>Data Object</MenuItem>
                    <MenuItem value={'Template Object'}>Template Object</MenuItem>
                    <MenuItem value={'Sub Template Object'}>Sub Template Object</MenuItem>
                    <MenuItem value={'Mapping Row'}>Mapping Row</MenuItem>

                    </Select>
                    <FormHelperText>Enter the type of follow-up to create</FormHelperText>
                </FormControl>

                {
                    noteLevel &&
                    <div className='custom-table' style={{width: '45%', marginLeft: 'auto', marginRight: '1rem'}}>
                        <TableContainer component={Paper}>
                          <Table sx={{ minWidth: 450}} aria-label="simple table">
                            <TableBody>
                              {rows.slice(0, cutTable()).map((row) => (
                                <TableRow

                                  key={row.name}
                                >
                                  <TableCell component="th" scope="row" sx={{backgroundColor: '#757272', color: 'white', width: '300px' }}>
                                    {row.name}:
                                  </TableCell>
                                  <TableCell sx={{width: '300px'}} component={row.description} align="left"></TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                    </div>
                }


                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4rem', marginTop: '1rem'}}>

                    <DateComponent/>

                    <FormControl variant="standard" sx={{m: 1, minWidth: 150}}>
                        <InputLabel id="demo-simple-select-standard-label">Status</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={status}
                            onChange={e => setStatus(e.target.value)}
                            placeholder='Select'
                            label="Age"
                        >
                            <MenuItem disabled value="">
                                Select
                            </MenuItem>
                            <MenuItem value={'{Not Started'}>Not Started</MenuItem>
                            <MenuItem value={'In Progress'}>In Progress</MenuItem>
                            <MenuItem value={'Completed'}>Completed</MenuItem>
                            <MenuItem value={'Deferred'}>Deferred</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl variant="standard" sx={{m: 1, minWidth: 150}}>
                        <InputLabel id="demo-simple-select-standard-label">Priority</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={priority}
                            onChange={e => setPriority(e.target.value)}
                            placeholder='Select'
                            label="Age"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={'Critical'}>Critical</MenuItem>
                            <MenuItem value={'High'}>High</MenuItem>
                            <MenuItem value={'Medium'}>Medium</MenuItem>
                            <MenuItem value={'Low'}>Low</MenuItem>
                            <MenuItem value={'Unknown'}>Unknown</MenuItem>
                        </Select>
                    </FormControl>

                    <Autocomplete
                      sx={{minWidth: 300}}
                      multiple
                      options={users}
                      getOptionLabel={(option) => option}
                      disableCloseOnSelect
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Assigned:"
                          placeholder="Search"
                        />
                      )}
                    />

                </div>

                <div style={{marginTop: '3rem', height: '10vh'}}>
                    <Editor
                        notes={notes}
                        setNotes={setNotes}
                    />

                </div>


            </div>
        )
    }

    const ManageFollowUp = () => {
        return (
            <>
                <div>
                    <div style={{display: 'flex', flexDirection: 'column', backgroundColor: '#D9D9D978', paddingBottom: '0.5rem'}}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '0.2rem 1rem'
                        }}>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <div style={{
                                    padding: '0.5rem 1.3rem',
                                    backgroundColor: '#5C5B5B',
                                    color: 'white',
                                    fontSize: 30,
                                    fontWeight: 800
                                }}>{selectedObject.id}
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', padding: '1rem'}}>
                                    <span style={{fontSize: 25, marginBottom: '0.2rem'}}><strong>{selectedObject.name}</strong></span>
                                    <span style={{fontSize: 20}}><strong>Template Object: </strong>{selectedObject.templateObject}</span>
                                </div>
                            </div>

                            <div>
                                <Button variant='contained'>Copy link</Button>
                            </div>


                        </div>

                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '5rem'
                        }}>

                            <DateComponent/>


                            <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                                <InputLabel id="demo-simple-select-standard-label">{selectedObject.status}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={status}
                                    onChange={e => setStatus(e.target.value)}
                                    label="Age"
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={'{Not Started'}>Not Started</MenuItem>
                                    <MenuItem value={'In Progress'}>In Progress</MenuItem>
                                    <MenuItem value={'Completed'}>Completed</MenuItem>
                                    <MenuItem value={'Deferred'}>Deferred</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                                <InputLabel id="demo-simple-select-standard-label">Priority</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={priority}
                                    onChange={e => setPriority(e.target.value)}
                                    label="Age"
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={'Critical'}>Critical</MenuItem>
                                    <MenuItem value={'High'}>High</MenuItem>
                                    <MenuItem value={'Medium'}>Medium</MenuItem>
                                    <MenuItem value={'Low'}>Low</MenuItem>
                                    <MenuItem value={'Unknown'}>Unknown</MenuItem>

                                </Select>
                            </FormControl>

                            <div style={{width: '30%'}}>
                                <Autocomplete
                                    multiple
                                    options={users2}
                                    getOptionLabel={(option) => option.name}
                                    getOptionSelected={(option, value) => value && option.name === value.name}
                                    disableCloseOnSelect
                                    value={selectedUser}
                                    onChange={(event, newValue) => setSelectedUser(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="Assigned:"
                                        />
                                    )}
                                />


                            </div>

                        </div>

                    </div>
                </div>

                <div>

                    {
                        notes.map(note => {
                            return (
                                <div className='manage-notes-note'>
                                    <span style={{color: 'gray'}}>{note.author} | {note.date} {note.edited ? '(Edited)' : null}</span>
                                    {/*<p style={{fontSize: 18, marginTop: '0.3rem', fontWeight: 400}}>{note.text}</p>*/}
                                    <div style={{fontSize: 18, marginTop: '0.3rem', fontWeight: 400}} dangerouslySetInnerHTML={{ __html: note.text }} />

                                    <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '3.5rem', marginRight: '1rem', marginBottom: '1rem', gap: '0.5rem'}}>
                                        <img style={{width: '20px', height: '20px', cursor: 'pointer'}} src={pen}></img>
                                        <img
                                            onClick={_ => setNotes(notes.filter(el => el !== note))}
                                            style={{width: '20px', height: '20px', cursor: 'pointer'}}
                                            src={deleteImg}>
                                        </img>

                                    </div>
                                </div>
                            )
                        })
                    }
                    <div style={{marginTop: '3rem', height: '10vh', zIndex: 201}}>
                        <Editor
                            notes={notes}
                            setNotes={setNotes}
                        />
                    </div>
                </div>
            </>
        )
    }

    const CustomFilter = () => {
      const [anchorEl, setAnchorEl] = useState(null);
      // const { setFilters } = useListContext();

      const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
      };

      const handleClose = () => {
        setAnchorEl(null);
      };

      const handleFilterSelect = (filterValue) => {
        setFilterValue(filterValue)
        handleClose();
      };

      return (
        <React.Fragment>
          <IconButton onClick={handleOpen} color="primary">
            <FilterListIcon />
          </IconButton>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={() => handleFilterSelect('Migration Cycle')}>Migration Cycle</MenuItem>
            <MenuItem onClick={() => handleFilterSelect('Data Object')}>Data Object</MenuItem>
              <MenuItem onClick={() => handleFilterSelect('Template Object')}>Template Object</MenuItem>
              <MenuItem onClick={() => handleFilterSelect('Sub-Template Object')}>Sub-Template Object</MenuItem>
              <MenuItem onClick={() => handleFilterSelect('Field-Level')}>Field-Level</MenuItem>
          </Popover>
        </React.Fragment>
      );
    };


    return (
        <div style={{width: '80vw', height: '78vh', display: 'flex', flexDirection: 'row'}}>
            <div className='manage-follow-ups-sidebar'>
                <div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '1rem',
                        height: '40%',
                        justifyContent: 'space-between'
                    }}>
                        <CustomFilter/>

                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <img src={search} style={{width: '24px', height: '24px'}}></img>
                            <TextField
                                onChange={event => setInput(event.target.value)}
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        "& > fieldset": {
                                            border: "none"
                                        }
                                    }
                                }}
                                id="standard-basic"
                                placeholder='Search...'
                                variant="outlined"

                            />
                        </div>

                        <Button
                            sx={{minWidth: 100}}
                            onClick={_ => setAddNewFollowUp(true)}
                            variant='contained'
                        >+ New
                        </Button>
                    </div>


                    <Sidebar/>

                </div>


            </div>

            {/*<Sidebar/>*/}

            <hr style={{margin: '1rem'}}></hr>

            <div style={{width: '75%'}}>
                {
                    addNewFollowUp ?  <AddNewFollowUp/> : <ManageFollowUp/>
                }

            </div>

        </div>
    )
}

export default ManageFollowUpsModal;