import {Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import key from "../assets/key.png";
import SettingsIcon from "@mui/icons-material/Settings";

const rows = [
        {
            id: 1,
            type: 'Filter',
            field: 'Required?',
            criteria: 'Yes',
        },
        {
            id: 2,
            type: 'Sort',
            field: 'Required?',
            criteria: 'Descending',
        },
    ]

const columns = [
        {
            id: 'type',
            label: 'Type',
            minWidth: 200,
            align: 'center'
        },
        {
            id: 'field',
            label: 'Field',
            minWidth: 200,
            align: 'center'
        },
        {
            id: 'criteria',
            label: 'Criteria',
            minWidth: 200,
            align: 'center',
        },
    ];


const FilterSortCriteria = () => {
    return (
        <div style={{display: 'flex', flexDirection: 'column', width: '40vw', height: '30vh', padding: '0 1rem'}}>
            <span style={{fontSize: 30, fontWeight: 700, marginBottom: '0.2rem'}}>Showing x/y of all mapping rows due to the below criteria:</span>
            <span style={{marginBottom: '1.4rem', fontStyle: 'italic'}}>Tip: Change the filter and sort criteria in the Mapping View table to change the display.</span>

            <TableContainer sx={{width: '100%'}} component={Paper}>
                    <Table  sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead sx={{fontSize: 800}}>
                            <TableRow sx={{backgroundColor: '#CCCCCC80'}}>

                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{top: 57, minWidth: column.minWidth}}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {rows.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}>

                                    <TableCell align='center' component="th" scope="row">
                                        {row.type}
                                    </TableCell>

                                    <TableCell align="center">
                                        <span>{row.field}</span>
                                    </TableCell>

                                    <TableCell align="center">
                                        <span>{row.criteria}</span>
                                    </TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

        </div>
    )
}

export default FilterSortCriteria;
