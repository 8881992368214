import './ChangeLoadTemplateModal.css';
import {Button, Checkbox, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {useCallback, useState} from "react";
import {useDropzone} from "react-dropzone";
import MainModal from "../../../helpers/Modal/MainModal";
import FileProcessModal from "../FileProcessModal/FileProcessModal";
import {DataGrid} from "@mui/x-data-grid";
import download from "../assets/download.png";
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import DisplayMappingDifferencesModal from "./DisplayMappingDifferencesModal/DisplayMappingDifferencesModal";
import close from "../assets/close.png";

const uploadFileTypes = [
    'CSV File',
    'New Dataset',
    'any',
];



const rows = [
        {
            id: 1,
            fileName: 'Materials.xml',
            uploadedBy: 'Colin Bryan',
            uploadedDate: '6/15/2023',
            dataIngestionMethod: 'Flat File',
            link: ''
        },
        {
            id: 2,
            fileName: 'Materials.xml',
            uploadedBy: 'Colin Bryan',
            uploadedDate: '6/15/2023',
            dataIngestionMethod: 'CSV',
            link: ''
        },
        {
            id: 3,
            fileName: 'Materials.xml',
            uploadedBy: 'Colin Bryan',
            uploadedDate: '6/15/2023',
            dataIngestionMethod: 'CSV',
            link: ''
        }
];



const ChangeLoadTemplateModal = () => {
    const [ingestionMethod, setIngestionMethod] = useState('');
    const [dataError ,setDataError] = useState(false);
    const [checkedRow, setCheckedRow] = useState(null);


        const priorityFormater = (cell) => {
            return (
                <div className='data-grid-icons-container'>
                    <img onClick={_ => setDataError(true)} className='data-grid-icon' src={download}></img>
                </div>
            );
        };

    const columns = [
        {
            field: 'checkbox',
            headerName: 'Active',
            width: 80,
            renderCell: (params) => {
                return (
                    <>
                        <Checkbox
                            onChange={_ => checkedRow === params.id ? setCheckedRow(null) : setCheckedRow(params.id)}
                            checked={params.id === checkedRow}
                            icon={<StarBorderIcon/>}
                            checkedIcon={<StarIcon/>}/>
                    </>
                )
            }
        },

        {
            field: 'fileName',
            headerName: 'File Name',
            width: 120
        },
        {
            field: 'uploadedBy',
            headerName: 'Uploaded By',
            width: 150
        },
        {
            field: 'uploadedDate',
            headerName: 'Uploaded Date',
            width: 150
        },
        {
            field: 'dataIngestionMethod',
            headerName: 'Data Ingestion Method',
            width: 220
        },
        {
            field: 'link',
            headerName: 'Link',
            width: 150,
            renderCell: (params) => {
                return priorityFormater(params.value);
            }
        }
    ]


    const Dragndrop = () => {
        const [files, setFiles] = useState()
        const [fileProcessModal, setFileProcessModal] = useState(false);

        const onDrop = useCallback(acceptedFiles => {
            setFiles(acceptedFiles)
        }, [])

        const {getRootProps, getInputProps} = useDropzone({
            onDrop
        })

        const FileContainer = () => {
            return (
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
                    <h2>File Name</h2>

                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '80%',}}>
                        {
                            files.map(file => {
                                return (
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '2rem',
                                        padding: '2% 0', borderBottom: '1px solid lightgray', width: '100%'}}>
                                        <img onClick={_ => setFiles(files?.filter(el => el !== file))} style={{width: '16px', height: '16px', cursor: 'pointer'}} src={close}></img>

                                        <span>{file.name}</span>

                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            )
        }

        return (
            <div style={{width: '90%', padding: '1rem', border: '1px solid lightgray'}}>
                <h2 style={{padding: 0, marginTop: 0}}>Upload Files:</h2>
                <div>
                    <div className='change-template-dropzone-area dropzone-area' {...(!files?.length? getRootProps() : null)}>

                        {!files?.length ? "Drag your file or select file" : <FileContainer/>}
                        {!files?.length && <input className='dropzone-area' {...getInputProps()}/>}
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%', alignItems: 'center', marginBottom: '3%'}}>

                    <Button
                        disabled={!files?.length}
                        variant='contained'
                        onClick={_ => setFileProcessModal(true)}
                        sx={{marginRight: '2%', marginTop: '4%'}}>Process
                    </Button>
                    </div>

                    {
                        !!files?.length &&
                            <p style={{marginLeft: '25%'}}>Processing source datsaets. Do not close the page...</p>
                    }


                    <MainModal
                        tooltip={true}
                        modalOpen={fileProcessModal}
                        setModalOpen={setFileProcessModal}
                        headerText='Display Mapping Differences'
                        Child={DisplayMappingDifferencesModal}
                        files={files}
                        setFiles={setFiles}
                        isOpen={setFileProcessModal}
                        file={files}
                    />
                </div>
            </div>

        )
    }


    return (
        <div className='change-load-template-main'>
            <div className='upload-new-template'>
                <h2>Upload New Load Template</h2>
                <span>Upload a new load template to replace the active load template for the object</span>

                <FormControl variant="standard" sx={{marginTop: '1rem', marginBottom: '1rem', width: '45%'}}>
                        <InputLabel sx={{marginLeft: '1px'}} id="demo-simple-select-label">Select Data Ingestion Method:</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={ingestionMethod}
                            variant='standard'
                            onChange={event => setIngestionMethod(event.target.value)}
                          >
                            {
                                uploadFileTypes.map(type => {
                                    return (
                                        <MenuItem value={type}>{type}</MenuItem>
                                    )
                                })
                            }
                          </Select>
                    </FormControl>

                <Dragndrop/>

            </div>

            <hr/>

            <div className='choose-active-template'>
                <h2>Choose Active Load Template</h2>
                <span>The active load template is the file format that the data for the object will be transformed into for Target System load</span>
                    <DataGrid
                        sx={{marginTop: '1rem', "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {display: "none"}}}
                        rows={rows}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        pageSizeOptions={[5]}
                        disableRowSelectionOnClick
                        selection

                    />

            </div>

        </div>
    )
}

export default ChangeLoadTemplateModal;
