import './DisplayLoadDasatestsModal.css';
import {Button} from "@mui/material";
import search from "../assets/search.png";
import poll from "../assets/poll.png";
import download from "../assets/download.png";
import warning from "../assets/warning.png";
import {useState} from "react";
import {DataGrid} from "@mui/x-data-grid";
import MainModal from "../../../helpers/Modal/MainModal";
import ManageLoadDataModal from "./ManageLoadDataModal/ManageLoadDataModal";
import PostLoadProcessingModal from "./PostLoadProcessingModal/PostLoadProcessingModal";
import UploadLoadDatasetModal from "./UploadLoadDatasetModal/UploadLoadDatasetModal";
import ManageValidationModal from "./ManageValidationModal/ManageValidationModal";
import ManageFollowUpsModal from "../ManageFollowUpsModal/ManageFollowUpsModal";

const rows = [
        {
            id: 1,
            loadDataset: 'Materials_2023_06_23',
            description: 'Manual test load file',
            createdBy: 'Automated',
            createdDate: 'timestamp',
            manageLoadData: 'data',
            manageValidation: 'data',
            preLoadValidatedBy: 'Not Validated',
            preLoadValidatedDate: 'Not Validated',
            postLoadValidatedBy: 'Not Validated',
            postLoadValidatedDate: 'Not Validated',
        },
        {
            id: 2,
            loadDataset: 'Materials_2023_06_23',
            description: 'Manual test load file',
            createdBy: 'Automated',
            createdDate: 'timestamp',
            manageLoadData: 'data',
            manageValidation: 'data',
            preLoadValidatedBy: 'Colin Bryan',
            preLoadValidatedDate: 'timestamp',
            postLoadValidatedBy: 'Not Validated',
            postLoadValidatedDate: 'Not Validated',
        },
        {
            id: 3,
            loadDataset: 'Materials_2023_06_23',
            description: 'Manual test load file',
            createdBy: 'Automated',
            createdDate: 'timestamp',
            manageLoadData: 'data',
            manageValidation: 'data',
            preLoadValidatedBy: 'Not Validated',
            preLoadValidatedDate: 'Not Validated',
            postLoadValidatedBy: 'Not Validated',
            postLoadValidatedDate: 'Not Validated',
        }
];


const DisplayLoadDatasetsModal = () => {
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [manageLoadDataModal ,setManageLoadDataModal] = useState(false);
    const [postLoadProcessingModal, setPostLoadProcessingModal] = useState(false);
    const [uploadLoadDataset, setUploadLoadDataset] = useState(false);
    const [manageValidationModal, setManageValidationModal] = useState(false);
    const [showFollowUps, setShowFollowUps] = useState(false);


    const columns = [
        {
            field: 'checkbox', headerName: '', width: 10 },
        {
            field: 'loadDataset',
            headerName: 'Load Dataset',
            width: 200,
            editable: true,
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 200,
            editable: true,
        },
        {
            field: 'createdBy',
            headerName: 'Created By',
            width: 150,
            editable: true,
        },
        {
            field: 'createdDate',
            headerName: 'Created Date',
            width: 120,
        },
        {
            field: 'manageLoadData',
            headerName: 'Manage Load Data',
            width: 150,
            renderCell: (params) => {
                return <Button onClick={_ => setManageLoadDataModal(true)} variant='contained'>Open</Button>
            },
        },
        {
            field: 'manageValidation',
            headerName: 'Manage Validation',
            width: 200,
            editable: true,
            renderCell: (params) => {
                return <Button onClick={_ => setManageValidationModal(true)}  variant='contained'>Open</Button>
            },
        },
        {
            field: 'preLoadValidatedBy',
            headerName: 'Pre-Load Validated By',
            width: 200,
            renderCell: (params) => {
                return <span style={ params.formattedValue === 'Not Validated' ? {color: '#EF7D0B'} : null}>{params.formattedValue}</span>
            },
        },
        {
            field: 'preLoadValidatedDate',
            headerName: 'Pre-Load Validated Date',
            width: 200,
            renderCell: (params) => {
                return <span style={ params.formattedValue === 'Not Validated' ? {color: '#EF7D0B'} : null}>{params.formattedValue}</span>
            },
        },
        {
            field: 'postLoadValidatedBy',
            headerName: 'Post-Load Validated By',
            width: 200,
            renderCell: (params) => {
                return <span style={ params.formattedValue === 'Not Validated' ? {color: '#EF7D0B'} : null}>{params.formattedValue}</span>
            },
        },
        {
            field: 'postLoadValidatedDate',
            headerName: 'Post-Load Validated Date',
            width: 200,
            renderCell: (params) => {
                return <span style={ params.formattedValue === 'Not Validated' ? {color: '#EF7D0B'} : null}>{params.formattedValue}</span>
            },
        },
    ]

    return (
        <div className='display-load-datasets-main'>
            <div className='display-load-datasets-btns-container'>
                <Button
                    onClick={_ => setShowFollowUps(prev => !prev)}
                    variant='contained'>Follow-ups
                </Button>
                <Button
                    onClick={_ => setPostLoadProcessingModal(true)}
                    variant='contained'>Post-Load Processing
                </Button>
                <Button
                    onClick={_ => setUploadLoadDataset(true)}
                    variant='contained'>Upload New Dataset
                </Button>
                <Button
                    variant='outlined'
                    disabled={!rowSelectionModel.length}
                    color='error'>Delete File(s)
                </Button>
            </div>

            <div style={{height: '80%'}}>
                <DataGrid
                    checkboxSelection
                    sx={{marginTop: '1rem'}}
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 5,
                            },
                        },
                    }}
                    pageSizeOptions={[5]}
                    disableRowSelectionOnClick
                    selection
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setRowSelectionModel(newRowSelectionModel);
                    }}
                    rowSelectionModel={rowSelectionModel}
                />
            </div>

            <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '1rem'}}>
                <Button variant='contained'>Save & Close</Button>
            </div>

            <MainModal
                tooltip={true}
                modalOpen={manageLoadDataModal}
                setModalOpen={setManageLoadDataModal}
                headerText='Manage Load Data'
                Child={ManageLoadDataModal}/>

            <MainModal
                tooltip={true}
                modalOpen={manageValidationModal}
                setModalOpen={setManageValidationModal}
                headerText='Mark as Validated'
                Child={ManageValidationModal}/>

            <MainModal
                tooltip={true}
                modalOpen={postLoadProcessingModal}
                setModalOpen={setPostLoadProcessingModal}
                headerText='Post-Load Processing'
                Child={PostLoadProcessingModal}/>

            <MainModal
                tooltip={true}
                modalOpen={uploadLoadDataset}
                setModalOpen={setUploadLoadDataset}
                headerText='Upload Load Dataset'
                Child={UploadLoadDatasetModal}/>

            <MainModal
                tooltip={true}
                modalOpen={showFollowUps}
                setModalOpen={setShowFollowUps}
                headerText='Manage Follow Ups'
                Child={ManageFollowUpsModal}
            />

        </div>
    )
}

export default DisplayLoadDatasetsModal;
