import {useCallback, useEffect, useRef, useState} from "react";
import CustomToolbar from "./QuillEditor/CustomToolbar";
import ReactQuill, { Quill } from 'react-quill';
import './editor.css';
import 'react-quill/dist/quill.snow.css';
import {Button} from "@mui/material";
import close from '../../../src/Project/ManageObjects/assets/close.png';

import {useDropzone} from 'react-dropzone';

const Editor = ({setNotes, notes}) => {
    const [value, setValue] = useState('');

    const [text,setText] = useState('');
    const [files, setFiles] = useState('');

    const {getRootProps, getInputProps, acceptedFiles, isDragActive, isDragAccept} = useDropzone({noClick: true});
    // const files = acceptedFiles.map(file => <li key={file.path}>{file.path}</li>);

    const handleChange = (html)=> {
        setText(html);

    }

    const onDrop = useCallback(acceptedFiles => {
        setFiles(acceptedFiles);
  }, [])

    const formatBytes = (bytes, decimals) => {
        if(bytes == 0) return '0 Bytes';
        const k = 1024,
           dm = decimals || 2,
           sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
           i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}


    const modules = {
        toolbar: {
            container: "#toolbar",
        },
    }
    const formats = [
      'font','size',
      'bold','italic','underline','strike',
      'color','background',
      'script',
      'header','blockquote','code-block',
      'indent','list',
      'direction','align',
      'link','image','video','formula',
    ]

    const handleClick = (event) => {
        event.preventDefault();
    };


    const setObject = () => {
        setNotes([...notes, {
                author: 'Maksym Kovalev',
                date: '20.11.1998',
                dateEdited: false,
                text
            },])
        setText('')
    }

    return (
        <>
            <div {...getRootProps()} onClick={handleClick} className={isDragActive ? 'drag-active' : ''}>
              <input {...getInputProps()}/>
                {isDragActive && isDragAccept ?
                        <div>
                            <div className="drop-message">
                                <span>Drop files here</span>
                            </div>
                        </div>

                     :
                    <>
                        <CustomToolbar/>
                        <ReactQuill
                            style={{height: '20vh'}}
                            value={text}
                            onChange={handleChange}
                            modules={modules}
                            formats={formats}
                            // value={ <DropzoneWithoutClick/>}
                        />
                    </>
                }

            </div>

            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                {
                    acceptedFiles &&
                        <div style={{width: '30%'}}>
                            {
                                acceptedFiles.map(el => {
                                    return (
                                        <div style={{
                                            backgroundColor: '#f5f5f5',
                                            padding: '1.5% 2.5%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            marginTop: '0.5rem',
                                        }}>
                                            <span><a style={{textDecoration: 'none'}} href={el}>{el.name}</a> ({formatBytes(el.size, 2)})</span>
                                            <img style={{width: 12, height: 12}} src={close}></img>
                                        </div>
                                    )
                                })
                            }

                        </div>
                }


                <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '1rem', height: '3vh'}}>
                    <Button
                        className='manage-load-data-save-close-btn'
                        variant='contained'
                        onClick={setObject}

                    >Submit
                    </Button>
                </div>
                </div>
        </>
    )
}

export default Editor;