import './addDoPage.css'
import {Alert, Button} from "@mui/material";
import {DataGrid, GridToolbarContainer, useGridApiContext} from '@mui/x-data-grid';
import AddIcon from "@mui/icons-material/Add";
import {useState, useCallback} from "react";
import {useDropzone} from "react-dropzone";
import RemoveIcon from "@mui/icons-material/Remove";
import LoadingButton from "@mui/lab/LoadingButton";
import {Save} from "@mui/icons-material";
import {createDataObjects} from "../../../api/requests";


const AddDOPage = ({projectId, mcId, getObjects, assignedObjects}) => {
    const [file, setFile] = useState()
    const [fields, setFields] = useState([])
    const [nameError, setNameError] = useState(false)
    const columns = [
        {
            field: 'do_name', headerName: 'Name', editable: true, minWidth: 150, flex: 1,
            cellClassName: (params) => {
                if (!nameError) {
                    return ''
                }
                if (!params.value || fields.filter(el => el.do_name === params.value).length > 1 || assignedObjects.includes(params.value)) {
                    return 'erroneous-cell'
                }
            }
        },
        {field: 'description', headerName: 'Description', editable: true, minWidth: 150, flex: 1}
    ]
    const CustomToolbar = (props) => {
        const apiRef = useGridApiContext();
        const [saveLoading, setSaveLoading] = useState(false)

        return (
            <GridToolbarContainer {...props}>
                <Button startIcon={<AddIcon/>}
                        onClick={_ => {
                            setFields([...fields, {id: fields.length, do_name: '', description: ''}])
                            setNameError(false)
                        }}>Add New Row</Button>
                <Button onClick={_ => {
                    setNameError(false)
                    const t = fields.filter(el => !apiRef.current.getSelectedRows().has(el.id))
                    setFields(t.map((el, i) => {
                        return {...el, id: i}
                    }))
                    apiRef.current.setRowSelectionModel([])
                }} startIcon={<RemoveIcon/>}>Remove Rows</Button>
                <LoadingButton loading={saveLoading} startIcon={<Save/>} onClick={_ => {
                    if (fields.some(el => !el.do_name) || new Set(fields.map(el => el.do_name)).size !== fields.length || fields.some(el => assignedObjects.includes(el.do_name))) {
                        setNameError(true)
                    } else {
                        setNameError(false)
                        setSaveLoading(true)
                        createDataObjects(projectId, mcId, fields.map(el => ({
                            do_name: el.do_name,
                            description: el.description
                        })), localStorage.getItem('token')).then(_ => {
                            setSaveLoading(false)
                            getObjects()
                            setFields([])
                        }).catch(_ => setSaveLoading(false))
                    }
                }}>
                    <span>Save</span>
                </LoadingButton>
                {nameError && <Alert severity={'error'}>Names must be unique and non-empty values</Alert>}
            </GridToolbarContainer>
        )
    }
    const onDrop = useCallback(acceptedFiles => {
        console.log(acceptedFiles)
        setFile(acceptedFiles[0])
    }, [])

    const {getRootProps, getInputProps} = useDropzone({
        onDrop
    })
    const processRowUpdate = (newValue) => {
        setFields(fields.map(el => el.id === newValue.id ? newValue : el))
        return newValue
    }

    return (
        <>
            <h1>Add New Data Objects</h1>
            <h3>Enter Data Object Details or choose to mass upload a list of objects with a pre-defined template</h3>
            <div className='main-body'>
                <div className='table-parent-block'>

                    <DataGrid getRowId={row => row.id} columns={columns} rows={fields} hideFooter className='datagrid'
                              processRowUpdate={processRowUpdate} onProcessRowUpdateError={e => console.log(e)}
                              checkboxSelection slots={{toolbar: CustomToolbar}} disableRowSelectionOnClick/>
                </div>
                <div className='file-upload-block'>
                    <h4 style={{alignSelf: 'baseline'}}>Upload Data Object Creation File:</h4>
                    <div className='dropzone-area' {...getRootProps()}> Drag your file or select file
                        <input className='dropzone-area' {...getInputProps()} />
                    </div>
                    <div
                        style={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center'}}>
                        {file?.name}
                        <Button variant='contained' sx={{alignSelf: 'end'}}>Upload File</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddDOPage