import {
    Autocomplete,
    Button,
    Checkbox,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {useState} from "react";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const fileExtensions = ['xlsx',
    'xlsm',
    'xls',
    'xml',
    'xlsb',
    'csv',
    'tsv',
    'txt',
    'parquet',
    'orc',
    'json',
    'html',
    '*']
const quotingOptions = ['QUOTE_MINIMAL', 'QUOTE_ALL', 'QUOTE_NONNUMERIC', 'QUOTE_NONE']
const delimiters = ['<horizontal tab>', ',', ';', '|', '"', "'", '{', '}', 'ѿ', 'Φ']

const AddNewDataSourceModal = ({rows, setRows, setChildModalOpen, dims, defaultRow = null}) => {
    const [delimited, setDelimited] = useState(defaultRow?.delimited_file ? defaultRow.delimited_file : false)
    const [name, setName] = useState(defaultRow?.dim_name)
    const [type, setType] = useState(defaultRow?.dim_type)
    const [showPassword, setShowPassword] = useState(false)
    const [fileExtension, setFileExtension] = useState(defaultRow?.file_extensions ? defaultRow.file_extensions : [])
    const [delimiter, setDelimiter] = useState(defaultRow?.parsing_delimiter)
    const [quoteChar, setQuoteChar] = useState(defaultRow?.quotechar)
    const [quoting, setQuoting] = useState(defaultRow?.quoting)
    const [serverType, setServerType] = useState(defaultRow?.server_type)
    const [serverName, setServerName] = useState(defaultRow?.server_name)
    const [databaseName, setDatabaseName] = useState(defaultRow?.database_name)
    const [username, setUsername] = useState(defaultRow?.username)
    const [password, setPassword] = useState(defaultRow?.password)
    const [connectionTimeout, setConnectionTimeout] = useState(defaultRow?.connection_timeout)
    const [executionTimeout, setExecutionTimeout] = useState(defaultRow?.execution_timeout)
    const [error, setError] = useState(false)
    const [description, setDescription] = useState(defaultRow?.dim_description)
    const [xpath, setXpath] = useState(defaultRow?.xpath)
    const [xmlNamespace, setXmlNamespace] = useState(defaultRow?.namespaces)
    const [nameError, setNameError] = useState(false)
    const [host, setHost] = useState(defaultRow?.host)
    const [port, setPort] = useState(defaultRow?.port)
    const [encoding, setEncoding] = useState(defaultRow?.encoding)

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <div style={{display: 'flex', flexDirection: 'column', gap: '0.5rem', paddingTop: 10}}>
            <TextField id="outlined-basic" label="Name" variant="outlined" onChange={e => setName(e.target.value)}
                       defaultValue={defaultRow?.dim_name} required error={error && !name || nameError}
                       helperText={nameError ? 'This name is already taken' : ''}/>
            <TextField multiline rows={4} label='Descrition' variant='outlined' value={description}
                       onChange={e => setDescription(e.target.value)}/>
            <FormControl required error={error && !type}>
                <InputLabel id='label-type-id'>Type</InputLabel>
                <Select labelId='label-type-id' label='Type' onChange={e => {
                    setError(false)
                    setType(e.target.value)
                }}
                        defaultValue={defaultRow?.dim_type}>
                    <MenuItem value='FF'>
                        Flat File
                    </MenuItem>
                    <MenuItem value='DB'>
                        Database Connection
                    </MenuItem>
                    <MenuItem value='SSD'>
                        Semi-structured data
                    </MenuItem>
                </Select>
            </FormControl>
            {type === 'FF' &&
                <>
                    <FormControl sx={{width: '99%'}} required>
                        <Autocomplete multiple freeSolo options={fileExtensions} value={fileExtension || []}
                                      onChange={(_, v) => setFileExtension(v)}
                                      renderInput={(params) => (
                                          <TextField
                                              {...params}
                                              required
                                              error={error && fileExtension.length === 0}
                                              variant="standard"
                                              label="File Extension(s)"
                                          />
                                      )}/>
                    </FormControl>
                    <div>
                        Delimited File: <span><Checkbox onChange={_ => setDelimited(!delimited)}
                                                        checked={delimited}/></span>
                    </div>
                    {delimited && <>
                        <Autocomplete
                            value={delimiter}
                            id="free-solo-demo"
                            freeSolo
                            options={delimiters}

                            renderInput={(params) => <TextField {...params} label="Delimiter"/>}
                            onChange={(event, value) => setDelimiter(value)}
                            onInputChange={(event, value) => setDelimiter(value)}

                        />

                        <Autocomplete
                            value={quoteChar}
                            id="free-solo-demo"
                            freeSolo
                            options={['"', "'", '`']}
                            renderInput={(params) => <TextField {...params} label="Quote Character"/>}
                            onChange={(event, value) => setQuoteChar(value)}
                            onInputChange={(event, value) => setQuoteChar(value)}

                        />

                        <FormControl>
                            <InputLabel id='label-quoting-id'>Quoting</InputLabel>
                            <Select label='Quoting' labelId='label-quoting-id' disabled={!delimited}
                                    onChange={event => setQuoting(event.target.value)} value={quoting}>
                                {quotingOptions.map(quotes =>
                                    <MenuItem value={quotes}>
                                        {quotes}
                                    </MenuItem>)}
                            </Select>
                        </FormControl>

                        <Autocomplete
                            value={encoding}
                            freeSolo
                            options={['utf-8', 'iso-8859-1', 'windows-1252']}
                            renderInput={(params) => <TextField {...params} label='Encoding'/>}
                            onChange={(_, value) => setEncoding(value)}
                            onInputChange={(_, value) => setEncoding(value)}/>

                    </>}
                </>
            }
            {type === 'DB' &&
                <>
                    <FormControl>
                        <InputLabel id='label-server-type-id'>Server Type</InputLabel>
                        <Select labelId='label-server-type-id' label='Server Type'
                                onChange={e => setServerType(e.target.value)}
                                value={serverType}>
                            <MenuItem value='Database Engine'>
                                Database Engine
                            </MenuItem>
                        </Select>
                    </FormControl>

                    <TextField id="outlined-basic" label="Server name" variant="outlined"
                               onChange={e => setServerName(e.target.value)}
                               value={serverName}/>
                    <TextField id="outlined-basic" label="Username" variant="outlined" error={error && !username}
                               onChange={e => setUsername(e.target.value)} required
                               value={username}/>
                    <FormControl variant="outlined" error={error && !password} required>
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <OutlinedInput
                            onChange={e => setPassword(e.target.value)}
                            value={password}
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff/> : <Visibility/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                        />
                    </FormControl>
                    <TextField label={'Host'} variant='outlined' error={error && !host}
                               onChange={e => setHost(e.target.value)}
                               required value={host}/>
                    <TextField label={'Port'} variant='outlined' error={error && !port}
                               onChange={e => setPort(e.target.value)}
                               required value={port}/>

                    <TextField id="outlined-basic" label="Database name" variant="outlined"
                               error={error && !databaseName}
                               onChange={e => setDatabaseName(e.target.value)} required
                               value={databaseName}/>


                    <FormControl>
                        <InputLabel id='label-connection-timeout-id'>Connection Timeout</InputLabel>
                        <Select labelId='label-connection-timeout-id' label='Connection Timeout'
                                value={connectionTimeout}
                                onChange={e => setConnectionTimeout(e.target.value)}>
                            <MenuItem value={30}>
                                30
                            </MenuItem>
                            <MenuItem value={60}>
                                60
                            </MenuItem>
                            <MenuItem value={300}>
                                300
                            </MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl>
                        <InputLabel id='label-execution-timeout-id'>Execution Timeout</InputLabel>
                        <Select labelId='label-execution-timeout-id' label='Execution Timeout' value={executionTimeout}
                                onChange={e => setExecutionTimeout(e.target.value)}>
                            <MenuItem value={30}>
                                30
                            </MenuItem>
                            <MenuItem value={60}>
                                60
                            </MenuItem>
                            <MenuItem value={300}>
                                300
                            </MenuItem>
                        </Select>
                    </FormControl>

                </>}
            {type === 'SSD' &&
                <>
                    <TextField label='XPath' onChange={e => setXpath(e.target.value)} value={xpath}/>
                    <TextField multiline rows={4} label='XML Namespace' onChange={e => setXmlNamespace(e.target.value)}
                               value={xmlNamespace}/>
                </>}
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button onClick={_ => {
                    if (!name || !type) {
                        setError(true)
                    } else if (type === 'FF' && fileExtension.length === 0) {
                        setError(true)
                    } else if (type === 'DB' && [databaseName, username, password, host, port].some(el => !el)) {
                        setError(true)
                    } else if ([...rows.map(el => el.dim_name.toLowerCase()), ...dims.map(el => el.dim_name.toLowerCase())].includes(name.toLowerCase()) && name !== defaultRow?.dim_name) {
                        setNameError(true)
                    } else {

                        const data = {
                            dim_name: name,
                            dim_type: type,
                            dim_description: description,
                            file_extensions: type === 'FF' ? fileExtension : null,
                            delimited_file: delimited,
                            parsing_delimiter: type === 'FF' ? delimiter === '<horizontal tab>' ? '\t' : delimiter : null,
                            quotechar: type === 'FF' ? quoteChar : null,
                            quoting: type === 'FF' ? quoting : null,
                            db_server_type: type === 'DB' ? serverType : null,
                            db_server_name: type === 'DB' ? serverName : null,
                            database_name: type === 'DB' ? databaseName : null,
                            username: type === 'DB' ? username : null,
                            host: type === 'DB' ? host : null,
                            port: type === 'DB' ? port : null,
                            password_key: type === 'DB' ? password : null,
                            connection_timeout: type === 'DB' ? connectionTimeout : null,
                            execution_timeout: type === 'DB' ? executionTimeout : null,
                            xpath: type === 'SSD' ? xpath : null,
                            namespaces: type === 'SSD' ? xmlNamespace : null,
                            encoding: type === 'FF' ? encoding : null,
                            is_standard: false,
                            parsing_format_id: null
                        }
                        setRows(defaultRow ? rows.map(el => el.id === defaultRow.id ? {id: el.id, ...data} : el) : [...rows, {id: rows.length, ...data}])
                        setChildModalOpen(false)
                        setError(false)
                    }
                }}>Save</Button>
            </div>
        </div>
    )
}

export default AddNewDataSourceModal