import {Button, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {useState} from "react";

const ManageValidationModal = () => {
    const [preLoadValidation, setPreLoadValidation] = useState();
    const [postLoadValidation, setPostLoadValidation] = useState();


    return (
        <div style={{display: 'flex', flexDirection: 'column', width: '25vw'}}>
            <h2 style={{marginBottom: 0, marginTop: '0.2rem'}}>Instructions:</h2>
            <h3  style={{marginTop: '0.5rem'}}>Set validation status for the file before loading or after loading</h3>

            <FormControl variant="standard" sx={{marginTop: '1rem', marginBottom: '1rem', width: '45%'}}>
                <InputLabel sx={{marginLeft: '1px'}} id="demo-simple-select-label">Pre-Load Validation</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={preLoadValidation}
                    variant='standard'
                    onChange={event => setPreLoadValidation(event.target.value)}
                  >
                    {
                        ['Yes', 'No'].map(type => {
                            return (
                                <MenuItem value={type}>{type}</MenuItem>
                            )
                        })
                    }
                  </Select>
            </FormControl>

            <FormControl variant="standard" sx={{marginTop: '1rem', marginBottom: '1rem', width: '45%'}}>
                <InputLabel sx={{marginLeft: '1px'}} id="demo-simple-select-label">Post-Load Validation</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={postLoadValidation}
                    variant='standard'
                    onChange={event => setPostLoadValidation(event.target.value)}
                  >
                    {
                        ['Yes', 'No'].map(type => {
                            return (
                                <MenuItem value={type}>{type}</MenuItem>
                            )
                        })
                    }
                  </Select>
            </FormControl>

            <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '1rem'}}>
                <Button variant='contained'>Save & Close</Button>
            </div>
        </div>
    )
}
export default ManageValidationModal;
